import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';
import { MY_LIKE_ITEM } from '../../api/api_story';

const SET_LIKE = 'suworld/common/SET_LIKE';

export const globalLikeCreator = createAction(SET_LIKE);

export function setGlobalLike(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalLikeCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalLikeCreator({
        data: data.like
      }));
    } catch (error) {
      
    }
  };
}

export interface like {
  like:Array<typeof MY_LIKE_ITEM>;
}

const initialState: like = {
  like:[MY_LIKE_ITEM]
};

export const globalLike = handleActions<like, any>({
  [SET_LIKE]: (state, action: ReturnType<typeof globalLikeCreator>) => ({
    like: action.payload.data ?? [MY_LIKE_ITEM],
  }),
}, initialState);
