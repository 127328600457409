import React from 'react'
import BasicText from '../../basic/BasicText'
import { addTheme } from '../../../style/addTheme'
import { TextItem } from '../../../style/basic/commonStyle'

export default function TitleItem({text,children,align}:any) {
    return (
        <TextItem textAlign={align ?? 'left'} fw={700} fontSize={addTheme.titleFontSize} mFontSize={addTheme.mTitleFontSize} dangerouslySetInnerHTML={{__html:text}}>
            {children}
        </TextItem>
    )
}
