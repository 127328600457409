import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React, { useRef } from 'react'
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

interface inputItemType{
    value?:string | number;
    valueChange?:any;
    type?:'text'|'number'|'password'|any;
    label?:string;
    margin?:string;
    inputSx?:any;
    inputLabel?:any;
    inputProps?:any;
    fileRef?:any;
    id?:string;
    labelSx?:any;
    placeholder?:string;
    fontSize?:number;
    rows?:number;
}

const pwProps = {
    autoComplete:"off"
}

export default function InputItem({value,valueChange,type,label,margin,inputSx,inputLabel,inputProps,fileRef,id,labelSx,placeholder,fontSize,rows}:inputItemType) {
    let intLabel = inputLabel === 'none' ? '' : label;
   
    // const fileRef = useRef<any>(null);
    const changeAction = (e:any) =>{
        if(type === 'file'){
            valueChange(e);
        }else{
            valueChange(e.target.value);
        }
    }
    return (
        <ItemWrap margin={margin} fontSize={fontSize}>
            {intLabel !== undefined && intLabel !== '' &&
                <BasicText marginBottom={1}>{intLabel}</BasicText>
            }
            <TextField placeholder={placeholder ?? ''} fullWidth size='small' inputRef={fileRef} sx={{'.MuiOutlinedInput-root':{border:'1px solid #fff',color:'#fff'},...inputSx,display:type==='file' ? 'none' : 'block'}} id={`input-${id}`} label={intLabel} variant="outlined" value={value} onChange={(e)=>changeAction(e)} type={type} inputProps={type==='password' ? pwProps : inputProps}
                rows={rows} multiline={rows === undefined ? false : true}
            />
            {type === 'file' &&
                <label style={labelSx} htmlFor={`input-${id}`}>{label}</label>
            }
        </ItemWrap>
    )
}

const ItemWrap = styled.div<{margin?:string,fontSize?:number}>`
    margin: ${props=>props.margin ?? 0};
    & label{
        width: 100%;
        border: 1px solid #fff;
        border-radius: 4px;
        /* max-width: 280px; */
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    & *{
        font-size: ${props=> props.fontSize ?? 16}px !important;
    }
    & input:focus-visible{
        outline: none;
    }
    & fieldset{
        border: none;
    }
`;