import React from 'react'
import { useGlobalLang } from './useGlobalLang';
import { TEXT_DATA } from '../../../components/data/langData';

export default function useLang(id:string) {
    const {lang} = useGlobalLang();
    const item = TEXT_DATA.filter((data)=>data.id === id);
    const text = item.length === 0 ? '' : lang === 'ko' ? item[0].ko : item[0].en;
    return text;
}
