import React from 'react'
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';

interface noItemType{
    text?:string;
    padding?:string;
}

export default function NoItem({text,padding}:noItemType) {
    return (
        <Wrap padding={padding}>
            {text ?? 'No items to display'}
        </Wrap>
    )
}

const Wrap = styled(Center)<{padding?:string}>`
    padding : ${props => props.padding ?? '150px 0 80px'}
`;
