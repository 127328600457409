import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_AlERT= 'SUBOX_AUDIO/SET_ALBUM';

export const albumCreator = createAction(SET_AlERT);

export function setAlbum(data: any): ThunkAction<void, RootState, null, ReturnType<typeof albumCreator>> {
    return async function (dispatch) {
    try {
            dispatch(albumCreator({
              data: data
            }));
    } catch (error) {
      
    }
  };
}

export interface AlbumState {
  id:string;
  title:string;
  artist:string;
  img:string;
  profile:string;
  type:''|'album'|'playlist';
}

const initialState: AlbumState = {
    id:'0',
    title:'',
    artist:'',
    img:'',
    profile:'',
    type:'',
};

export const albumInfo = handleActions<AlbumState, any>({
  [SET_AlERT]: (state, action: ReturnType<typeof albumCreator>) => ({
    id: action.payload.data.album_id ?? action.payload.data.playlist_id ??'0',
    title: action.payload.data.album_name ?? action.payload.data.playlist_name ?? '',
    artist: action.payload.data.artist ?? action.payload.data.user_nickname ??'',
    img: action.payload.data.artwork ?? action.payload.data.playlist_thumbnail?? '',
    profile: action.payload.data.user_profile ?? '',
    type: action.payload.data.type ?? '',
  }),
}, initialState);
