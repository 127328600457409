import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { TextItem } from '../../../style/basic/commonStyle';
import { MUSIC_ITEM } from '../../../api/api_music';
import MusicListPart from './MusicListPart';

interface musicNewMusicPartType{
    title:string;
    list:Array<typeof MUSIC_ITEM>;
}

export default function MusicNewMusicPart({title,list}:musicNewMusicPartType) {
    const [allList,setAllList] = useState<Array<Array<typeof MUSIC_ITEM>>>([[MUSIC_ITEM]]);

    useEffect(()=>{
        if(list[0].sound_id === 0) return;
        listItem();
    },[list])

    const listItem = () =>{
        const result = [];
        // for (let i = 0; i < list.length; i += 10) {
        //     result.push(list.slice(i, i + 10));
        // }
        for (let i = 0; i < list.length; i += 5) {
            result.push(list.slice(i, i + 5));
        }
        
        setAllList(result);
    }

    return (
        <Wrap>
            <TextItem fontSize={24} mFontSize={18}>{title}</TextItem>
            <ContentWrap>
                <MusicListPart allList={allList} total={allList.length}></MusicListPart>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div``