import React,{useState, useCallback} from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';

export default function SliderPart({data,children}:any) {

    const setting = {
        dots: true,
        infinity: true,
        speed:1500,
        autoplaySpeed:5000,
        autoplay:true,
        arrows:false,
        pauseOnHover : true,
        ...data,
    }
    
    return (
        <Wrap>
            <Slider {...setting}>
                {children}
            </Slider>
        </Wrap>
    )
}

const Wrap = styled.div`
    .slick-dots li button:before{
        color: #fff;
    }
    .slick-slide {
        display: inline-block;
        vertical-align: top;
    }
    & .slick-list .slick-track{
        margin: 0 !important;
    }
`;