import React,{useEffect, useState} from 'react'
import styled from 'styled-components';
import {BiSearch} from 'react-icons/bi';
import { Center, Row } from '../../../style/basic/commonStyle';
import { useSearchParams } from 'react-router-dom';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';

export default function SearchPart({sc}:any) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [search,setSearch] = useState('');
    const mChk = useMediaQuery(addTheme.media.m);

    const searchAction = (e:any) =>{
        e.preventDefault();
        searchParams.set('sc',search);
        // searchParams.set('p','1');
        setSearchParams(searchParams);
    }

    useEffect(()=>{
        if(sc !== null){
            setSearch(sc);
        }
    },[])

    return (
        <form onSubmit={searchAction}>
            <Wrap>
                <IconWrap onClick={searchAction}>
                    <BiSearch color='#111' size={mChk ? 18:28}></BiSearch>
                </IconWrap>
                <Input value={search} onChange={(e:any)=>setSearch(e.target.value)} placeholder='곡명/아티스트 등의 키워드로 검색해보세요.'></Input>
            </Wrap>
        </form>
    )
}

const Wrap = styled(Row)`
    margin-top: 26px;
`;
const IconWrap = styled(Center)`
    background-color: #fff;
    padding: 8px;
    border-radius: 50%;
`;
const Input = styled.input`
    color: #fff;
    padding: 8px;
    width: 100%;
    font-size: 18px;
    &:focus-visible{
        outline: 0;
    }
    @media ${addTheme.media.m}{
        font-size: 15px;
    }
`;