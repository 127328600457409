import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { Link, useLocation, useNavigate,  } from 'react-router-dom';
import { addTheme } from '../../../style/addTheme';
import HeaderLangPart from '../part/HeaderLangPart';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../store/common/globalAlert';
import HeaderMoMenuPart from '../part/HeaderMoMenuPart';

export const MENU = [
    {id:1,title:['음악듣기','Music'],link:'/music',type:'music'},
    {id:2,title:['음악찾기','Music Search'],link:'/search',type:'search'},
    // {id:2,title:['Story','Story'],link:'/story',type:'story'},
    // {id:3,title:['혜택받기','Benefits'],link:'',type:'benefit'},
    {id:4,title:['서비스 소개','Service'],link:'/',type:'/'},
    {id:5,title:['공지사항','Notice'],link:'/notice/1',type:'notice'},
    {id:6,title:['이용가이드','Guide '],link:'',type:'guide'},
]

export default function HeaderPage() {
    const {number} = useGlobalLang();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeMenu,setActiveMenu] = useState('');

    const [scroll,setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleScroll = () => {
        if(window.scrollY >= 50){
            setScroll(true);
        }else{
            setScroll(false);
        }
    };

    useEffect(()=>{
        setActiveMenu('1');
        const pathname = location.pathname.split('/').filter((data)=>data);
        const name = pathname[0];
        // if(name === 'search' || name === 'music'){
        //     setActiveMenu('music');
        //     return;
        // }
        if(name === 'music'){
            setActiveMenu('music');
            return;
        }

        if(name === 'search'){
            setActiveMenu('search');
            return;
        }

        

        if(name === 'story'){
            setActiveMenu('story');
            return;
        }

        if(name === 'notice'){
            setActiveMenu('notice');
            return;
        }

        if(name === undefined){
            setActiveMenu('/');
            return;
        }
    },[location.pathname]);

    const linkAction = (link:string) =>{
        if(link === '') {
            window.open('https://blog.naver.com/suboxm');
            // dispatch(setGlobalAlert({
            //     open:true,
            //     title:'Coming soon',
            //     content:'',
            // }));
            return
        };
        navigate(link);
    }
    
    return (
        <div style={{backgroundColor:scroll ? 'rgba(0,0,0,0.8)':'transparent'}}>
            <MaxWidthWrap>
                <Wrap>
                    <LeftWrap>
                        <LogoWrap>
                            <Link to='/'>
                                <img alt='logo' src='/img/logo.png'></img>
                            </Link>
                        </LogoWrap>
                        <MenuWrap>
                            {MENU.map((data,index)=>(
                                <MenuItem key={`menu-${index}`} activeMenu={activeMenu === data.type} onClick={()=>linkAction(data.link)}>
                                    {data.title[number]}
                                </MenuItem>
                            ))}
                        </MenuWrap>
                    </LeftWrap>
                    <RightWrap>
                        <HeaderLangPart></HeaderLangPart>
                        <MoMenu>
                            <HeaderMoMenuPart activeMenu={activeMenu} linkAction={linkAction}></HeaderMoMenuPart>
                        </MoMenu>
                    </RightWrap>
                </Wrap>
            </MaxWidthWrap>
        </div>
    )
}

const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
`;

const LeftWrap = styled(Row)``;
const LogoWrap = styled.div`
    & img{
        max-height: 45px;
    }
    @media ${addTheme.media.m} {
        & img{
            max-height: 35px;
        }
    }
`;

const MenuWrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: 50px;
    @media ${addTheme.media.m}{
        display: none;
        margin-left: 0;
    }
`;

const MenuItem = styled.div<{activeMenu:boolean}>`
    margin-right: 25px;
    &:last-of-type{
        margin-right: 0;
    }
    text-align: center;
    color: ${props => props.activeMenu ? addTheme.color : '#fff'};
    cursor: pointer;
`;

const RightWrap = styled.div`
    display: flex;
    align-items: center;
`;

const MoMenu = styled.div`
    display: none;
    @media ${addTheme.media.m}{
        display: block;
        margin-left: 15px;
    }
`;