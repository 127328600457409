import React,{useState} from 'react'
import styled from 'styled-components';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import {IoAlertCircleOutline,IoHeartOutline,IoHeart} from 'react-icons/io5';
import {MdPlaylistAdd} from 'react-icons/md';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import WaveItem from './WaveItem';
import { useDispatch } from 'react-redux';
import useAudioAction from '../../../utils/custom-hooks/useAudioAction';
import { MUSIC_ITEM } from '../../../api/api_music';
import { setGlobalReport } from '../../../store/common/globalReport';

interface musicItemType {
    data: typeof MUSIC_ITEM;
    reportAction?: any;
    dragging?:boolean;
}

export default function MusicItem({data,dragging}:musicItemType) {
    const dispatch = useDispatch();
    const audioAction = useAudioAction();

    const navigate = useNavigate();

    const detail = (id:number) =>{
        if(dragging) return;
        navigate(`/searchDetail/${id}`);
    }

    const profile = (user_id:number) =>{
        if(dragging) return;
        navigate(`/userPage/${user_id}`);
    }

    const report = (id:number) =>{
        dispatch(setGlobalReport({
            open:true,
            id:id,
            title:'해당 음원을 신고하시겠습니까?',
            type:2
        }))
    }

    const add = (data:any) =>{
        audioAction.add(data);
    }

    return (
        <Wrap>
            <InfoWrap>
                <ImgItem onClick={()=>detail(data.sound_id)}>
                    <img alt='music-img' src={data.artwork}></img>
                </ImgItem>
                <TitleItem>
                    <TextItem onClick={()=>detail(data.sound_id)} style={{cursor:'pointer'}} fontSize={15}>{data.title}</TextItem>
                    <TextItem onClick={()=>profile(data.user_id)} style={{cursor:'pointer'}} color={'#c2c2c2'} marginTop={6} fontSize={14}><span>by&nbsp;</span> {data.artist}</TextItem>
                </TitleItem>
            </InfoWrap>
            <PlayWrap>
                <WaveItem url={data.url}></WaveItem>
            </PlayWrap>
            <TagWrap>
                <TextItem line={2}>{data.sound_mood}</TextItem>
            </TagWrap>
            <ActiveWrap>
                <MdPlaylistAdd onClick={()=>add(data)} size={32}></MdPlaylistAdd>
                <ReportWrap>
                    <IoAlertCircleOutline onClick={()=>report(1)} size={24}></IoAlertCircleOutline>
                </ReportWrap>
            </ActiveWrap>
        </Wrap>
    )
}

const Wrap =  styled(Row)`
    margin-top: 35px;
    flex-wrap: wrap;
`;
const InfoWrap = styled(Row)`
    width: 25%;
    @media ${addTheme.media.m}{
        width: 60%;
        order:0;
    }
`;
const ImgItem = styled.div`
    width: 55px;
    height: 55px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 10px;
    cursor: pointer;
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 110%;
        width: 100%;
    }
    @media ${addTheme.media.m}{
        width: 45px;
        height: 45px;
    }
`;
const TitleItem = styled.div`
    width: calc(100% - 70px);
    &>div:nth-last-of-type(1):hover{
        text-decoration: underline;
    }
    @media ${addTheme.media.m}{
        width: calc(100% - 60px);
    }
`;

const PlayWrap = styled(Row)`
    width: 35%;
    display: flex;
    margin-right: 5%;
    &>p{
        margin-left: 10px;
    }
    @media ${addTheme.media.m}{
        margin-right: 0;
        width: 24%;
        order:1;
        &>p{
            margin-left: 5px;
        }
    }
`;

const TagWrap = styled.div`
    width: 26%;
    @media ${addTheme.media.m}{
        width: 100%;
        margin-top: 8px;
        order:3;
    }
`;
const ActiveWrap = styled.div`
    width: 9%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    & svg{
        cursor: pointer;
    }
    & svg:hover{
        color: #00ff9c;
    }
    @media ${addTheme.media.m}{
        width: 16%;
        order:2;
    }
    
`;

const ReportWrap = styled.div`
    position: relative;
`;

const ReportMenu = styled.div`
    position: absolute;
    top:0;
    right: 0;
    width: 110px;
    background-color: #fff;
`;

const ReportMenuItem = styled.div`
    color: #111;
    padding: 8px 12px;
    font-size: 14px;
`;

const BgCloseItem = styled.div`
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;