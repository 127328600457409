import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import MusicMenuPart from '../part/SearchMenuPart';
import MusicSearchPart from '../part/SearchPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import { useSearchParams } from 'react-router-dom';
import {Box,Tabs,Tab} from '@mui/material';
import { apiGetSearchAlbum, apiGetSearchBeat, apiGetSearchMusic, apiGetSearchPlaylist, apiGetSearchTotal } from '../../../api/api_search';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import SearchResPart from '../part/SearchResPart';
import { MUSIC_ITEM, NEW_ALBUM_ITEM, PLAYLIST_ITEM } from '../../../api/api_music';
import SearchResAlbumPart from '../part/SearchResAlbumPart';
import Head from '../../../routes/Head';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SearchPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [albumList,setAlbumList] = useState([NEW_ALBUM_ITEM]);
    const [beatList,setBeatList] = useState([MUSIC_ITEM]);
    const [musicList,setMusicList] = useState([MUSIC_ITEM]);
    const [playList,setPlayList] = useState([PLAYLIST_ITEM]);

    const user = useUserInfo();
    // 검색어
    const sc = searchParams.get('sc') === null ? '' : searchParams.get('sc');

    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);

    const [value,setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        listReset();
        setValue(newValue);
        setPage(1);
        setTotal(1);
    }

    const listReset = () =>{
        setAlbumList([NEW_ALBUM_ITEM]);
        setBeatList([MUSIC_ITEM]);
        setMusicList([MUSIC_ITEM]);
        setPlayList([PLAYLIST_ITEM]);
    }

    const getTotal = async() =>{
        const scText = sc === null ? '' : sc;
        const res = await apiGetSearchTotal(user.jwt_token,user.user_id,scText);
        
        if(res.result){
            const newMusic = res.data.music.slice(0,5);
            const newBeat = res.data.beat.slice(0,5);
            setAlbumList(res.data.album);
            setMusicList(newMusic);
            setBeatList(newBeat);
            setPlayList(res.data.playlist);
            return;
        }
    }

    const getMusic = async() =>{
        const scText = sc === null ? '' : sc;
        const res = await apiGetSearchMusic(user.jwt_token,user.user_id,scText,page);

        if(res.result){
            setMusicList(res.data);
            setTotal(res.total);
            return;
        }
    }
    const getBeat = async() =>{
        const scText = sc === null ? '' : sc;
        const res = await apiGetSearchBeat(user.jwt_token,user.user_id,scText,page);
        if(res.result){
            setBeatList(res.data);
            setTotal(res.total);
            return;
        }
    }

    const getAlbum = async() =>{
        const scText = sc === null ? '' : sc;
        const res = await apiGetSearchAlbum(user.jwt_token,user.user_id,scText,page);
        
        if(res.result){
            setAlbumList(res.data);
            setTotal(res.total);
            return;
        }
    }

    const getPlaylist = async() =>{
        const scText = sc === null ? '' : sc;
        const res = await apiGetSearchPlaylist(user.jwt_token,user.user_id,scText,page);
        
        if(res.result){
            setPlayList(res.data);
            setTotal(res.total);
            return;
        }
    }

    useEffect(()=>{
        if(value === 0){
            getTotal();
            return;
        }
        if(value === 1){
            getMusic();
            return;
        }
        if(value === 2){
            getBeat();
            return;
        }
        if(value === 3){
            getAlbum();
            return;
        }
        if(value === 4){
            getPlaylist();
            return;
        }
    },[sc,value,page])

    return (
        <LayoutPage>
            <Head title='search' />
            <Wrap>
                {/* <MusicMenuPart type='search'></MusicMenuPart> */}
                <MaxWidthWrap>
                    <MusicSearchPart sc={sc}></MusicSearchPart>
                    <ItemWrap>
                        <Tabs value={value} onChange={handleChange} aria-label="basic" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                            <Tab sx={{color:'#fff'}} label="전체" {...a11yProps(0)} />
                            <Tab sx={{color:'#fff'}} label="음원" {...a11yProps(1)} />
                            <Tab sx={{color:'#fff'}} label="비트" {...a11yProps(2)} />
                            <Tab sx={{color:'#fff'}} label="앨범" {...a11yProps(3)} />
                            <Tab sx={{color:'#fff'}} label="플레이리스트" {...a11yProps(4)} />
                        </Tabs>
                        <CustomTabPanel value={value} index={0}>
                            <SearchResPart albumList={albumList} beatList={beatList} musicList={musicList} playList={playList}></SearchResPart>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <SearchResPart albumList={albumList} beatList={beatList} musicList={musicList} playList={playList} page={page} total={total} setPage={setPage}></SearchResPart>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <SearchResPart albumList={albumList} beatList={beatList} musicList={musicList} playList={playList} page={page} total={total} setPage={setPage}></SearchResPart>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <SearchResAlbumPart albumList={albumList} playList={playList} page={page} total={total} setPage={setPage}></SearchResAlbumPart>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            <SearchResAlbumPart albumList={albumList} playList={playList} page={page} total={total} setPage={setPage}></SearchResAlbumPart>
                        </CustomTabPanel>
                    </ItemWrap>
                </MaxWidthWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;
const ItemWrap = styled.div`
    margin-top: 40px;
    & .MuiTab-root.Mui-selected{
        color: #00ff9c !important;
    }
    & .MuiTabs-indicator{
        background-color: #00ff9c !important;
    }

    & .MuiTabScrollButton-root{
        color: #00ff9c !important;
    }
`;