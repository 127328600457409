import React,{useEffect, useState}from 'react'
import styled from 'styled-components'
import { Row, TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import NewWebTextItem from '../item/NewWebTextItem';
import { NEW_PART_TEXT } from '../../data/introData';
import { BsChevronRight } from 'react-icons/bs';
import NewWebSliderItem from '../item/NewWebSliderItem';
import TitleItem from '../../common/item/TitleItem';
import useLang from '../../../utils/custom-hooks/common/useLang';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

export default function NewWebPart() {
    const lang = useLang('intro_new');
    const {number} = useGlobalLang();
    const [activeState,setActiveState] = useState(1);

    useEffect(()=>{
        const timer = setInterval(()=>{
            let next = activeState + 1;
            if(next > 3){
                next = 1;
            }
            setActiveState(next)
        },3000)
        return () => clearInterval(timer);
    },[activeState])

    const clickEvent = (id:number) =>{
        // setActiveState(id);
    }
    
    return (
        <div>
            <ContentWrap>
                <LeftWrap>
                    <img alt='background-img' src='/img/new_web/bg-1.png'></img>
                    <SliderWrap>
                        <NewWebSliderItem></NewWebSliderItem>
                    </SliderWrap>
                </LeftWrap>
                <RightWrap>
                    <TitleItem fw={700} fontSize={addTheme.titleFontSize} mFontSize={addTheme.mTitleFontSize} text={lang[0]}></TitleItem>
                    {/* <TextItem fw={700} fontSize={addTheme.titleFontSize} mFontSize={addTheme.mTitleFontSize} marginTop={8}>WEB3.0 앱테크</TextItem> */}
                    <TextWrap>
                        {NEW_PART_TEXT.map((data,index)=>
                            <NewWebTextItem 
                                key={`new-text-${index}`} 
                                id={data.id} 
                                title1={data.title1[number]} 
                                content1={data.content1[number]} 
                                content2={data.content2[number]} 
                                activeState={activeState === data.id} 
                                clickEvent={clickEvent}
                                fontSize={24}
                                mFontSize={20}
                            ></NewWebTextItem>
                        )}
                    </TextWrap>
                    {/* <Btn>View More <BsChevronRight></BsChevronRight></Btn> */}
                </RightWrap>
            </ContentWrap>
        </div>
    )
}

const ContentWrap = styled(Row)`
    flex-wrap: wrap;
    @media ${addTheme.media.m}{
        &>div{
            width:100%;
            margin-right: 0;
        }
    }
`;

const LeftWrap = styled.div`
    width: 40%;
    margin-right: 15%;
    position: relative;
   
    &>img{
        max-width: 100%;
        border-radius: 30px;
    }
`;

const SliderWrap = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
`;
const RightWrap = styled.div`
    width: 45%;
    @media ${addTheme.media.m}{
        margin-top: 40px;
    }
`;

const TextWrap = styled.div`
    margin-top: 50px;
`;

const Btn = styled.button`
    margin-top: 60px;
    background-color: ${addTheme.color};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    font-size: 19px;
    height: 55px;
    border-radius: 12px;
    & svg{
        margin-left: 10px;
    }
`;