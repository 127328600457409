import { axiosConfigInstance } from "./config";

// 앨범의 음악리스트
export const apiGetAlbumSoundList = async(token:string,user_id:any,album_id:any) => {
    try {
        const res = await axiosConfigInstance('/sound/album',token).post('',{
            method:'getAlbumSoundList',
            params:{
                user_id,
                album_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}