import { axiosConfigInstance } from "./config";

// 음악 재생 시작시
export const apiSetMusicStart = async(token:string,user_id:any,sound_id:any,start_timestamp:any) =>{
    try {
        const res = await axiosConfigInstance('/sound/log',token).post('',{
            method:'getStartSoundPlay',
            params:{
                user_id,
                sound_id,
                start_timestamp,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 음악 로그
export const apiSetMusicLog = async(token:string,user_id:any,sound_id:any,start_timestamp:any,end_timestamp:any,log_usage:any) =>{
    try {
        const res = await axiosConfigInstance('/sound/log',token).post('',{
            method:'logSoundPlayReward',
            params:{
                user_id,
                sound_id,
                start_timestamp,
                end_timestamp,
                log_usage,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

