import { axiosConfigInstance } from "./config";

export const TERMS_ITEM = {
    terms_content:'',
    terms_id:0,
    terms_title:'',
    terms_type:'0',
    terms_webview:'',
}

// 약관
export const apiGetTermsList = async(token:string,user_id:number) =>{
    try {
        const res = await axiosConfigInstance('/platform/terms',token).post('',{
            method:'getTermsList',
            params:{
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}