import { axiosAI, axiosConfigInstance } from "./config";

// 타입
export const BANNER_ITEM = {banner_id: 0, banner_url: ''};

export const MUSIC_ITEM = {
    "sound_id": 0,
    "album_id": 0,
    "user_id": 14,
    "title": "R&B Delicacy",
    "url": "",
    "genre": "RnB & Soul",
    "sound_category": "1",
    "sound_bpm": "100",
    "sound_duration": "3:04",
    "duration": 18400,
    "sound_mood": "Dreamy, Elegant, Glamorous, Dark, Laid Back, Smooth, Broadcasting, Corporate, Fashion & Beauty, Gaming, Photography, Tutorials, Vlogs, Workout & Wellness",
    "sound_timestamp": "1685608809134",
    "sound_visible": "1",
    "album_name": "ATO-05-ALBUM-01",
    "artwork": "https://music.dapi.subox.co.kr/sound_album/album_image_13.jpg",
    "album_timestamp": "1685582424000",
    "artist": "ATO creator 05",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png",
    "user_country": "ko"
}

export const NEW_MUSIC = {
    "main_title": "두근두근 신곡 BGM",
    "main_title_ko": "두근두근 신곡 BGM",
    "main_title_en": "두근두근 신곡 BGM",
    "data": [MUSIC_ITEM]
}

export const NEW_ALBUM_ITEM = {
    "album_id": 0,
    "album_name": "ATO-02-ALBUM-01",
    "sound_count": 19,
    "artwork": "https://music.dapi.subox.co.kr/sound_album/album_image_4.jpg",
    "album_timestamp": "1685582424000",
    "album_visible": "1",
    "user_id": 11,
    "artist": "ATO creator 02",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png"
}

export const NEW_ALBUM = {
    "main_title": "아티스트 신규 앨범 📀",
    "main_title_ko": "아티스트 신규 앨범",
    "main_title_en": "아티스트 신규 앨범",
    "data": [NEW_ALBUM_ITEM]
}

export const PLAYLIST_ITEM = {
    "playlist_id": 0,
    "playlist_name": "한시간 빡세게 운동하고 싶을 때",
    "playlist_thumbnail": "https://music.dapi.subox.co.kr/playlist_thumbnail/playlist_thumb_1.png",
    "playlist_category": "1",
    "sound_count": 31,
    "user_id": 10,
    "user_nickname": "ATO creator 01",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png",
    "user_country": "ko"
}

export const PLAYLIST = {
    "main_title": "러닝이 더 즐거워 지는 일렉 팝 💥",
    "main_title_ko": "러닝이 더 즐거워 지는 일렉 팝",
    "main_title_en": "러닝이 더 즐거워 지는 일렉 팝 💥",
    "data": [PLAYLIST_ITEM]
}

export const MUSIC_INFO = {
    "sound_id": 0,
    "album_id": 20,
    "user_id": 16,
    "title": "Smooth Soul Jams",
    "url": "",
    "genre": "RnB & Soul",
    "sound_category": "1",
    "sound_bpm": "105",
    "sound_duration": "2:55",
    "duration": 17500,
    "sound_mood": "Happy, Hopeful, Dreamy, Ads & Trailers, Cinematic, Nature, Photography, Travel, Vlogs, Documentary, Fashion & Beauty, Workout & Wellness",
    "sound_timestamp": "1685608807112",
    "sound_visible": "1",
    "album_name": "ATO-07-ALBUM-01",
    "artwork": "https://music.dapi.subox.co.kr/sound_album/album_image_19.jpg",
    "album_timestamp": "1685582424000",
    "artist": "ATO creator 07",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png",
    "user_country": "ko",
    "user_cover": "https://music.dapi.subox.co.kr/user_cover/user_cover_2.jpg",
    "block_id": "807112"
}

// api
// 배너 가져오기
// export const apiGetBanner = async(token:any,user_id:any) =>{
//     try {
//         const res = await axiosConfigInstance('/platform/banner',token).post('',{
//             method:'getMainBigBannerList',
//             params:{
//                 user_id
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return {
//             error,
//             result:false,
//         }
//     }
// }

// 음악페이지 배너
export const apiGetMusicBanner = async(token:string,user_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/platform/banner',token).post('',{
            method:'getMainBannerList',
            params:{
                user_id,
                page,
                count,
                banner_type:3,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 신곡 가져오기
export const apiGetNewMusic = async(token:any,user_id:any,page:number) =>{
    try {
        const res = await axiosConfigInstance('/sound',token).post('',{
            method:'getSoundRankingList',
            params:{
                user_id,
                page:page,
                count:25
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
    
}

// 신규 앨범
export const apiGetNewAlbum = async(token:string,user_id:any,page:number) =>{
    try {
        const res = await axiosConfigInstance('/sound/album',token).post('',{
            method:'getNewAlbumList',
            params:{
                user_id,
                page:page,
                count:10
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 플레이리스트
export const apiGetPlayList = async(token:string,user_id:any,page:number,part:string) =>{
    try {
        const res = await axiosConfigInstance('/sound/playlist',token).post('',{
            method:'getSummaryPlayList',
            params:{
                user_id,
                page,
                count:10,
                part
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 해당 음악 정보 가져오기
export const apiGetMusicInfo = async(token:string,user_id:any,sound_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sound',token).post('',{
            method:'getSoundInfo',
            params:{
                user_id,
                sound_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 유사곡
export const apiGetSimilarList = async(token:string,user_id:any,sound_id:any,) =>{
    try {
        const res = await axiosConfigInstance('/sound',token).post('',{
            method:'getSimilarSoundList',
            params:{
                user_id,
                sound_id,
                page:1,
                count:5,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

//  ai 만들수 있는 장르 리스트
export const apiGetAiGenre = async(token:string,user_id:any) =>{
    try {
        const res = await axiosAI('/generate/info',token).post('',{
            method: "supportGenMusicList",
            params:{
                user_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}



// ai 음악 만들기
export const apiGetAiMusic = async(token:string,user_id:any) =>{
    try {
        const res = await axiosAI('/generate/music',token).post('',{
            method: "getGenerateMusic",
            params: {
                user_id: user_id,
                pack: "technopop",
                min_bpm: 70,
                max_bpm: 200,
                target_duration: 300
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

