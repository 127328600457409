import React from 'react'
import { ALBUM_ITEM, SOUND_ITEM } from '../../../api/api_user';
import styled from 'styled-components';
import { TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import NoItem from '../../common/item/NoItem';
import MusicItem from '../../search/item/MusicItem';
import UserAlbumItem from './UserAlbumItem';
import PaginationItem from '../../common/item/PaginationItem';

interface userContentTabItemType{
    cnt:number;
    list: Array<typeof SOUND_ITEM | typeof ALBUM_ITEM | any>;
    total:number;
    type:'sound'|'album';
    page:number;
    setPage:any;
}

export default function UserContentTabItem({cnt,list,total,type,page,setPage}:userContentTabItemType) {
    return (
        <Wrap>
            <TextItem color={addTheme.color} fontSize={14}>전체 {cnt}</TextItem>
            {list.length === 0 || list[0].album_id === 0 || list[0].sound_id === 0 ?
                <NoItem></NoItem> :
                list.map((data,index)=>{
                    if(type==='sound'){
                        return(
                            <MusicItem key={`music-item-${index}`} data={data}></MusicItem>
                        )
                    }
                    if(type==='album'){
                        return(
                            <UserAlbumItem key={`album-item-${index}`} data={data}></UserAlbumItem>
                        )
                    }
                })
            }
            <PageWrap>
                <PaginationItem nowPage={page} setPage={setPage} total={total}></PaginationItem>
            </PageWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 20px;
`;

const PageWrap = styled.div`
    margin-top: 30px;
`;