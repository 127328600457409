import React from 'react'
import styled from 'styled-components'
import { Row, TextItem } from '../../../style/basic/commonStyle'

const STEP = [
    {id:1,title:`음악 컨텐츠 제작`,content:`제작 가이드에 맞춰 콘텐츠를 제작하고 업로드해 주세요.`},
    {id:2,title:`심사 요청`,content:`심사는 약 2주간 소요되며 내부 기준에 따라 검토 후 결과를 알려드릴게요. 심사 결과는 알림메시지로 알려드리며, '내 음악 > 업로드한 음악' 메뉴에서도 확인할 수 있어요.`},
    {id:3,title:`공개 시작`,content:`이제 크리에이터님의 콘텐츠가 슈박스에 공개되었습니다. 수익도 창출할 수 있습니다.`},
]

const NOTE = [
    {id:1,content:`· 업로드한 음원이 다른 이의 지적재산권을 침해한 경우, 다른 음원을 표절하거나 타인의 기존 저작물을 허락없이 개작한 경우 등을 포함한 음원과 관련된 모든 법적 책임은 업로드한 크리에이터에게 있음을 알려드립니다.`},
    {id:2,content:`· 로열티 무료 샘플루트를 그대로 올리는 경우, 음질이 문제(깨짐, 잡음 등)가 있는 경우, 볼륨이 너무 크거나 작아서 사용에 적합하지 않은 경우에는 음원 심사를 통과하는데 어려움이 있을 수 있습니다.`},
]

const QUESTION = [
    {id:1,title:`Q. 심사 기간은 얼마나 걸리나요?`,content:`심사 기간은 일반적으로 2주 정도 걸리며, 심사 요청이 폭증하는 경우 조금 더 늦어질 수 있습니다. 보내주시는 모든 콘텐츠를 살펴보며 논의하는 과정이 필요하니 조금 오래 걸려도 너그러운 마음으로 이해 부탁드립니다. 심사 결과는 '내 음악 > 업로드한 음악' 메뉴에서도 확인할 수 있습니다.`},
    {id:2,title:`Q. 미승인 사유에 대해 알고 싶어요?`,content:`크리에이터님이 제출해 주신 모든 콘텐츠 제작 가이드와 내부 메뉴얼에 따라 꼼꼼하게 심사가 진행됩니다. 내부 메뉴얼은 콘텐츠의 다양성과 크리에이티브를 위해 외부로 공개하지 않으며, 미승인 사유에 대해 문의하셔도 알려드리지 못하는 점 양해 부탁드립니다.`},
    {id:3,title:`Q. 제출한 콘텐츠를 수정하고 싶어요.`,content:`크리에터님이 제출해 주신 콘텐츠는 수정 및 삭제가 불가능합니다. 잘못 제출하거나 수정할 내용이 있는 경우, 새 콘텐츠로 업로드해 주시고, 기존 콘텐츠는 공개 중지를 해주세요.`},
]

export default function UploadGuidePart() {
    return (
        <Wrap>
            <TextItem textAlign='center' fontSize={18}>음악 업로드 가이드라인</TextItem>
            <TextItem textAlign='center' marginTop={30}>참여 방법</TextItem>
            {STEP.map((data,index)=>{
                return(
                    <Row style={{marginTop:'40px',alignItems:'start'}} key={`step-${index}`}>
                        <TextItem w='25%' fw={700} style={{flexShrink:0}}>STEP {data.id}</TextItem>
                        <div>
                            <TextItem fw={700}>{data.title}</TextItem>
                            <TextItem fontSize={14} marginTop={10}>{data.content}</TextItem>
                        </div>
                    </Row>
                )
            })}
            <TextItem textAlign='center' marginTop={60} fw={700}>유의 사항</TextItem>
            {NOTE.map((data,index)=>{
                return(
                    <TextItem key={`note-${index}`} marginTop={25}>{data.content}</TextItem>
                )
            })}
            <TextItem textAlign='center' marginTop={60} fw={700}>자주 묻는 질문</TextItem>
            {QUESTION.map((data,index)=>{
                return(
                    <div key={`question-${index}`}>
                        <TextItem marginTop={20}>{data.title}</TextItem>
                        <TextItem marginTop={10} fontSize={14}>{data.content}</TextItem>
                    </div>
                )
            })}
            <TextItem marginTop={40}>문의 메일 : help@subox.co.kr</TextItem>
        </Wrap>
    )
}

const Wrap = styled.div``;
