import React from 'react'
import { ALBUM_ITEM } from '../../../api/api_user'
import styled from 'styled-components';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setAlbum } from '../../../store/common/clickAlbum';

interface userAlbumItemType{
    data: typeof ALBUM_ITEM;
}

export default function UserAlbumItem({data}:userAlbumItemType) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const detail = () =>{
        dispatch(setAlbum({...data,type:'album'}));
        navigate(`/searchAlbumDetail/${data.album_id}`);
    }

    return (
        <Wrap>
            <ImgItem onClick={detail}>
                <img alt='album-img' src={data.artwork}></img>
            </ImgItem>
            <TextWrap>
                <TextItem onClick={detail} fontSize={17} fw={700}>{data.album_name}</TextItem>
                <TextItem marginTop={8} fontSize={15}>{data.sound_count}</TextItem>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    margin-top: 35px;
    flex-wrap: wrap;
`;

const ImgItem = styled.div`
    width: 65px;
    height: 65px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 10px;
    cursor: pointer;
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 110%;
        width: 100%;
    }
    @media ${addTheme.media.m}{
        width: 55px;
        height: 55 px;
    }
`;

const TextWrap = styled.div``;