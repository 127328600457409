import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle'
import { useDispatch } from 'react-redux'
import { setGlobalReport } from '../../../store/common/globalReport';
import { apiGetUserVisibleList, apiSetUserVisibleOff, apiSetUserVisibleOn } from '../../../api/api_user';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { setGlobalAlert } from '../../../store/common/globalAlert';

export default function UserReportPart({targetId}:any) {
    const [state,setState] = useState(false);
    const user = useUserInfo();
    const dispatch = useDispatch();

    const reportAction = () =>{
        dispatch(setGlobalReport({
            open:true,
            id:targetId,
            title:'해당 유저를 신고하시겠습니까?',
            type:1
        }))
    }

    const alertAction = (txt:string) =>{
        dispatch(setGlobalAlert({
            open:true,
            title:txt,
            content:'언제든 숨기기/해제를 할 수 있어요'
        }))
    }

    useEffect(()=>{
        getVisible();
    },[]);

    const getVisible = async() =>{
        const res = await apiGetUserVisibleList(user.jwt_token,user.user_id);
        if(res.result){
            const item = res.data.filter((data:any)=>data.target_id === Number(targetId));
            if(item.length === 0){
                setState(false);
            }else{
                const typeItem = item.filter((data:any)=>data.non_visible_type === '4');
                if(typeItem.length === 0){
                    setState(false);
                }else{
                    setState(true);
                }
            }
        }
    }

    const visibleAction = async() =>{
        if(!state){
            const res = await apiSetUserVisibleOn(user.jwt_token,user.user_id,targetId,'1');
            if(res.result){
                alertAction('해당 유저의 활동을 숨겼습니다.');
                getVisible();
                return;
            }
            alertAction('잠시후 다시 시도해주세요.');
        }else{
            const res = await apiSetUserVisibleOff(user.jwt_token,user.user_id,targetId,'1');
            if(res.result){
                alertAction('해당 유저의 활동 숨김을 해제했습니다.');
                getVisible();
                return;
            }
            alertAction('잠시후 다시 시도해주세요.');
        }
    }

    return (
        <Wrap>
            {/* <ItemWrap onClick={reportAction}>
                신고하기    
            </ItemWrap>
            <ItemWrap onClick={visibleAction}>
                이 유저의 활동 숨기기 {state && '해제'}
            </ItemWrap> */}
        </Wrap>
    )
}

const Wrap = styled(Row)`
    justify-content: end;
`;

const ItemWrap = styled.div`
    margin-right: 15px;
    font-size: 13px;
    cursor: pointer;
    &:nth-last-child(1){
        margin-right: 0;
    }
`;