import React from 'react'
import styled from 'styled-components'
import { Center, TextItem } from '../../../style/basic/commonStyle';

export default function WelcomeItem({data}:any) {
    return (
        <Wrap>
            <ImgWrap>
                <img alt='welcome-img' src={data.img} />
            </ImgWrap>
            <Title>{data.title}</Title>
            <Content>{data.content}</Content>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
    border-radius: 18px;
    background-color: #fff;
    margin: 10px 0;
    padding: 65px 0;
    width: 100%;
    text-align: center;
`;

const ImgWrap = styled.div`
    width: 50%;
    position: relative;
    &:after{
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }
`;

const Title = styled.div`
    font-family: GmarketSans;
    font-weight: 700;
    color: #000;
    margin-top: 16px;
`;

const Content = styled.div`
    font-family: GmarketSans;
    font-weight: 500;
    color: #000;
    margin-top: 6px;
`;