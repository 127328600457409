import React from 'react'
import { Center } from '../../../style/basic/commonStyle'
import { useSearchParams } from 'react-router-dom'
import { Pagination } from '@mui/material';
import styled from 'styled-components';

export default function PaginationItem({nowPage,total,setPage}:any) {
    // const [searchParams,setSearchParams] = useSearchParams();
    const pageChange = (page:any)=>{
        setPage(page);
        // searchParams.set('p',page);
        // setSearchParams(searchParams);
    }

    return (
        <Wrap>
            {total !== 0 &&
                <Pagination shape="rounded" page={nowPage} count={total} onChange={(e:any, page:any) => pageChange(page)} sx={{'.MuiPagination-ul li button':{color:'#fff'},'.MuiPagination-ul li .Mui-selected':{color:'#111'}}} color='secondary'></Pagination>
            }
        </Wrap>
    )
}

const Wrap = styled(Center)`
    & .MuiPaginationItem-root.Mui-selected{
        background-color: #d3d3d3;
        color: #111 !important;
    }
    & .MuiPaginationItem-root.MuiPaginationItem-ellipsis{
        color:rgba(255, 255, 255, 0.87);
    }
`;