import React, { useEffect, useState } from 'react'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import LayoutPage from '../../common/page/LayoutPage';
import { useNavigate, useParams } from 'react-router';
import UserInfoPart from '../part/UserInfoPart';
import styled from 'styled-components';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import UserContentPart from '../part/UserContentPart';
import { PROFILE, apiGetUserProfile } from '../../../api/api_user';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../store/common/globalAlert';
import { useGlobalAlert } from '../../../utils/custom-hooks/common/useGlobalAlert';
import UserReportPart from '../part/UserReportPart';
import Head from '../../../routes/Head';

export default function UserPage() {
    const {id} = useParams();
    const user = useUserInfo();
    const targetId = (id === undefined || id === null) ? 0 : id;
    const [profile,setProfile] = useState(PROFILE);
    const navigate = useNavigate();

    useEffect(()=>{
        getUser();
    },[]);

    const getUser = async() =>{
        const res = await apiGetUserProfile(user.jwt_token,user.user_id,targetId);
        if(res.result){
            setProfile(res.data);
            return;
        }
        window.alert('올바른 정보가 아닙니다.');
        navigate('/');
    }

    return (
        <LayoutPage>
            <Head title='profile' />
            <MaxWidthWrap>
                <Wrap>
                    <UserReportPart targetId={targetId}></UserReportPart>
                    <UserInfoPart profile={profile}></UserInfoPart>
                    <UserContentPart targetId={targetId}></UserContentPart>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    margin-top: 50px;
`;

