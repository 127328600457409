import React, { useEffect, useRef, useState } from 'react'
import { Center, Row } from '../../../style/basic/commonStyle';
import styled,{css, keyframes} from 'styled-components';
import {IoPlay,IoPause} from 'react-icons/io5';
import { addTheme } from '../../../style/addTheme';
import WaveSurfer from 'wavesurfer.js';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import { durationFormat } from '../../../utils/format/time';
import BasicText from '../../basic/BasicText';
import { useDispatch } from 'react-redux';
import { useGlobalMusicPlay } from '../../../utils/custom-hooks/common/useGlobalMusicPlay';
import { setMusicPlay } from '../../../store/common/globalMusicPlay';

const formatTime = (seconds:any) => {
    const minutes = Math.floor(seconds / 60)
    const secondsRemainder = Math.round(seconds) % 60
    const paddedSeconds = `0${secondsRemainder}`.slice(-2)
    return `${minutes}:${paddedSeconds}`
}

const WaveItem = ({url,mShow,duVal,setDuVal}:any)=>{
    const dispatch = useDispatch();
    const musicPlay = useGlobalMusicPlay();
    const [playState,setPlayState] = useState(false);
    const [loading,setLoading] = useState(false);
    const [durNum,setDurNum] = useState(0);
    const [currentTime,setCurrentTime] = useState(0);
    const durationVal = useRef('0');
    const waveform = useRef<any>(null);
    const container = useRef<any>(null);
    const audio = useRef<any>(null);

    const time = useRef<any>(null);
    const duration = useRef<any>(null);

    useEffect(()=>{
        if(url === '') return;
        setDurNum(0);
        setCurrentTime(0);
        audio.current = new Audio();
        audio.current.src = url;
        audio.current.addEventListener('loadedmetadata',()=>{
            setDurNum(audio.current.duration);
        })
        audio.current.addEventListener('timeupdate', function() {
            setCurrentTime(audio.current.currentTime);
        });
        audio.current.addEventListener('ended',function(){
            setCurrentTime(0);
            setPlayState(false);
            audio.current.currentTime = 0;
        })

        return () =>{
            audio.current.pause();
            setPlayState(false);
            audio.current.removeEventListener('loadedmetadata', () => {
                setDurNum(0);
            });
            audio.current.removeEventListener('timeupdate', () => {
                setCurrentTime(0);
            });
        }
    },[url]);

    useEffect(()=>{
        if(musicPlay.url === '') return;
        if(url === '') return;
        if(musicPlay.type === 'player'){
            audio.current.pause();
            setPlayState(false);
            return;
        }
        if(musicPlay.url !== url){
            audio.current.pause();
            setPlayState(false);
            return;
        }
    },[musicPlay.url,musicPlay.type,url])

    // useEffect(()=>{
    //     if(url === undefined || url === null || url === '') return;
    //     if (waveform.current) {
    //         waveform.current.destroy();
    //         setPlayState(false);
    //         setLoading(false);
    //         durationVal.current = '0';
    //     }
    //     const audio = new Audio()
    //     audio.controls = true
    //     audio.src = url;
    //     waveform.current = WaveSurfer.create({
    //         container: container.current,
    //         waveColor: "#fff",
    //         barGap: 2,
    //         barWidth: 1,
    //         barRadius: 1,
    //         cursorWidth: 2,
    //         cursorColor: "#00ff9c",
    //         height:42,
    //         media: audio,
    //     });
        
    //     // waveform.current.load(url);
    //     waveform.current.on('play',()=>{
    //         setPlayState(true);
    //     })
    //     waveform.current.on('pause',()=>{
    //         setPlayState(false);
    //     })
    //     waveform.current.on('decode', (duration:any) => {
    //         const du = formatTime(duration);
    //         durationVal.current = du;
    //         if(duVal !== undefined){
    //             setDuVal(durationVal.current);
    //         }
    //         if(time.current === null) return;
    //         time.current.innerHTML = du;
    //     })
    //     waveform.current.on("ready", (data:any) => {
    //         setLoading(true);
    //         const peaks = waveform.current.getDecodedData();
    //     });
    
    //     waveform.current.on('timeupdate', (currentTime:any) => {
    //         const nowTime = formatTime(currentTime);
    //         if(duration.current === null) return;
    //         duration.current.innerHTML = nowTime;
    //     })
    //     return () =>{
    //         waveform.current.destroy();
    //     }
    // },[url]);

    const handleSeek = (e:any) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = x / rect.width;
        const newTime = percentage * durNum;
        audio.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const playAction = () =>{
        if(!playState){
            dispatch(setMusicPlay({
                url: url,
            }))
            document.querySelectorAll('audio').forEach((el:any) =>{
                if(el === undefined || el === null) return;
                el.pause();
            })
            setPlayState(true);
            audio.current.play();
        }else{
            setPlayState(false);
            audio.current.pause();
        }
        // if (waveform.current.isPlaying()) {
        //     waveform.current.pause();
        // } else {
        //     waveform.current.play();
        // }
    }
    
    return (
        <PlayWrap>
            {!playState ? 
                <IoPlay onClick={playAction} size={28}></IoPlay> :
                <IoPause onClick={playAction} size={28} color='#00ff9c'></IoPause>}
                <BasicText fontSize={15}>{durationFormat(durNum)}</BasicText>
            {/* {loading ? 
                !playState ? 
                <IoPlay onClick={playAction} size={28}></IoPlay> :
                <IoPause onClick={playAction} size={28} color='#00ff9c'></IoPause>
            :   <Loading loadingState={loading}>
                    <AiOutlineLoading3Quarters size={28} color='#fff'></AiOutlineLoading3Quarters>
                </Loading> 
            } */}
            {/* <Waveform style={{width:'100%'}} ref={container} mShow={false}>
                {mShow &&
                    <>
                        <TimeItem type={'right'} ref={time}>0:00</TimeItem>
                        <TimeItem type={'left'} ref={duration}>0:00</TimeItem>
                    </>
                }
            </Waveform> */}
            <Waveform >
                <LineWrap onClick={handleSeek}>
                    <Line w={(currentTime / durNum) * 100} />
                </LineWrap>
                <BasicText style={{flexShrink:0}}>{currentTime === 0 ? '0:00' : durationFormat(currentTime)}</BasicText>
            </Waveform>
            
            {/* {!mShow && loading &&
                <p>{durationVal.current}</p>
            } */}
        </PlayWrap>
    )
}

const PlayWrap = styled(Row)`
    width: 100%;
    display: flex;
    gap: 10px;
    &>svg{
        /* margin: 0 10px 0 5px; */
        cursor: pointer;
    }
    &>svg:hover{
        color: #00ff9c;
    }
    @media ${addTheme.media.m}{
        width: auto;
        &>svg{
            margin: 0;
        }
    }
`;

const Waveform = styled(Row)<{mShow?:boolean}>`
    width: 100%;
    position: relative;
    gap: 10px;
    @media ${addTheme.media.m}{
        display: ${props => props.mShow === true ? 'block' : 'none'};
    }
`;

const TimeItem = styled.div<{type:string}>`
    position: absolute;
    z-index: 11;
    bottom: -20px;
    /* transform: translateY(-50%); */
    font-size: 11px;
    background: rgba(0, 0, 0, 0.75);
    padding: 2px;
    color: #ddd;
    ${props => props.type === 'left' ? css`left:-5px;`:css`right:-5px;`}
`;

const ani = keyframes`
    100% {
        transform:rotate(360deg);
    }
`;

const Loading = styled.div<{loadingState:boolean}>`
    & svg{
        ${props => props.loadingState ? '' : css`
            animation:${ani} 1s linear infinite;
        `}
    }
`;

const LineWrap = styled(Center)`
    width: 80%;
    height: 4px;
    background-color: #6a6a6a;
    border-radius: 5px;
    justify-content: start;
    overflow: hidden;
`;
const Line = styled.div.attrs(({w}:{w:number}) =>({
    style:{
        width: `${w ?? 0}%`,
    }
}))<{w:number}>`
    height: 2px;
    background-color: #00ff3c;
    border-radius: 5px;
    overflow: hidden;
`;
// const Line = styled.div<{w:number}>`
//     width: ${({w}) => w ?? 0}%;
//     height: 2px;
//     background-color: #00ff3c;
//     border-radius: 5px;
//     overflow: hidden;
// `;

export default WaveItem;