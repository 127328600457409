import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const AUDIO_SET = 'SUBOX_AUDIO/SET';

export const setAudioSetCreator = createAction(AUDIO_SET);

export function setAudioData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setAudioSetCreator>> {
    
    return async function (dispatch) {
        try {
                dispatch(setAudioSetCreator({
                    data: data
                }));
        } catch (error) {
        
        }
    };
}

export interface audioType {
    name:string;
    singer:string;
    cover:string;
    musicSrc:string;
}

const initialState:Array<audioType> = [];


export const globalAudio = handleActions<audioType | any>({
    [AUDIO_SET]: (state, action: ReturnType<typeof setAudioSetCreator>) => (
       action.payload.data ?? state
    ),
  }, initialState);
