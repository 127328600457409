import React, { useEffect, useState } from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import { Center, MaxWidthWrap, Row, TextItem } from '../../../style/basic/commonStyle'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import NoticeItem from '../../Intro/item/NoticeItem';
import { momentFormat } from '../../../utils/format/time';
import PaginationItem from '../../common/item/PaginationItem';
import { NOTICE_ITEM, apiGetNotice } from '../../../api/api_home';
import { useNavigate, useParams } from 'react-router';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';
import Head from '../../../routes/Head';

const TYPE = [{title:'PRESS',color:'#bbff00'},{title:'INSIGHT',color:'#ff5f00'}];

const LIST = [
    {id:1,type:0,title:'슈박스, 웹 3.0 표준화 및 인프라 구축 앞장선다',time:1689914474692,img:'/img/st-1.png'},
    {id:2,type:1,title:'새로운 트랜드 조각 투자, 그것이 궁금하다',time:1689914474692,img:'/img/st-2.png'},
    {id:3,type:1,title:'슈박스, 웹 3.0 표준화 및 인프라 구축 앞장선다',time:1689914474692,img:'/img/st-1.png'},
    {id:4,type:0,title:'슈박스, 웹 3.0 표준화 및 인프라 구축 앞장선다',time:1689914474692,img:'/img/st-2.png'},
    {id:5,type:1,title:'새로운 트랜드 조각 투자, 그것이 궁금하다',time:1689914474692,img:'/img/st-1.png'},
    {id:6,type:1,title:'슈박스, 웹 3.0 표준화 및 인프라 구축 앞장선다',time:1689914474692,img:'/img/st-2.png'},
]

export default function NoticePage() {
    const {number} = useGlobalLang();
    const navigate = useNavigate();
    const [total,setTotal] = useState(0);
    const [list,setList] = useState<any>([]);
    const [list_kr,setList_kr] = useState<any>([]);
    const [list_en,setList_en] = useState<any>([]);

    const {page} = useParams();
    let nowPage = Number(page) ?? 1;

    useEffect(()=>{
        getList();
    },[])

    useEffect(()=>{
        if(number === 0){
            setList(list_kr);
            return;
        }
        if(number === 1){
            setList(list_en);
        }
    },[number,list_kr,list_en])

    const getList = async() =>{
        const res = await apiGetNotice(1,9);
        
        if(res.result){
            setList_kr(res.data.reverse());
            setList_en(res.data_en.reverse());
            return;
        }
        setList_kr([]);
        setList_en([]);
    }

    const pageMove = (page:any) =>{
        navigate(`/notice/${page}`);        
    }

    return (
        <LayoutPage>
            <Head title='notice' />
            <MaxWidthWrap>
                <TextItem textAlign='center' fw={700} fontSize={addTheme.titleFontSize} mFontSize={addTheme.mTitleFontSize} marginTop={60}>Press & Insight</TextItem>
                <ListWrap itemWidth={(list.length === 0 || list[0].notice_id === 0)}>
                {list.length === 0 || list[0].notice_id === 0 ?
                    <Center>
                        No items to display
                    </Center>
                : list.map((data:any,index:any)=>{
                    return(
                        <NoticeItem
                            key={`press-item-${index}`}
                            // type={TYPE[data.notice_type].title}
                            // color={TYPE[data.notice_type].color}
                            // title={data.notice_title}
                            // img={data.notice_img.length === 0 ? '' : data.notice_img[0]}
                            // time={momentFormat('yyyy.MM.DD',data.notice_time)}
                            id={data.id}
                            type={'NOTICE'}
                            title={data.title}
                            img={data.img}
                            time={data.date}
                        ></NoticeItem>
                    )
                })}
                </ListWrap>
                <PageWrap>
                    <PaginationItem nowPage={nowPage} setPage={pageMove} total={total}></PaginationItem>
                </PageWrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const ListWrap = styled(Row)<{itemWidth:boolean}>`
    flex-wrap: wrap;
    margin-top: 80px;
    &>div{
        width: ${props=>props.itemWidth ? 100 : 31.33}%;
        margin: 1%;
    }

    @media ${addTheme.media.m}{
        &>div{
            width: ${props=> props.itemWidth ? 100 : 48}%;
        }
    }
`;

const PageWrap = styled.div`
    margin-top: 80px;
`;