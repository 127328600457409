import React,{useState,useEffect,useCallback} from 'react'
import styled from 'styled-components'
import SliderPart from '../../common/part/SliderPart';
import { BOTTOM_BANNER_ITEM } from '../../../api/api_home';
import BottomBannerItem from '../item/BottomBannerItem';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

const DUMMY = [
    {
        "bb_id": 1,
        "bb_title": "음악을 사랑하는 크리에이터라면?\r\n지금 슈박스를 다운로드하세요!",
        "bb_title_en":"If you're a music-loving creator?\r\nDownload SUBOX now!",
        "bb_link": "https://play.google.com/store/apps/details?id=com.subox_platform",
        "bb_img": "/img/st-1.png",
        "bb_time": 1691037314232,
        "bb_update_time": 0,
        "bb_del": 0
    },
    // {
    //     "bb_id": 2,
    //     "bb_title": "음악을 사랑하는 크리에이터라면?\r\n지금 슈박스를 다운로드하세요!",
    //     "bb_link": "",
    //     "bb_img": "/img/st-2.png",
    //     "bb_time": 1691037314232,
    //     "bb_update_time": 0,
    //     "bb_del": 0
    // },
    // {
    //     "bb_id": 3,
    //     "bb_title": "음악을 사랑하는 크리에이터라면?\r\n지금 슈박스를 다운로드하세요!",
    //     "bb_link": "",
    //     "bb_img": "/img/st-1.png",
    //     "bb_time": 1691037314232,
    //     "bb_update_time": 0,
    //     "bb_del": 0
    // },
    // {
    //     "bb_id": 4,
    //     "bb_title": "음악을 사랑하는 크리에이터라면?\r\n지금 슈박스를 다운로드하세요!",
    //     "bb_link": "",
    //     "bb_img": "/img/st-2.png",
    //     "bb_time": 1691037314232,
    //     "bb_update_time": 0,
    //     "bb_del": 0
    // },
]

export default function BannerPart() {
    const {number} = useGlobalLang();
    const [list,setList] = useState([BOTTOM_BANNER_ITEM]);
    const [dragging, setDragging] = useState(false);

    const handleBeforeChange = useCallback(() => {
        setDragging(true);
    }, [setDragging]);
    
    const handleAfterChange = useCallback(() => {
        setDragging(false);
    }, [setDragging]);

    const clickEvent = (link:string) =>{
        if(dragging) return;
        if(link === '') return;
        window.open(link);
    }

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
        // const res = await apiGetBottomBannerList();
        // if(res.result){
        //     setList(res.data);
        //     return;
        // }
        // setList([BOTTOM_BANNER_ITEM]);
    }

    return (
        <Wrap>
            <SliderPart data={{
                beforeChange: handleBeforeChange,
                afterChange: handleAfterChange,
            }}>
                {
                    DUMMY.map((data,index)=>{
                        return (
                            <BottomBannerItem
                                key={`banner-item-${index}`}
                                title={number === 0 ? data.bb_title : data.bb_title_en}
                                link={data.bb_link}
                                img={data.bb_img}
                                clickEvent={clickEvent}
                            ></BottomBannerItem>
                        )
                    }) 
                }
            </SliderPart>
        </Wrap>
    )
}

const Wrap = styled.div`
    & .slick-dots li{
        width: 8px;
        height: 8px;
        opacity: 0.25;
        border-radius: 50%;
        background-color: #fff;
        transition: 0.3s ease-in-out;
    }
    & .slick-dots .slick-active{
        width: 16px;
        opacity: 0.8;
        border-radius: 16px;
    }

    & .slick-dots li button::before{content:none;}
`;
