import React,{useRef,useState,useEffect} from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import { MaxWidthWrap } from '../../../style/basic/commonStyle'
import styled from 'styled-components';
import IntroVideoPart from '../part/IntroVideoPart';
import NewWebPart from '../part/NewWebPart';
import EveryDayPart from '../part/EveryDayPart';
import InvestPart from '../part/InvestPart';
import NewEnjoyPart from '../part/NewEnjoyPart';
import { addTheme } from '../../../style/addTheme';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import GrowthPart from '../part/GrowthPart';
import NewDimensionPart from '../part/NewDimensionPart';
import PartnerPart from '../part/PartnerPart';
import PressPart from '../part/PressPart';
import BannerPart from '../part/BannerPart';
import YoutubePart from '../part/YoutubePart';

export default function IntroPage() {

    const matches = useMediaQuery(addTheme.media.m);
    return (
        <LayoutPage padding={'0 0 120px'}>
            <Wrap>
                <IntroVideoPart></IntroVideoPart>
                <MaxWidthWrap>
                    <MarginBox mt={150}></MarginBox>
                    <NewWebPart></NewWebPart>
                </MaxWidthWrap>
                <MarginBox mt={matches ? 200 : 350}></MarginBox>
                <EveryDayPart></EveryDayPart>
                <MaxWidthWrap>
                    <MarginBox mt={matches ? 150 : 250}></MarginBox>
                    {/* <YoutubePart></YoutubePart> */}
                    <MarginBox mt={matches ? 150 : 250}></MarginBox>
                    <InvestPart></InvestPart>
                    <MarginBox mt={250}></MarginBox>
                    <NewEnjoyPart></NewEnjoyPart>
                    <MarginBox mt={250}></MarginBox>
                    <GrowthPart></GrowthPart>
                    <MarginBox mt={matches ? 200 : 300}></MarginBox>
                    <NewDimensionPart></NewDimensionPart>
                    <MarginBox mt={matches ? 150 : 200}></MarginBox>
                    <PartnerPart></PartnerPart>
                    <MarginBox mt={matches ? 150 : 200}></MarginBox>
                    <PressPart></PressPart>
                    <MarginBox mt={matches ? 150 : 200}></MarginBox>
                    <BannerPart></BannerPart>
                </MaxWidthWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;
const MarginBox = styled.div<{mt?:number}>`
    margin-top: ${props => props.mt ?? 0}px;
`;