import { axiosConfigInstance } from "./config";

export const USER_INFO = {
    "sound_count": 0,
    "feed_count": 0
}

export const PROFILE = {
    "user_id": 0,
    "user_address": "",
    "user_code": "",
    "user_nickname": "",
    "user_profile": "",
    "user_membership": "1",
    "user_country": "",
    "is_creator": false,
}

export const SOUND_ITEM = {
    "sound_id": 0,
    "album_id": 0,
    "user_id": 0,
    "title": "0",
    "url": "",
    "genre": "",
    "sound_category": "0",
    "sound_bpm": "0",
    "sound_duration": "0",
    "duration": 0,
    "sound_mood": "",
    "sound_timestamp": "0",
    "sound_visible": "0",
    "album_name": "",
    "artwork": "",
    "album_timestamp": "0",
    "artist": "",
    "user_profile": "",
    "user_country": ""
}

export const ALBUM_ITEM ={
    "album_id": 0,
    "album_name": "0",
    "sound_count": 0,
    "artwork": "",
    "album_timestamp": "0",
    "album_visible": "0",
    "user_id": 0,
    "artist": "",
    "user_profile": ""
}

export const VISIBLE_ITEM = {
    "target_id": 0,
    "non_visible_type": "4"
}

// 유저 feed, sound 수
export const apiGetUserInfo= async(token:string,user_id:number) =>{
    try {
        const res = await axiosConfigInstance('/account/profile',token).post('',{
            method:'getUserBasicInfo',
            params:{
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 유저 프로필
export const apiGetUserProfile = async(token:string,user_id:number,target_id:any) =>{
    try {
        const res = await axiosConfigInstance('/account/profile',token).post('',{
            method:'getUserProfileInfo',
            params:{
                user_id,
                target_user_id:target_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 닉네임 수정
export const apiSetUserNick = async(token:string,user_id:number,nick:string) =>{
    try {
        const res = await axiosConfigInstance('/account/profile',token).post('',{
            method:'setModifyUserNickName',
            params:{
                user_id,
                user_nickname:nick,
            }
        })

        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 프로필 이미지 수정
export const apiSetUserProfileImg = async(token:string,user_id:any,img:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','setModifyUserProfileImage');
        formData.append('params', JSON.stringify({
            user_id: user_id
        }));
        formData.append('user_profile',img);
        
        const res = await axiosConfigInstance(`/account/profile`,token,type).post('',formData);
        
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 유저가 업로드한 음원
export const apiGetUserSoundList = async(token:string,user_id:number,target_id:any,page:number) =>{
    try {
        const res = await axiosConfigInstance('/sound',token).post('',{
            method:'getUserSoundList',
            params:{
                user_id,
                target_user_id:target_id,
                page:page,
                count:10,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 유저가 올린 비트
export const apiGetUserBeatList = async(token:string,user_id:number,target_id:any,page:number) =>{
    try {
        const res = await axiosConfigInstance('/sound',token).post('',{
            method:'getUserBeatList',
            params:{
                user_id,
                target_user_id:target_id,
                page:page,
                count:10
            },
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 유저가 올린 앨범
export const apiGetUserAlbumList = async(token:string,user_id:number,target_id:any,page:number) =>{
    try {
        const res = await axiosConfigInstance('/sound/album',token).post('',{
            method:'getAlbumList',
            params:{
                user_id,
                target_user_id:target_id,
                page:page,
                count:10
            },
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 숨긴 유저 리스트
export const apiGetUserVisibleList = async(token:string,user_id:number) =>{
    try {
        const res = await axiosConfigInstance('/platform/visible',token).post('',{
            method:'getNonVisibleUserList',
            params:{
                user_id
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 유저 숨기기
// type 1: user / 2: sound / 3: album / 4: sns feed
export const apiSetUserVisibleOn = async(token:string,user_id:number,target_id:any,type:any) =>{
    try {
        const res = await axiosConfigInstance('/platform/visible',token).post('',{
            method:'nonVisibleUser',
            params:{
                user_id,
                target_id,
                non_visible_type:type,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 유저 숨기기 해제
// type 1: user / 2: sound / 3: album / 4: sns feed
export const apiSetUserVisibleOff = async(token:string,user_id:number,target_id:any,type:any) =>{
    try {
        const res = await axiosConfigInstance('/platform/visible',token).post('',{
            method:'nonVisibleUserDelete',
            params:{
                user_id,
                target_id,
                non_visible_type:type,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}