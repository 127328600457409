import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { themeColor } from './common/theme';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import session from "redux-persist/lib/storage/session";
import { globalLoadingState } from './common/globalLoading';
import { globalToast } from './common/globalToast';
import { apiInformation } from './api/apiInformation';
import {user} from './common/user';
import { globalDialog } from './common/globalDialog';
import { globalLang } from './common/globalLang';
import { globalAudio } from './common/globalAudio';
import { globalAudioState } from './common/globalAudioState';
import { globalAlert } from './common/globalAlert';
import { albumInfo } from './common/clickAlbum';
import { globalReport } from './common/globalReport';
import { globalLike } from './common/globalLike';
import { globalVisible } from './common/globalVisible';
import { globalConfirm } from './common/globalConfirm';
import { globalMusicPlay } from './common/globalMusicPlay';

const persistConfig = {
    key: "subox",
    // localStorage에 저장합니다.
    storage:storage,
    whitelist: ['globalLang','globalAudio','globalAudioState', 'globalAlert', 'albumInfo','globalReport','user','globalLike','globalVisible','globalConfirm'],
    blacklist: []
};

const rootReducer = combineReducers({
    themeColor,
    globalLoadingState,
    globalToast,
    apiInformation,
    user,
    globalDialog,
    globalLang,
    globalAudio,
    globalAudioState,
    globalAlert,
    albumInfo,
    globalReport,
    globalLike,
    globalVisible,
    globalConfirm,
    globalMusicPlay,
});

export type RootState = ReturnType<typeof rootReducer>;

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default function reducer() {
    return persistReducer(persistConfig, rootReducer);
}
