import React from 'react'
import styled from 'styled-components';
import { TextItem } from '../../../style/basic/commonStyle';
import { NEW_ALBUM_ITEM } from '../../../api/api_music';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAlbum } from '../../../store/common/clickAlbum';

interface musicAlbumItemType{
    data: typeof NEW_ALBUM_ITEM;
    clickEvent?:any;
}

export default function MusicAlbumItem({data,clickEvent}:musicAlbumItemType) {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    // const clickEvent = (data:any) =>{
    //     dispatch(setAlbum(data));
    //     navigate(`/searchPlayListDetail/${data.album_id}`);
    // }
    const itemClick = () =>{
        if(clickEvent !== undefined){
            clickEvent(data);
        }
    }
    
    return (
        <Wrap onClick={() => itemClick()}>
            <InnerWrap>
                <ImgWrap>
                    <img alt='album-artwork' src={data.artwork}></img>
                </ImgWrap>
                <TextWrap>
                    <TextItem fontSize={17} line={2}>{data.album_name}</TextItem>
                    <TextItem fontSize={14} marginTop={5}>{data.artist}</TextItem>
                </TextWrap>
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    padding: 10px;
`;

const InnerWrap = styled.div`
    cursor: pointer;
`;
const ImgWrap = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    &:after{
        content: '';
        display: block;
        padding-bottom: 100%;
    }
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 110%;
    }
`;

const TextWrap = styled.div`
    margin-top: 10px;
`;