import React,{useState} from 'react'
import styled from 'styled-components';
import { Center, TextItem } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import DropImgItem from '../../common/item/DropImgItem';
import {Button} from '@mui/material';

export default function UploadAlbumPart() {
    const [name,setName] = useState('');
    const [file,setFile] = useState<any>(null);
    const [img,setImg] = useState<any>('');

    const getFile = (e:any) =>{
        const files = e.target.files[0];
        if(files === undefined){
            setFile(null);
            return;
        }
        const imgUrl = URL.createObjectURL(files);
        
        setImg(imgUrl);
        setFile(files);
    }

    const albumCreate = (e:any) =>{
        e.preventDefault();
    }

    return (
        <Wrap>
            <form onSubmit={albumCreate}>
                <TextItem fontSize={18} marginTop={25}>앨범 이름</TextItem>
                <InputItem type={'text'} margin='15px 0 0 0' value={name} valueChange={setName}></InputItem>
                <TextItem fontSize={18} marginTop={25}>앨범 이미지</TextItem>
                <InputItem type={'file'} margin='15px 0 0 0' id='album-img' inputLabel='none' label={file === null ? '앨범 이미지 첨부하기':file.name} inputProps={{'accept':'.png,.jpeg'}} valueChange={getFile}></InputItem>
                {img !== '' &&
                    <>
                        <TextItem fontSize={18} marginTop={25}>앨범 미리보기</TextItem>
                        <Center>
                            <ImgWrap>
                                <img alt='album-img' src={img}></img>
                            </ImgWrap>
                        </Center>
                    </>
                }
                <Button type='submit' fullWidth variant='contained' sx={{backgroundColor:'#009764',marginTop:'30px','&:hover':{backgroundColor:'#018b5d'}}}>앨범 생성</Button>
            </form>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ImgWrap = styled.div`
    width: 128px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    &::after{
        content:'';
        padding-bottom: 128px;
        display: block;
    }
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 110%;
    }
`;