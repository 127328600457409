import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import SliderPart from '../../common/part/SliderPart';
import HomeAlbumItem from '../item/MusicAlbumItem';
import { PLAYLIST_ITEM } from '../../../api/api_music';
import MusicPlayListItem from '../item/MusicPlayListItem';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAlbum } from '../../../store/common/clickAlbum';

interface musicPlayListPartType{
    title:string;
    list:Array<typeof PLAYLIST_ITEM>;
    option?:any;
}

export default function MusicPlayListPart({title,list,option}:musicPlayListPartType) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dragging, setDragging] = useState(false);

    const handleBeforeChange = useCallback(() => {
        setDragging(true);
    }, [setDragging]);
    
    const handleAfterChange = useCallback(() => {
        setDragging(false);
    }, [setDragging]);

    const clickEvent = (data:any) =>{
        if(dragging) return;
        dispatch(setAlbum({...data,type:'playlist'}));
        navigate(`/searchPlayListDetail/${data.playlist_id}`);
    }

    return (
        <Wrap>
            <TextItem fontSize={24} mFontSize={18}>{title}</TextItem>
            <ContentWrap>
                <SliderPart data={{ autoplaySpeed:6500, infinite: list.length < 5 ? false : true,
                    slidesToShow:5,
                    slidesToScroll: 5,
                    responsive: [{  
                        breakpoint: 960, //화면 사이즈 960px일 때
                        settings: {
                            slidesToShow:3,
                            slidesToScroll: 3,
                        } 
                    },
                    { 
                        breakpoint: 768, //화면 사이즈 768px일 때
                        settings: {	
                            slidesToShow:2,
                            slidesToScroll: 2,
                        } 
                    }],
                    ...option,
                    beforeChange: handleBeforeChange,
                    afterChange: handleAfterChange,
                    }}
                >
                    {list[0].playlist_id !== 0 &&
                        list.map((data,index)=>{
                            return(
                                <MusicPlayListItem key={`new-playlist-${index}`} data={data} clickEvent={clickEvent}></MusicPlayListItem>
                            )
                        })
                    }
                </SliderPart>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    margin-top: 25px;
`