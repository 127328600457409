import React, { useEffect, useState } from 'react'
import { MUSIC_ITEM } from '../../../api/api_music'
import MusicItem from '../../search/item/MusicItem';
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';

interface musicListPartType {
    allList:Array<Array<typeof MUSIC_ITEM>>;
    total:number;
}

export default function MusicListPart({allList,total}:musicListPartType) {
    const [list,setList] = useState<any>([]);
    const [page,setPage] = useState(1);

    useEffect(()=>{
        if(allList[0][0].sound_id === 0) return;
        setList(allList[page-1])
    },[allList,page])

    const pageView = () =>{
        const item:any = [];
        for (let i = 0; i < total; i++) {
            item.push(
                <PageItem key={`page-${i}`} activeState={page === i+1} onClick={()=>pageClick(i+1)}></PageItem>
            );
        }
        return item;
    }

    const pageClick = (nowPage:number) =>{
        setPage(nowPage);
    }

    return (
        <div>
            <ListWrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <MusicItem key={`new-music-${index}`} data={data}></MusicItem>
                        )
                    })
                }
            </ListWrap>
            <PageWrap>
                {pageView()}
            </PageWrap>
        </div>
    )
}

const ListWrap = styled.div``;
const PageWrap = styled(Center)`
    margin-top: 30px;
`;
const PageItem = styled.div<{activeState:boolean}>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    opacity: ${props => props.activeState ? '0.75' : '0.25'};
    margin: 0 10px;
    cursor: pointer;
`;