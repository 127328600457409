import { axiosConfigInstance } from "./config";

// 검색 통합
export const apiGetSearchTotal = async(token:string,user_id:any,search:string) => {
    try {
        const res = await axiosConfigInstance('/search',token).post('',{
            method:'getSearchTotal',
            params:{
                user_id,
                search_query:search,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 검색 음원
export const apiGetSearchMusic= async(token:string,user_id:any,search:string,page:number) => {
    try {
        const res = await axiosConfigInstance('/search',token).post('',{
            method:'getSearchMusic',
            params:{
                user_id,
                search_query:search,
                page,
                count:8
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 검색 비트
export const apiGetSearchBeat= async(token:string,user_id:any,search:string,page:number) => {
    try {
        const res = await axiosConfigInstance('/search',token).post('',{
            method:'getSearchBeat',
            params:{
                user_id,
                search_query:search,
                page,
                count:8
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 검색 앨범
export const apiGetSearchAlbum= async(token:string,user_id:any,search:string,page:number) => {
    try {
        const res = await axiosConfigInstance('/search',token).post('',{
            method:'getSearchAlbum',
            params:{
                user_id,
                search_query:search,
                page,
                count:12
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 검색 플레이리스트
export const apiGetSearchPlaylist= async(token:string,user_id:any,search:string,page:number) => {
    try {
        const res = await axiosConfigInstance('/search',token).post('',{
            method:'getSearchPlaylist',
            params:{
                user_id,
                search_query:search,
                page,
                count:12
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}
