import React,{useEffect,useState} from 'react'
import styled from 'styled-components';
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, TextItem } from '../../../style/basic/commonStyle';
import { useNavigate, useParams } from 'react-router';
import { NOTICE_ITEM, apiGetNotice } from '../../../api/api_home';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';
import Head from '../../../routes/Head';

const DETAIL_DUMMY = {
    "notice_id": 2,
    "notice_type": 1,
    "notice_title": "새로운 트랜드 조각 투자, 그것이 궁금하다",
    "notice_content": "새로운 트랜드 조각 투자, 그것이 궁금하다",
    "notice_img": [
        "/notice/1690438681456st-2.png"
    ],
    "notice_time": 1690438681458,
    "notice_update_time": 0,
    "notice_delete": 0
}

export default function NoticeDetailPage() {
    const {id} = useParams();
    const {number} = useGlobalLang();
    // const [detail,setDetail] = useState(NOTICE_ITEM);
    const [detail,setDetail] = useState<any>([]);
    const navigate = useNavigate();
    
    useEffect(()=>{
        getDetail();
    },[number,id])

    const getDetail = async() =>{
        const res = await apiGetNotice(1,9);
        const krItem = res.data.filter((item:any)=>item.id === id);
        const enItem = res.data_en.filter((item:any)=>item.id === id);
        if(number === 0){
            setDetail(krItem);
        }
        if(number === 1){
            setDetail(enItem);
        }
        // const res = await apiGetNotice(1,1,id);
        // if(!res.result || res.data.length === 0){
        //     // setDetail(NOTICE_ITEM);
        //     setDetail(DETAIL_DUMMY);
        //     return;
        // }
        // setDetail(res.data[0]);
    }
    
    const goNoticePage = () =>{
        navigate('/notice/1');
    }

    return (
        <LayoutPage>
            <Head title='notice detail' />
            <MaxWidthWrap>
                {detail === undefined || detail.length === 0 ?
                <Center style={{minHeight:'300px'}}>
                    No items to display
                </Center> :
                <>
                    <TitleWrap>
                        <TextItem textAlign='center' fontSize={20} mFontSize={18}>{detail[0].title}</TextItem>
                        <TextItem fontSize={14} marginTop={10} textAlign='right'>{detail[0].date}</TextItem>
                    </TitleWrap>
                    <ContentWrap>
                        <ImgWrap>
                            <img alt='notice-img' src={detail[0].img}></img>
                        </ImgWrap>
                        <TextWrap>
                            <TextItem dangerouslySetInnerHTML={{__html:detail[0].contents}}></TextItem>
                        </TextWrap>
                        <Center>
                            <Btn onClick={goNoticePage}>목록</Btn>
                        </Center>
                    </ContentWrap>
                </>
                }
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    border-bottom: 2px solid #eee;
    padding: 15px 0;
    margin-top: 55px;
`;

const ContentWrap = styled.div``;
const ImgWrap = styled.div`
    /* width: 100%; */
    margin: 20px 0;
    text-align: center;
    & img{
        max-width: 100%;
    }
`;
const TextWrap = styled.div`
    margin-top: 15px;
`;

const Btn = styled.button`
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 6px 12px;
    margin-top: 20px;
`;