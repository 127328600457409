import styled, { css } from 'styled-components';
import { addTheme } from '../addTheme';

export const MaxWidthWrap = styled.div<{maxWidth?:string,margin?:string, padding?:string}>`
    width:100%;
    height: 100%;
    max-width: ${props=>props.maxWidth ?? addTheme.maxWidth};
    margin: ${props=>props.margin ?? '0 auto'};
    padding: ${props=>props.padding ?? 0};
    @media (max-width:1200px){
        padding: ${props=>props.padding ?? addTheme.padding};
    }
`;

export const TextItem = styled.div<{color?:string, fontSize?:number, mFontSize?:number, marginTop?:number, line?:number, fw?:number,textAlign?:'center'|'left'|'right',w?:string}>`
    width: ${props => props.w ?? '100%'};
    font-size: ${props => props.fontSize ?? 16}px;
    color: ${props => props.color ?? '#fff'};
    margin-top: ${props => props.marginTop ?? 0}px;
    font-weight: ${props => props.fw ?? 400};
    text-align: ${props => props.textAlign ?? 'left'};
    line-height: ${props=> props.fontSize === undefined ? 19 : props.fontSize+3}px;
    & .green{
        color: ${addTheme.color};
    }
    @media ${addTheme.media.m}{
        font-size: ${props=> props.mFontSize ?? (props.fontSize ?? 16)}px;
        line-height: ${props=>props.mFontSize === undefined ? props.fontSize === undefined ? 19 : props.fontSize+3 : props.mFontSize+3}px;
    }
    ${props => props.line !== undefined && css<{line?:number, fontSize?:number,mFontSize?:number}>`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${props => props.line ?? 1};
        -webkit-box-orient: vertical;
        height: ${props=> (props.line ?? 1) * (props.fontSize === undefined ? 20 : props.fontSize+4)}px;
        @media ${addTheme.media.m}{
            height: ${props=>(props.line ?? 1) * (props.mFontSize === undefined ? props.fontSize === undefined ? 20 : props.fontSize+4 : props.mFontSize+4)}px;
        }
    `}
`;

export const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const RowBetween = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const Left = styled.div`
    display: flex;
    align-items: left;
    justify-content: center;
`;

export const PaddingWrap = styled.div<{padding?: number}>`
    padding: ${props => props.padding ?? 0}px;
`;

export const FullWrap = styled.div<{padding?: number}>`
    width: 100%;
    height: 100%;
    padding: ${props => props.padding ?? 0}px;
`;

export const JustifyWrap = styled.div<{height?: string}>`
    justify-content: space-between;
    flex-direction: column;
    height: ${props => props.height ?? undefined};
`;

export const ItemDiv = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: ${props => props.center ? 'center' : 'flex-start'};
    align-items: center;
`;

export const ItemDivBetween = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export const IconImage = styled.img<{size?: number}>`
    width: ${props => props.size ?? 50}px;
    height: ${props => props.size ?? 50}px;
`;

export const WidthWrap = styled.div<{width?: number, align?: string}>`
    width: ${props => props.width}%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: ${props => props.align ?? 'center'};
    flex-direction: column;
`;