import React, {  useState,useEffect, useRef } from 'react'
import MusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import styled from 'styled-components';
import { useGlobalAudio } from '../../../utils/custom-hooks/common/useGlobalAudio';
import useAudioAction from '../../../utils/custom-hooks/useAudioAction';
import { apiSetMusicLog, apiSetMusicStart } from '../../../api/api_play';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setMusicPlay } from '../../../store/common/globalMusicPlay';

interface audioListItem {
    name: string;
    singer: string;
    cover: string;
    musicSrc: string;
}

const LOG1 = 30; // 30초
const LOG2 = 60; // 1분
const LOG_REWARD = 120; // 2분

export default function Player() {
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const user = useUserInfo();
    const [audioList,setAudioList] = useState<Array<audioListItem>>([]);
    const [instance,setInstance] = useState<any>(null);
    const [preId,setPreId] = useState(0);
    const audioAction = useAudioAction();
    
    const audio = useGlobalAudio();

    const time = useRef(0);
    const start_timestamp = useRef(0);
    const nowSound = useRef(0);
    const [playState,setPlayState] = useState(false);
    
    useEffect(()=>{
        // 재생한 시간 체크
        if(playState){
            dispatch(setMusicPlay({
                type:'player',
            }))
            const timer = setInterval(async()=>{
                if(time.current === 0){
                    start_timestamp.current = new Date().getTime();
                }
                
                time.current = time.current + (playState ? 0.5 : 0);
                if(time.current === LOG1){
                    const end_timestamp = new Date().getTime();
                    await musicLog(nowSound.current,start_timestamp.current,end_timestamp,2);
                }
                if(time.current === LOG2){
                    const end_timestamp = new Date().getTime();
                    await musicLog(nowSound.current,start_timestamp.current,end_timestamp,3);
                }
                if(time.current === LOG_REWARD){
                    const end_timestamp = new Date().getTime();
                    await musicLog(nowSound.current,start_timestamp.current,end_timestamp,1);
                }
            },500);
            return () => clearInterval(timer);
        }
    },[playState])

    useEffect(()=>{
        setAudioList(audio);
    },[audio]);

    // 삭제
    const listUpdate = (currentPlayIndex:any, audioLists:any, audioInfo:any) =>{
        if(audioLists.length < audioList.length){
            if(audioLists.length === 0){
                audioAction.del('all');
                // setAudioList([]);
                return;
            }
            audioAction.del(audioInfo.musicSrc);
        }
    }

    // 음악 시작
    const musicStart = async(sound_id:any,start_timestamp:any) =>{
        await apiSetMusicStart(user.jwt_token,user.user_id,sound_id,start_timestamp);
    }

    // 음악 로그
    const musicLog = async(sound_id:any,start_timestamp:any,end_timestamp:any,log_usage:any) =>{
        await apiSetMusicLog(user.jwt_token,user.user_id,sound_id,start_timestamp,end_timestamp,log_usage);
    }

    return (
        <>
            {pathname === '/welcome' ?
                <></> :
                <MusicPlayer
                    defaultPlayIndex={0}
                    autoPlay={false}
                    showDownload={false}
                    showThemeSwitch={false}
                    mode={'full'}
                    audioLists={audioList}
                    responsive={false}
                    toggleMode={false}
                    showReload={false}
                    quietUpdate={true}
                    clearPriorAudioLists={true}
                    drag={false}
                    showPlayMode={false}
                    onAudioListsChange={(currentPlayIndex, audioLists, audioInfo)=>{
                        listUpdate(currentPlayIndex, audioLists, audioInfo)
                    }}
                    onAudioPlay={async(audioInfo)=>{
                        setPlayState(true);
                        const soundId = audioInfo.sound_id;
                        setPreId(soundId);
                        nowSound.current = soundId;
                        if(soundId !== preId){
                            time.current = 0;
                            const start_timestamp = new Date().getTime();
                            await musicStart(soundId,start_timestamp);
                        }
                    }}
                    onAudioPause={(audioInfo)=>{
                        setPlayState(false);
                    }}
                    getAudioInstance={(inst)=>{
                        setInstance(inst);
                    }}
                    sortableOptions={{
                        swap: false,
                        sort:false,
                        disabled:true
                    }}
                />
            }
        </>
    )
}
