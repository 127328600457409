import React from 'react'
import styled from 'styled-components';
import { Center, Row, RowBetween, TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { GoPersonAdd } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import { setGlobalAlert } from '../../../store/common/globalAlert';
import { PROFILE } from '../../../api/api_user';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

interface userInfoPartType {
    profile: typeof PROFILE;
}

export default function UserInfoPart({profile}:userInfoPartType) {
    const user = useUserInfo();
    const dispatch = useDispatch();
    const notReady = () =>{
        dispatch(setGlobalAlert({
            open:true,
            title:'준비중입니다.',
        }))
    }
    
    return (
        <Wrap>
            <InnerWrap>
                <ImgWrap>
                    <ImgItem>
                        <img alt='user-profile' src={profile.user_profile === '' ? '/img/no.jpg' : profile.user_profile}></img>
                    </ImgItem>
                    <TextWrap>
                        <Row>
                            <FlagItem>
                                {(profile.user_country === 'ko' || profile.user_country === "KR") && <img alt='flag' src='/img/flag/ko.png'></img>}
                            </FlagItem>
                            {profile.is_creator &&
                                <TypeText>크리에이터</TypeText>
                            }
                        </Row>
                        <TextItem w='auto' marginTop={7} fw={700} fontSize={17}>{profile.user_nickname}</TextItem>
                    </TextWrap>
                </ImgWrap>
                <BtnWrap>
                    {user.user_id !== profile.user_id &&
                        <FollowBtn onClick={notReady}>
                            <FollowInner>
                                <GoPersonAdd size={20}></GoPersonAdd>
                                팔로우
                            </FollowInner>
                        </FollowBtn>
                    }
                    <CreatorBtn onClick={notReady}>크리에이터 프로필</CreatorBtn>
                </BtnWrap>
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 8px;
`;

const InnerWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;
const ImgWrap = styled.div`
    display: flex;
    align-items: center;
`;
const ImgItem = styled.div`
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 25px;
    & img{
        width: 100%;
        height: 100%;
    }
`;
const TextWrap = styled.div`
    margin-left: 20px;
`;
const FlagItem = styled.div`
    & img{
        width: 34px;
    }
    margin-right: 10px;
`;
const TypeText = styled.div`
    font-size: 12px;
    color: #ed9b21;
    background-color: rgba(223, 156, 29, 0.5);
    border: 1px solid #ed9b21;
    border-radius: 15px;
    padding: 4px 10px;
`;
const BtnWrap = styled.div`
    display: flex;
    &>div{
        margin-right: 20px;
    }
    @media ${addTheme.media.m}{
        width: 100%;
        justify-content: space-between;
        margin-top: 18px;
        &>div{
            margin-right: 0;
        }
    }
`;

const FollowBtn = styled.div`
    background-image: linear-gradient(to right, blue, purple);
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
`;
const FollowInner = styled.div`
    background-color: #111;
    border-radius: 4px;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
        margin-right: 8px;
    }
`;
const CreatorBtn = styled.button`
    color: ${addTheme.color};
    border: 1px solid ${addTheme.color};
    border-radius: 4px;
    width: 150px;
    height: 42px;
    background-color: rgba(66, 161, 66,0.4);
`;