import React from 'react'
import { Row, TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import styled from 'styled-components'
import NewEnjoyItem from '../item/NewEnjoyItem'
import { ENJOY_PART_TEXT } from '../../data/introData'
import TitleItem from '../../common/item/TitleItem'
import useLang from '../../../utils/custom-hooks/common/useLang'
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang'

export default function NewEnjoyPart() {
    const lang = useLang('intro_enjoy');
    const {number} = useGlobalLang();

    return (
        <div>
            <TitleItem align='center' text={lang[0]}></TitleItem>
            <ImgWrap>
                {ENJOY_PART_TEXT.map((data,index)=>{
                    return(
                        <ImgItem key={`enjoy-item-${index}`}>
                            <NewEnjoyItem title={data.title[number]} content={data.content[number]} img={data.img}></NewEnjoyItem>
                        </ImgItem>
                    )
                })}
            </ImgWrap>
        </div>
    )
}

const ImgWrap = styled.div`
    display: grid;
    margin-top: 120px;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media ${addTheme.media.m}{
        grid-template-columns: 1fr;
    }
`;

const ImgItem = styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #2f2f2f;
    border-radius: 25px;
    &::after{
        content:'';
        display: block;
        padding-bottom: 120%;
    }
    &>div{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
    }
`;


