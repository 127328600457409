import React from 'react'
import styled from 'styled-components'
import { Center, Row, TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme';

export default function NewWebTextItem({activeState,clickEvent,id,title1,title2,content1,content2,fontSize,mFontSize}:any) {
    const wrapClick = (id:number) =>{
        if(clickEvent === undefined) return;
        clickEvent(id)
    }
    
    return (
        <Wrap activeState={activeState} onClick={()=>wrapClick(id)} clickState={clickEvent}>
            <NumberWrap>
                <NumberItem activeState={activeState}>{id < 10 ? `0${id}` : id}</NumberItem>
                <TextItem fontSize={fontSize} mFontSize={mFontSize} fw={700} w='auto' dangerouslySetInnerHTML={{__html:title1}}></TextItem>
            </NumberWrap>
            <TextWrap>
                <TextItem fontSize={17} mFontSize={16} marginTop={15}>{content1}</TextItem>
                <TextItem fontSize={17} mFontSize={16} marginTop={5}>{content2}</TextItem>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{activeState:boolean,clickState?:any}>`
    padding: 25px 15px;
    background-color: ${props => props.activeState === undefined? 'transparent' : props.activeState ? addTheme.color_off : 'transparent'};
    border-radius: 12px;
    /* cursor: ${props => props.clickState === undefined ? 'default':'pointer'}; */
    transition: 0.3s ease-in-out;
`;

const NumberWrap = styled(Row)``;
const NumberItem = styled(Center)<{activeState:boolean}>`
    width: 32px;
    height: 32px;
    background-color: ${props=> props.activeState === undefined ? addTheme.color : props.activeState ? addTheme.color :addTheme.color_off};
    border-radius: 50%;
    color: #000;
    font-size: 16px;
    font-weight: 900;
    margin-right: 15px;
    transition: 0.3s ease-in-out;
    flex-shrink: 0;
`;
const TextWrap = styled.div`
    padding-left: 51px;
`;