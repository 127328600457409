export const NEW_PART_TEXT = [
    {id:1,title1:['슈박스 적립',`SUBOX Accumulation`], content1:[`내가 듣는 음악이 슈박스로 적립!`,`The music I listen to will be saved as SUBOX!`],content2:['활동할 수록 쌓이는 슈박스!',`Earn SUBOX for activities!`]},
    {id:2,title1:['포인트 전환',`Switch points`], content1:['내가 모은 슈박스를',`Easily and quickly convert`],content2:['포인트로 쉽고 빠르게 전환!',`SUBOX I collected into points!`]},
    {id:3,title1:['포인트 사용',`Use Points`], content1:['적립한 포인트는 쇼핑몰과',`Points earned can be used like cash`],content2:['다양한 제휴업체에서 현금처럼 사용 가능!',`at shopping malls and various affiliates!`]},
]

export const INVEST_PART_TEXT = [
    {id:1,title1:['투자한 음악에 내 소유권이 주어집니다.','My ownership is given to the music I invested in.'], content1:['음악별 전체 소유자의 소유권 비율만큼 표시됩니다.','The percentage ownership of all owners per song is displayed.'],content2:['음악의 창작활동은 AI크리에이터, 아토에게 맡겨주세요!','Leave the creative activities of music to AI creator, Ato!'],img:'/img/st-1.png'},
    {id:2,title1:['음악별 수익금이 발생 했을때<br>투자비율 만큼 배당금을 받을 수 있습니다.','As each song becomes profitable,<br>you will receive a dividend based on your investment ratio.'], content1:['일정 기간 후에 음악별 수익금이 발생했을때,','After a certain period of time, when each song is paid,'],content2:['투자비율 만큼 배당금을 받을 수 있습니다.','You will receive a dividend equal to the percentage you invested.'], img:'/img/st-2.png'},
]

export const ENJOY_PART_TEXT = [
    {id:1,title:['똑똑한 크리에이터들만의<br/><span>창작놀이터</span>','<span>A creative playground</span><br/>for smart creators'],content:['창작 음악 컨텐츠를 올리고 나의 음악을 알려보세요.<br/>블록체인의 투명성을 통해 합리적인 수익을 만들어갈 수 있어요!',`Post your creative music content and let me know your music.<br/>The transparency of the blockchain allows you to make a reasonable profit!`],img:'/img/enjoy/music-1.png'},
    {id:2,title:['온/오프라인 문제없이<br/><span>모든 음악</span>파일 재생','Play <span>any music</span> file online or offline</br>without any issues'],content:['언제 어디서든 음악을 감상하세요.<br/>온/오프라인에 있는 모든 음악을 플레이리스트로 만들 수 있어요.','Listen to music anytime, anywhere.<br/>You can make a playlist of all music online and offline.'],img:'/img/enjoy/music-2.png'},
    {id:3,title:['<span>세상의 모든</span><br/>음악/사운드 탐색','Explore all the music/sounds<br/><span>in the world</span>'],content:['다양한 장르의 ATO, 크리에이터들의 창작곡,<br/>K-Pop 아티스트들의 고화질 영상 등<br/>모든 장르의 음악을 탐색하고 재생하세요.','Various genres of ATO, creative songs by creators,<br/>High definition videos of K-Pop artists<br/>Explore and play all genres of music.'],img:'/img/enjoy/music-3.png'},
    {id:4,title:['일상을 공유하는<br/><span>나만의 스토리</span>','Share <span>your own stories</span><br>of everyday life'],content:['일상의 순간을 스토리로 공유하세요.<br/>음악과 함께하는 나만의 스토리도 남길 수 있어요.','Share your daily moments with stories.<br/>You can even leave your own story to accompany the music.'],img:'/img/enjoy/music-4.png'},
]

export const GROWTH_PART_TEXT = [
    {count_id:1,count_type:1,count_title_ko:'AI 크리에이터 ATO 창작곡',count_title_en:'AI Creator ATO Creative Song', count_content:5000, count_icon:'+',},
    {count_id:2,count_type:2,count_title_ko:'앱다운로드 수',count_title_en:'Number of app downloads', count_content:12000, count_icon:'+',},
    {count_id:3,count_type:3,count_title_ko:'등록된 창작 크리에이터',count_title_en:'Registered Creative Creators', count_content:1000, count_icon:'+',},
    {count_id:4,count_type:4,count_title_ko:'현재 슈박스 블록ID',count_title_en:'Current SUBOX Block ID', count_content:10751, count_icon:'',},
    {count_id:5,count_type:5,count_title_ko:'현재 슈박스 Tx Count',count_title_en:'Current SUBOX Tx Count', count_content:354269, count_icon:'+',},
    // {count_id:6,count_type:6,count_title_ko:'진행 중인 슈박스 조각 투자 음원',count_title_en:'Ongoing SUBOX Fragment Investment Music', count_content:10, count_icon:'',},
]