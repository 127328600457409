import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import WelcomeItem from '../item/WelcomeItem';

export default function WelcomeSlickPart({autoplaySpeed,mt,center,list}:any) {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: autoplaySpeed,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        arrow: false,
        centerMode: center,
    };
    return (
        <Wrap mt={mt}>
            <Slider {...settings}>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <WelcomeItem key={`welcome-${center}-${index}`} data={data}/>
                        )
                    })
                }
            </Slider>
        </Wrap>
    )
}

const Wrap = styled.div<{mt:any}>`
    & .slick-prev,
    & .slick-next {
        display: none !important;
    }
    margin-top: ${props=>props.mt ?? '0'}px;
`;
