import React from 'react'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderPart from '../../common/part/SliderPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

// const BANNER = [
//     {id:1,img:'/img/banner/banner1.jpg'},
//     {id:2,img:'/img/banner/banner2.jpg'},
//     {id:3,img:'/img/banner/banner3.jpg'},
//]

const DUMMY = [
    {
        "banner_id": 1,
        "banner_url": "https://music.dapi.subox.co.kr/ad_image/banner_4.png"
    },
    {
        "banner_id": 2,
        "banner_url": "https://music.dapi.subox.co.kr/ad_image/banner_2.jpg"
    },
    {
        "banner_id": 3,
        "banner_url": "https://music.dapi.subox.co.kr/ad_image/banner_3.jpg"
    }
]

export default function MusicBannerPart({list}:any) {
    return (
        <Wrap>
            <MaxWidthWrap>
                <SliderPart data={{autoplaySpeed:5500}}>
                    {list === undefined || list.length === 0 ? 
                        DUMMY.map((data,index)=>{
                            return(
                                <SliderItem key={`home-banner-${index}`}>
                                    <img alt='banner-d-img' src={data.banner_url} />
                                </SliderItem>
                            )
                        })
                        : list.map((data:any,index:any)=>{
                            return(
                                <SliderItem key={`home-banner-${index}`}>
                                    <PcImg alt='banner-p-img' src={data.banner_web_img} />
                                    <MoImg alt='banner-m-img' src={data.banner_mo_img} />
                                </SliderItem>
                            )
                        })
                    }
                </SliderPart>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    
`;
const SliderItem = styled.div`
    text-align: center;
    & img{
        margin: 0 auto;
        width: 100%;
    }
`;

const PcImg = styled.img`
    @media (${addTheme.media.s}){
        display: none !important;
    }
`;

const MoImg = styled.img`
    display: none !important;
    @media (${addTheme.media.s}){
        display: block !important;
    }
`;
