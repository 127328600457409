import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { TextItem } from '../../../style/basic/commonStyle';
import { useDispatch } from 'react-redux';
import { useGlobalConfirm } from '../../../utils/custom-hooks/common/useGlobalConfirm';
import { setGlobalConfirm } from '../../../store/common/globalConfirm';

export default function ConfirmPart() {
    const confirm = useGlobalConfirm();
    const dispatch = useDispatch();

    const close = () =>{
        dispatch(setGlobalConfirm({
            result:false,
        }));
    }

    const approval = () =>{
        dispatch(setGlobalConfirm({
            result:true,
        }));
    }

    return (
        <div>
            <Dialog open={confirm.open} sx={{'.MuiPaper-root':{width:'100%',maxWidth:'320px',backgroundColor:'#12101e'}}}>
                <DialogTitle sx={{color:'#fff'}}>{confirm.title}</DialogTitle>
                <DialogContent>
                   <TextItem dangerouslySetInnerHTML={{__html:confirm.content}}></TextItem>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>취소</Button>
                    <Button onClick={approval}>확인</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
