export const TEXT_DATA = [
    {
        id:'footer',
        ko:[
            `회사명`,`주소`,`사업자등록번호`,`대표`,`이메일`,`통신판매업신고번호`,`고객센터`,`회사소개`,`서비스 이용약관`,`개인정보처리방침`
        ],
        en:[
            `Corporate name`,`Address`,`Business registration number`,`Representative`,`E-mail`,`Mail order business report number`,`Customer Service`,`Company introduction`,'Terms and Conditions of Service','Personal information processing policy'
        ]
    },
    {
        // intro 0
        id:'intro_video',
        ko:[
            `내가 듣는 음악이<br/><span class='green'>매력적인 자산</span>이 되다`,
        ],
        en:[
            `The music I listen to<br>become an <span class='green'>attractive asset</span>`,
        ],
    },
    {
        // intro 1
        id:'intro_new',
        ko:[
            `새로운 차원의<br/>WEB3.0 앱테크`,
        ],
        en:[
            `A New Level of<br/>WEB3.0 AppTech`
        ],
    },
    {
        // intro 2
        id:'intro_every',
        ko:[
            `매일 신규 업로드되는`,
            `매력적인 테마별 플레이리스트<br/><span class='green'>매일 듣고 슈박스 모아요</span>`
        ],
        en:[
            `Newly uploaded daily`,
            `Listen to engaging, themed playlists <span class='green'>daily and collect SUBOX</span>`
        ]
    },
    {
        // intro 3
        id:'intro_invest',
        ko:[
            `음악,<br/>듣는 것을 넘어<br/><span class='green'>투자하고 소유까지</span>`,
            `슈박스가 제안하는 저작권 걱정없는<br/>새로운 차원의 WEB3.0 조각 투자`
        ],
        en:[
            `Music,<br/>from listening<br/><span class='green'>to investing to owning</span>`,
            `SUBOX's Proposed New Level of Web3.0<br/>Fragment Investment Without Copyright Worries`
        ]
    },
    {
        // intro 4
        id:'intro_enjoy',
        ko:[
           `음악 그 이상의 가치를 넘어<br/><span class='green'>새로운 즐거움의 시작</span>`,
        ],
        en:[
            `More than just music,<br/><span class='green'>it's the start of something new</span>`,
        ]
    },
    {
        // intro 5
        id:'intro_growth',
        ko:[
            `숫자로 증명하는</br><span class='green'>슈박스 성장력</span>`,
        ],
        en:[
            `<span class='green'>SUBOX Growth</span><br/>Proofs by Numbers`,
        ]
    },
    {
        // intro 6
        id:'intro_dimension',
        ko:[
            `새로운 차원을<br/>열어가는 <span class='green'>확장성</span>`
        ],
        en:[
            `It's a new dimension<br/>Open <span class='green'>scalability<span>`,
        ]
    },
    {
        // intro 
        id:'intro_youtube',
        ko:[
            `새로운 차원을<br/>열어가는 <span class='green'>확장성</span>`
        ],
        en:[
            `It's a new dimension<br/>Open <span class='green'>scalability<span>`,
        ]
    },
]
