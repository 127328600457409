import React,{useState,useEffect} from 'react'
import { Row, TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import styled from 'styled-components'

const LIST = [
    {id:1,img:'/img/partner/1.png'},
    {id:2,img:'/img/partner/2.png'},
    {id:3,img:'/img/partner/logo1.png'},
    {id:4,img:'/img/partner/5.png'},
    {id:5,img:'/img/partner/4.png'},
    {id:6,img:'/img/partner/logo6.png'},
    {id:7,img:'/img/partner/logo4.png'},
    {id:8,img:'/img/partner/logo8.png'},
    {id:9,img:'/img/partner/logo9.png'},
    {id:10,img:'/img/partner/logo10.png'},
    {id:11,img:'/img/partner/logo11.png'},
    {id:12,img:'/img/partner/logo12.png'},
    {id:13,img:'/img/partner/logo13.png'},
    {id:14,img:'/img/partner/logo14.png'},
    {id:15,img:'/img/partner/logo15.png'},
]

export default function PartnerPart() {

    return (
        <div>
            <TextItem textAlign='center' fontSize={addTheme.titleFontSize} mFontSize={addTheme.mTitleFontSize} fw={700}>Clients & Partners</TextItem>
            <ContentWrap>
                {LIST.map((data,index)=>{
                    return(
                        <ContentItem key={`partner-${index}`}>
                            <img alt='partner-logo' src={data.img}></img>
                        </ContentItem>
                    )
                })}
            </ContentWrap>
        </div>
    )
}

const ContentWrap = styled(Row)`
    margin-top: 80px;
    flex-wrap: wrap;
`;

const ContentItem = styled.div`
    width: 19%;
    margin: 20px 0.5%;
    & img{
        max-width: 100%;
    }

    @media ${addTheme.media.m}{
        width: 32.33%;
    }
`;