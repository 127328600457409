import React from 'react'
import styled from 'styled-components';
import { RowBetween, TextItem } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';

export default function NoticeItem({type,color,title,time,img,id}:any) {
    const navigate = useNavigate();

    const clickEvent = (id:any) =>{
        navigate(`/noticeDetail/${id}`);
        // window.open(id);
    }
    
    return (
        <ItemWrap onClick={()=>clickEvent(id)}>
            <ItemInnerWrap>
                <ItemContent>
                    <RowBetween>
                        <TextItem fontSize={18} mFontSize={15} w='auto'>{type}</TextItem>
                        <ItemColor color={color}></ItemColor>
                    </RowBetween>
                    <TextItem fw={700} fontSize={22} mFontSize={18} line={2}>{title}</TextItem>
                    <TextItem fontSize={14} mFontSize={13}>{time}</TextItem>
                </ItemContent>
                <ItemImg imgState={''} color={color} bgSrc={img}>
                    {/* {img !== '' &&
                        <img alt='press-img' src={img}></img>
                    } */}
                </ItemImg>
            </ItemInnerWrap>
        </ItemWrap>
    )
}

const ItemWrap = styled.div`
    background-color: #292929;
    width: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &::after{
        content:'';
        display: block;
        padding-bottom: 110%;
    }
`;


const ItemInnerWrap = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
`;

const ItemContent = styled.div`
    height: 55%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ItemImg = styled.div<{imgState:string,color:string,bgSrc:string}>`
    height: 45%;
    overflow: hidden;
    position: relative;
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }
    /* background-color: ${props => props.imgState === '' ? props.color : ''}; */
    background: url(${props=>props.bgSrc ?? ''});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const ItemColor = styled.div<{color:string}>`
    width: 10px;
    height: 10px;
    background-color: ${props => props.color ?? '#fff'};
    border-radius: 50%;
`;
