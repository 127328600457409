import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_REPORT= 'SUBOX_AUDIO/SET_REPORT';

export const globalReportCreator = createAction(SET_REPORT);

export function setGlobalReport(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalReportCreator>> {
    return async function (dispatch) {
    try {
            dispatch(globalReportCreator({
              data: data
            }));
    } catch (error) {
      
    }
  };
}

export interface AlertState {
  open:boolean;
  id:number;
  type:number;
  title:string;
}

const initialState: AlertState = {
  open:false,
  id:0,
  type:0,
  title:''
};

export const globalReport = handleActions<AlertState, any>({
  [SET_REPORT]: (state, action: ReturnType<typeof globalReportCreator>) => ({
    open: action.payload.data.open ?? false,
    id: action.payload.data.id ?? 0,
    type: action.payload.data.type ?? 0,
    title: action.payload.data.title ?? '',
  }),
}, initialState);
