import React from 'react'
import styled from 'styled-components'
import { MUSIC_ITEM, NEW_ALBUM_ITEM, PLAYLIST_ITEM } from '../../../api/api_music'
import MusicAlbumItem from '../../music/item/MusicAlbumItem';
import MusicNewAlbumPart from '../../music/part/MusicNewAlbumPart';
import MusicItem from '../item/MusicItem';
import { TextItem } from '../../../style/basic/commonStyle';
import MusicPlayListPart from '../../music/part/MusicPlayListPart';
import PaginationItem from '../../common/item/PaginationItem';
import NoItem from '../../common/item/NoItem';
import NoneItem from '../item/NoneItem';

interface searchResPartType {
    musicList: Array<typeof MUSIC_ITEM>;
    albumList: Array<typeof NEW_ALBUM_ITEM>;
    beatList: Array<typeof MUSIC_ITEM>;
    playList: Array<typeof PLAYLIST_ITEM>;
    page?:number;
    total?:number;
    setPage?:any;
}

export default function SearchResPart({albumList,beatList,musicList,playList,page,total,setPage}:searchResPartType) {
    
    return (
        <Wrap>
            {musicList.length === 0 ? <NoneItem title='음원'></NoneItem>
                : musicList[0].sound_id !== 0 &&
                    <InnerWrap>
                        <TextItem fontSize={24} mFontSize={20}>음원</TextItem>
                        {musicList.map((data,index)=>{
                            return(
                                <MusicItem key={`music-${index}`} data={data}></MusicItem>
                            )
                        })}
                    </InnerWrap>
            }
            {albumList.length === 0 ? <NoneItem title='앨범'></NoneItem>
                : albumList[0].album_id !== 0 &&
                    <InnerWrap>
                        <MusicNewAlbumPart title='앨범' list={albumList} option={{infinity:false,autoplay:false}}></MusicNewAlbumPart>
                    </InnerWrap>
            }
            {beatList.length === 0 ? <NoneItem title='비트'></NoneItem>
                : beatList[0].sound_id !== 0 &&
                    <InnerWrap>
                        <TextItem fontSize={24} mFontSize={20}>비트</TextItem>
                        {beatList.map((data,index)=>{
                            return(
                                <MusicItem key={`music-${index}`} data={data}></MusicItem>
                            )
                        })}
                    </InnerWrap>
            }
            {playList.length === 0 ? <NoneItem title='플레이리스트'></NoneItem>
                : playList[0].playlist_id !== 0 &&
                    <InnerWrap>
                        <MusicPlayListPart title='플레이리스트' list={playList} option={{infinity:false,autoplay:false}}></MusicPlayListPart>
                    </InnerWrap>
            }
            {page !== undefined && total !== 0 &&
                <PageWrap>
                    <PaginationItem page={page} total={total} setPage={setPage}></PaginationItem>
                </PageWrap>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;

const InnerWrap = styled.div`
    margin-top: 80px;
    &:first-of-type{
        margin-top: 35px;
    }
`;

const PageWrap = styled.div`
    margin-top: 20px;
`;