import React from 'react'
import styled from 'styled-components';
import { Center, Row, TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { INVEST_PART_TEXT } from '../../data/introData';
import InvestContentItem from '../item/InvestContentItem';
import useLang from '../../../utils/custom-hooks/common/useLang';
import TitleItem from '../../common/item/TitleItem';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

export default function InvestPart() {
    const lang = useLang('intro_invest');
    const {number} = useGlobalLang();

    return (
        <Wrap>
            <TitleItem text={lang[0]}></TitleItem>
            <TextItem fontSize={18} marginTop={20} dangerouslySetInnerHTML={{__html:lang[1]}}></TextItem>
            <ItemWrap>
                {INVEST_PART_TEXT.map((data,index)=>(
                    <InvestContentItem 
                        key={`invest-item-${index}`}
                        type={index === 0 ? 'left' : 'right'}
                        id={data.id}
                        title1={data.title1[number]}
                        content1={data.content1[number]}
                        content2={data.content2[number]}
                        fontSize={20}
                        mFontSize={18}
                        contentFs={16}
                        img={data.img}
                    ></InvestContentItem>
                ))}
            </ItemWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const ItemWrap = styled.div`
    margin-top: 30px;
`;


