import React,{useState,useEffect} from 'react'
import { Row, TextItem } from '../../../style/basic/commonStyle'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme';
import NoticeItem from '../item/NoticeItem';
import { apiGetNotice } from '../../../api/api_home';
import NoItem from '../../common/item/NoItem';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

export default function PressPart() {
    const {number} = useGlobalLang();
    const [list,setList] = useState<any>([]);
    const [list_kr,setList_kr] = useState<any>([]);
    const [list_en,setList_en] = useState<any>([]);

    useEffect(() =>{
        getList()
    },[])

    useEffect(()=>{
        if(number === 0){
            setList(list_kr);
        }
        if(number === 1){
            setList(list_en);
        }
    },[number,list_kr,list_en]);

    const getList = async() =>{
        const res = await apiGetNotice(1,9);
        if(res.result){
            const newList_kr:any = [];
            const newList_en:any = [];
            res.data.reverse().map((item:any,index:any)=>{
                if(index<4){
                    newList_kr.push(item);
                }
            })
            res.data_en.reverse().map((item:any,index:any)=>{
                if(index<4){
                    newList_en.push(item);
                }
            })

            setList_kr(newList_kr);
            setList_en(newList_en);
            return;
        }
        setList_kr([]);
        setList_en([]);
    }


    return (
        <div>
            <TextItem textAlign='center' fw={700} fontSize={addTheme.titleFontSize} mFontSize={addTheme.mTitleFontSize}>Press & Insight</TextItem>
            <ListWrap listChk={list.length === 0 || list[0].notice_id === 0}>
                {list.length === 0 ?
                    <NoItem></NoItem>
                    :
                    list.map((data:any,index:any)=>{
                        return(
                            <NoticeItem
                                key={`press-item-${index}`}
                                // id={data.notice_id}
                                id={data.id}
                                type={'NOTICE'}
                                title={data.title}
                                img={data.img}
                                time={data.date}
                                // time={momentFormat('yyyy.MM.DD',data.notice_time)}
                            ></NoticeItem>
                        )
                    })
                }
            </ListWrap>
        </div>
    )
}

const ListWrap = styled(Row)<{listChk:boolean}>`
    flex-wrap: wrap;
    margin-top: 80px;
    &>div{
        width: ${props=> props.listChk ? '100%' : '31.33%'};
        margin: 1%;
    }
    &>div:nth-last-of-type(1){
        display: none;
    }

    @media ${addTheme.media.m}{
        &>div{
            width: ${props => props.listChk ? '100%' : '48%'};
        }
        &>div:nth-last-of-type(1){
            display: block;
        }
    }
`;
