import React,{useState, useRef, useEffect} from 'react'
import { Center, TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import styled from 'styled-components'
import { GROWTH_PART_TEXT } from '../../data/introData'
import CountUp from 'react-countup'
import useLang from '../../../utils/custom-hooks/common/useLang'
import TitleItem from '../../common/item/TitleItem'
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang'
import { COUNT_ITEM } from '../../../api/api_home'

export default function GrowthPart() {
    const lang = useLang('intro_growth');
    const {number} = useGlobalLang();
    const [isVisible, setIsVisible] = useState(false);
    const targetRef = useRef<any>(null);
    const [list,setList] = useState([COUNT_ITEM]);

    useEffect(() => {
        const handleScroll = () => {
          if (targetRef.current) {
            const { top } = targetRef.current.getBoundingClientRect();
            setIsVisible(top <= window.innerHeight-100);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
    }
    
    return (
        <RefWrap ref={targetRef} >
            <TitleItem align='center' text={lang[0]}></TitleItem>
            <Wrap>
                {
                    GROWTH_PART_TEXT.map((data,index)=>{
                        return(
                            <ItemWrap key={`growth-item-${index}`}>
                                <Item>
                                    <div>
                                        <Center>
                                            <TextItem textAlign='center' fw={900} w='auto' fontSize={42} mFontSize={30} style={{letterSpacing:'5px'}}>
                                                <CountUp end={isVisible ? data.count_content : 0} duration={2} separator=','></CountUp>
                                            </TextItem>
                                            <TextItem textAlign='center' fw={900} w='auto' fontSize={42} mFontSize={30}>{data.count_icon}</TextItem>
                                        </Center>
                                        <TextItem textAlign='center' fontSize={13} mFontSize={12} marginTop={14}>{number === 0 ? data.count_title_ko : data.count_title_en}</TextItem>
                                    </div>
                                </Item>
                            </ItemWrap>
                        )
                    })
                }
                <ItemWrap>
                    <Item>
                        <img alt='logo_symbol' src='/img/logo_symbol.png'></img>
                    </Item>
                </ItemWrap>
            </Wrap>
        </RefWrap>
    )
}

const RefWrap = styled.div``;

const Wrap = styled.div`
    margin-top: 85px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    @media ${addTheme.media.m}{
        grid-template-columns: 1fr 1fr;
    }
    /* &>div{
        width: 48%;
        margin: 1%;
    } */
`;

const ItemWrap = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    &::after{
        content:'';
        padding-bottom: 42%;
        display: block;
    }
    &:nth-last-of-type(1)::after{
        content: none;
    }
    background-color: #626262;
    & img{
        width: 80%;
        max-width: 148px;
    }
    
`;

const Item = styled(Center)`
    width: 100%;
    height: 100%;
`;

