import React from 'react'
import styled from "styled-components";
import { TextItem } from "../../../style/basic/commonStyle";
import NoItem from "../../common/item/NoItem";

export default function NoneItem ({title}:any){
    return(
        <InnerWrap>
            <TextItem fontSize={24} mFontSize={20}>{title}</TextItem>
            <NoItem></NoItem>
        </InnerWrap>
    )
}

const InnerWrap = styled.div`
    margin-top: 80px;
    &:first-of-type{
        margin-top: 35px;
    }
`;
