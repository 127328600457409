import React from 'react'
import styled from 'styled-components'
import { TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import useLang from '../../../utils/custom-hooks/common/useLang'
import { footerPartType } from '../page/FooterPage'

const SNS = [
    {id:1,link:'https://www.youtube.com/@suboxmusic',name:'youtube',img:'/img/sns/sns_youtube.png'},
    {id:2,link:'https://www.instagram.com/subox.official/',name:'insta',img:'/img/sns/sns_instagram.png'},
    {id:3,link:'https://blog.naver.com/suboxm',name:'naver',img:'/img/sns/sns_naver.png'},
    {id:4,link:'https://t.me/suworld_official',name:'telegram',img:'/img/sns/sns_telegram.png'},
    {id:5,link:'https://twitter.com/SUWORLD2030META',name:'twitter',img:'/img/sns/sns_twitter.png'},
]

const SnsItemWrap = ({snsClick,link,img}:any) =>{
    
    return(
        <SnsItem onClick={()=>snsClick(link)}>
            <img alt='sns-img' src={img}></img>
        </SnsItem>
    )
}

export default function FooterRightPart({company,sns}:footerPartType) {
    const lang = useLang('footer');
    
    // sns 새창
    const snsClick = (link:string) =>{
        window.open(link);
    }

    return (
        <Wrap>
            {/* <TextItem fontSize={17} fw={700}>{lang[6]} {company.info_customer}</TextItem> */}
            <SnsWrap>
                {SNS.map((data,index)=>{
                        return(
                            <SnsItemWrap key={`sns-${index}`} snsClick={snsClick} img={data.img} link={data.link}></SnsItemWrap>
                        )
                    })}
            </SnsWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    &>div{
        text-align: right;
    }
    @media ${addTheme.media.m}{
        &>div:first-of-type{
            text-align: left;
            margin-top: 50px;
        }
    }
`;

const SnsWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    @media ${addTheme.media.m}{
        justify-content:start;
    }
`;

const SnsItem = styled.div`
    margin: 8px 0 0 8px;
    width: 30px;
    cursor: pointer;
    & img{
        width: 100%;
    }
    @media ${addTheme.media.m}{
        margin: 8px 8px 0 0;
    }
`;