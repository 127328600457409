import { axiosConfigInstance } from "./config";

export const REPORT_CATEGORY_ITEM = {
    "report_category_id": 0,
    "report_category_reason": "",
    "report_category_type": ""
}

// 신고하기 카테고리 가져오기
// report type 1: user / 2: sound / 3: album / 4: sns feed
export const apiGetReportCategory = async(token:string,user_id:number,report_type:any) =>{
    try {
        const res = await axiosConfigInstance('/report',token).post('',{
            method:'getReportCategoryList',
            params:{
                user_id,
                report_type
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 신고하기
// report action 1: user / 2: sound / 3: album / 4: sns feed
export const apiSetReport = async(token:string,user_id:number,target_id:any,report_category_id:any,report_action:any,) =>{
    try {
        const res = await axiosConfigInstance('/report',token).post('',{
            method:'reportTarget',
            params:{
                user_id,
                target_id,
                report_category_id,
                report_action
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}