import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const AUDIO_STATE_SET = 'SUBOX_AUDIO/STATE_SET';

export const setAudioSetState = createAction(AUDIO_STATE_SET);

export function setAudioState(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setAudioSetState>> {
    
    return async function (dispatch) {
        try {
                dispatch(setAudioSetState({
                    data: data
                }));
        } catch (error) {
        
        }
    };
}

export interface audioStateType {
    play:boolean;
}

const initialState:audioStateType = {
    play:false,
};


export const globalAudioState = handleActions<audioStateType | any>({
    [AUDIO_STATE_SET]: (state, action: ReturnType<typeof setAudioSetState>) => ({
        play: action.payload.data.text ?? state.play,
    }),
}, initialState);
