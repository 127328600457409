import React from 'react'
import HeaderPage from '../../Header/page/HeaderPage'
import styled from 'styled-components';
import FooterPage from '../../footer/page/FooterPage';
import { addTheme } from '../../../style/addTheme';
import { useLocation } from 'react-router';

export default function LayoutPage({children,padding}:any) {
    const location = useLocation();
    
    return (
        <Wrap>
            <HeaderWrap>
                <HeaderPage></HeaderPage>
            </HeaderWrap>
            <Content padding={padding} marginTopState={location?.pathname !== '/'}>
                {children}
            </Content>
            <FooterPage></FooterPage>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const HeaderWrap = styled.div`
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 999;
`;
const Content = styled.div<{padding?:any,marginTopState:boolean}>`
    padding: ${props => props.padding ?? '15px 0 75px'};
    margin-top: ${props => props.marginTopState ? 72 : 0}px;
`;
