import React from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import NewWebTextItem from './NewWebTextItem';

const TextItem = ({id,title1,content1,content2,fontSize,mFontSize}:any) =>{
    return(
        <TextWrap>
            <NewWebTextItem id={id} title1={title1} content1={content1} content2={content2} fontSize={fontSize} mFontSize={mFontSize}></NewWebTextItem>
        </TextWrap>
    )
}

export default function InvestContentItem({type,id,title1,title2,content1,content2,fontSize,mFontSize,img}:any) {
    return (
        <div>
            <Item>
                {type === 'left' ? 
                    <TextItem id={id} title1={title1} content1={content1} content2={content2} fontSize={fontSize} mFontSize={mFontSize}></TextItem> :
                    <ImgWrap>
                        <Img>
                            <img alt='invest-img' src={img}></img>
                        </Img>
                    </ImgWrap>
                }
                <SpaceWrap></SpaceWrap>
                {type === 'left' ? 
                    <ImgWrap>
                        <Img>
                            <img alt='invest-img' src={img}></img>
                        </Img>
                    </ImgWrap> :
                    <TextItem id={id} title1={title1} title2={title2} content1={content1} content2={content2} fontSize={fontSize} mFontSize={mFontSize}></TextItem>
                }

            </Item>
        </div>
    )
}

const Item = styled(Row)`
    flex-wrap: wrap;
    margin-top: 90px;
    @media ${addTheme.media.m}{
        margin-top: 50px;
        &>div{
            width: 100%;
        }
    }
`;

const SpaceWrap = styled.div`
    width: 5%;
    @media ${addTheme.media.m}{
        display: none;
    }
`
const TextWrap = styled(Center)`
    width: 45%;
    justify-content: start;
    @media ${addTheme.media.m}{
        order: 1;
    }
`;

const ImgWrap = styled.div`
    width: 50%;
    @media ${addTheme.media.m}{
        order: 2;
    }
`;

const Img = styled.div`
    width: 100%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    &::after{
        content:'';
        padding-bottom: 55%;
        display: block;
    }
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 110%;
    }
`;