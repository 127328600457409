import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import { useNavigate } from 'react-router'
import useLang from '../../../utils/custom-hooks/common/useLang'
import { footerPartType } from '../page/FooterPage'


const InfoItem = ({title,content}:any) =>{
    return(
        <TextItem fontSize={14} w='auto'><span style={{fontWeight:900}}>{title} </span> {content}</TextItem>
    )
}

export default function FooterLeftPart({company}:footerPartType) {
    const lang = useLang('footer');

    const navigate = useNavigate();
    const termsAction = (id:any) =>{
        navigate(`/terms/${id}`);
    }
    
    return (
        <Wrap>
            <MenuWrap>
                <MenuItem onClick={()=>navigate('/')}>
                    <TextItem fw={700} fontSize={17} mFontSize={15}>{lang[7]}</TextItem>
                </MenuItem>
                <MenuItem onClick={()=>termsAction(0)}>
                    <TextItem fw={700} fontSize={17} mFontSize={15}>{lang[8]}</TextItem>
                </MenuItem>
                <MenuItem onClick={()=>termsAction(1)}>
                    <TextItem fw={700} fontSize={17} mFontSize={15} color='#00ff00'>{lang[9]}</TextItem>
                </MenuItem>
            </MenuWrap>
            <InfoWrap>
                <div>
                    <InfoItem title={lang[0]} content={company.info_name}></InfoItem>
                    <InfoItem title={lang[1]} content={company.info_addr}></InfoItem>
                </div>
                <div>
                    <InfoItem title={lang[2]} content={company.info_num}></InfoItem>
                    <InfoItem title={lang[3]} content={company.info_cap}></InfoItem>
                    <InfoItem title={lang[4]} content={company.info_phone}></InfoItem>
                </div>
                <div>
                    <InfoItem title={lang[5]} content={company.info_bNum}></InfoItem>
                </div>
            </InfoWrap>
           
        </Wrap>
    )
}

const Wrap = styled.div`

`;

const MenuWrap = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
`;

const MenuItem = styled.div`
    /* margin-right: 15px; */
    cursor: pointer;
`;

const InfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    &>div{
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;
    }
    &>div>div{
        margin-right: 10px;
    }
    @media ${addTheme.media.m}{
        &>div>div{
            margin-top: 6px;
        }
    }
`;

{/* <div>
                    <InfoItem title={lang[0]} content={company.info_name}></InfoItem>
                    <InfoItem title={lang[1]} content={company.info_addr}></InfoItem>
                </div>
                <div>
                    <InfoItem title={lang[2]} content={company.info_num}></InfoItem>
                    <InfoItem title={lang[3]} content={company.info_cap}></InfoItem>
                    <InfoItem title={lang[4]} content={company.info_phone}></InfoItem>
                </div>
                <div>
                    <InfoItem title={lang[5]} content={company.info_bNum}></InfoItem>
                </div> */}