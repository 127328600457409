import axios from "axios";
import { axiosConfigInstance } from "./config";

// 나라
const getCountry = async() =>{
    try {
        const {data} = await axios.get('https://ipapi.co/json/');
        return data.country_code
    } catch (error) {
        return '';
    }
}

// 로그인
export const apiLogin = async() =>{
    try {
        const country = await getCountry();
        const res = await axiosConfigInstance('/account').post('',{
            method:'firstAccessAccount',
            params:{
                user_device: 3,
                user_token: 'web_platform',
                user_country: country,
                user_timestamp: new Date().getTime()
            }
        })
        return {...res.data,user_country: country};
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 로그
export const apiSetLog = async(token:string,user_id:any,user_country:any) =>{
    try {
        const res = await axiosConfigInstance('/account',token).post('',{
            method: 'setLoginLog',
            params: {
                user_id,user_country,user_device:'3'
            }
        })
        
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}