import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween, TextItem } from '../../../style/basic/commonStyle';
import { useNavigate, useParams } from 'react-router';
import WaveItem from '../item/WaveItem';
import { addTheme } from '../../../style/addTheme';
import MusicGenreItem from '../item/MusicGenreItem';
import MusicOtherItem from '../item/MusicOtherItem';
import { MUSIC_INFO } from '../../../api/api_music';

interface searchInfoPartType {
    info: typeof MUSIC_INFO;
    similar: Array<typeof MUSIC_INFO>;
}

export default function SearchInfoPart({info,similar}:searchInfoPartType) {
    // const duVal = useRef('0');
    const [duVal,setDuVal] = useState('0');
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <InfoWrap>
                        <Row>
                            <ImgItem>
                                <img alt='album-img' src={info.artwork}></img>
                            </ImgItem>
                            <div>
                                <TextItem fontSize={28} fw={700} mFontSize={20}>{info.title}</TextItem>
                                <TextItem marginTop={10} fontSize={22} mFontSize={16}>{info.artist}</TextItem>
                            </div>
                        </Row>
                        <div style={{margin:'34px 0 44px'}}>
                            <WaveItem url={info.url} mShow={true} duVal={duVal} setDuVal={setDuVal}></WaveItem>
                        </div>
                        <Row>
                            <InfoItem>
                                <TextItem fontSize={14}>길이</TextItem>
                                <TextItem fontSize={20} marginTop={12}>{info.sound_duration}</TextItem>
                            </InfoItem>
                            <InfoItem>
                                <TextItem fontSize={14}>BPM</TextItem>
                                <TextItem fontSize={20} marginTop={12}>{info.sound_bpm}</TextItem>
                            </InfoItem>
                        </Row>
                    </InfoWrap>
                    <TextWrap>
                        <MusicGenreItem title='음악 장르' list={info.genre}></MusicGenreItem>
                        <MusicGenreItem title='분위기' list={info.sound_mood}></MusicGenreItem>
                        {/* <MusicGenreItem title='악기' list={[{id:32,name:'드럼'},{id:33,name:'일렉기타'}]}></MusicGenreItem>
                        <MusicGenreItem title='장면/테마' list={[{id:42,name:'음식'}]}></MusicGenreItem> */}
                    </TextWrap>
                    <OtherWrap>
                        <TextItem>유사곡</TextItem>
                        {similar[0].sound_id !== 0 &&
                            similar.map((data,index)=>{
                                return(
                                    <MusicOtherItem key={`similar-item-${index}`} data={data}></MusicOtherItem>
                                )
                            })
                        }
                    </OtherWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background: url('/img/tech/item_4.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    padding: 80px 0;
    border-bottom: 2px solid #eee;
`;

const InnerWrap = styled(Row)`
    background-color: #212121;
    box-shadow: 0px 8px 100px -20px rgba(0,0,0,0.6);
    padding: 30px;
    border-radius: 5px;
    overflow: hidden;
    align-items: stretch;
    @media ${addTheme.media.m}{
        flex-direction: column;
        &>div{
            width: 100%;
            margin-top: 25px;
        }
    }
`;

const InfoWrap = styled.div`
    width: 35%;
    margin-right: 5%;
`;

const ImgItem = styled.div`
    position: relative;
    width: 125px;
    height: 125px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
    & img{
        height: 110%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    @media ${addTheme.media.m}{
        width: 80px;
        height: 80px;
    }
`;

const InfoItem = styled(Center)`
    flex-direction: column;
    width: 20%;
    &>div{
        text-align: center;
    }
`;

const TextWrap = styled.div`
    width: 27%;
    margin-right: 6%;
    display: flex;
    flex-direction: column;
    &>div{
        margin-top: 30px;
    }
    &>div:first-of-type{
        margin-top: 0;
    }
`;

const OtherWrap = styled.div`
    width: 27%;
`;