import moment from 'moment';

export const displayedAt = (datee: any) => {
  const current: any = new Date();
  const milliSeconds = Math.abs(current - datee);
  const seconds = milliSeconds / 1000;
  if (seconds < 60) return `${Math.floor(seconds)}초 전`;

  const minutes = seconds / 60;
  if (minutes < 60) return `${Math.floor(minutes)}분 전`;

  const hours = minutes / 60;
  if (hours < 24) return `${Math.floor(hours)}시 전`;

  const days = hours / 24;
  if (days < 7) return `${Math.floor(days)}일 전`;

  const weeks = days / 7;
  if (weeks < 5) return `${Math.floor(weeks)}주 전`;

  const months = days / 30;
  if (months < 12) return `${Math.floor(months)}달 전`;

  const years = days / 365;
  return `${Math.floor(years)}년 전`;
};

export function momentFormat(format: string, timestamp?: number): string {
  if (timestamp) {
    return moment(timestamp).format(format);

  } else {
    return '-';
  }
}

export function momentFormatUTC(format: string, timestamp?: number): string {
    if (timestamp) {
      return moment(timestamp).utc().format(format);
    } else {
      return '-';
    }
  }

export function dateFormatUTCTimeZone(timestamp?: any) {
  return {
    day: momentFormatUTC('DD/MM/YYYY', timestamp),
    time: momentFormatUTC('HH:mm:ss', timestamp)
  }
}

export function dateFormatDayColons(timestamp?: any) {
  return momentFormatUTC('DD/MM/YYYY', timestamp);
}

export function dateFormatTimeZoneColons(timestamp?: any) {
  return momentFormatUTC('HH:mm:ss', timestamp);
}

export function dateFormatMonthDay(timestamp?: number) {
  return momentFormat('MM/DD', timestamp);
}

export function dateFormatChat(timestamp?: number) {
  return momentFormat('hh:mm A', timestamp);
}

export function dateFormatMinute(timestamp?: number) {
  return momentFormat('YYYY/MM/DD HH:mm', timestamp);
}

export function dateFormatSecond(timestamp: number) {
  return momentFormat('YYYY/MM/DD HH:mm:ss', timestamp);
}

export function CountDownTimer(dt: any)
{
var end = dt;

var _second = 1000;
var _minute = _second * 60;
var _hour = _minute * 60;
var _day = _hour * 24;
var now = new Date().getTime();
var distance = end - now;
  if (distance < 0) {
    return '만료';
  }
  var days = Math.floor(distance / _day);
  var hours = Math.floor((distance % _day) / _hour) < 10 ? `0${Math.floor((distance % _day) / _hour)}` : `${Math.floor((distance % _day) / _hour)}`;
  var minutes = Math.floor((distance % _hour) / _minute) < 10 ? `0${Math.floor((distance % _hour) / _minute)}` : `${Math.floor((distance % _hour) / _minute)}`;
  var seconds = Math.floor((distance % _minute) / _second) < 10 ? `0${Math.floor((distance % _minute) / _second)}` : `${Math.floor((distance % _minute) / _second)}`;

  return `${days}일 ${hours}:${minutes}:${seconds}`;
}

export const durationFormat = (sec:any) =>{
  const minutes = Math.floor(sec / 60);
  const remainingSeconds = Math.floor(sec % 60);
  const formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  return formattedTime;
}