import React,{useEffect,useState} from 'react'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import useLang from '../../../utils/custom-hooks/common/useLang';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

export default function IntroVideoPart() {
    const lang = useLang('intro_video');
    const {number} = useGlobalLang();
    const [videoUrl,setVideoUrl] = useState('');

    useEffect(()=>{
        setVideoUrl(number === 0 ? '/v/subox_main_kr.mp4' : '/v/subox_main_en.mp4');
    },[number])

    return (
        <Wrap>
            <video key={`video-${videoUrl}`} muted autoPlay loop playsInline={true}>
                <source src={videoUrl}></source>
            </video>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    & video{
        width: 100%;
        margin-top: -3%;
        @media ${addTheme.media.m}{
            margin-top: 0;
        }
    }
`;