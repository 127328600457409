import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import SliderPart from '../../common/part/SliderPart';
import MusicAlbumItem from '../item/MusicAlbumItem';
import { NEW_ALBUM_ITEM } from '../../../api/api_music';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAlbum } from '../../../store/common/clickAlbum';

interface musicPlayListPartType{
    title?:string;
    list:Array<typeof NEW_ALBUM_ITEM>
    option?:any;
}

export default function MusicNewAlbumPart({title,list,option}:musicPlayListPartType) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dragging, setDragging] = useState(false);

    const handleBeforeChange = useCallback(() => {
        setDragging(true);
    }, [setDragging]);
    
    const handleAfterChange = useCallback(() => {
        setDragging(false);
    }, [setDragging]);

    const clickEvent = (data:any) =>{
        if(dragging) return;
        dispatch(setAlbum({...data,type:'album'}));
        navigate(`/searchAlbumDetail/${data.album_id}`);
    }

    return (
        <Wrap>
            {title !== undefined &&
                <TextItem fontSize={24} mFontSize={18}>{title}</TextItem>
            }
            <ContentWrap>
                <SliderPart data={{ autoplaySpeed:6500, infinite: list.length < 5 ? false : true,
                    slidesToShow:5,
                    slidesToScroll: 5,
                    responsive: [{  
                        breakpoint: 960, //화면 사이즈 960px일 때
                        settings: {
                            slidesToShow:3,
                            slidesToScroll: 3,
                        } 
                    },
                    { 
                        breakpoint: 768, //화면 사이즈 768px일 때
                        settings: {	
                            slidesToShow:2,
                            slidesToScroll: 2,
                        } 
                    }],
                    ...option,
                    beforeChange: handleBeforeChange,
                    afterChange: handleAfterChange,
                    }}
                >
                    {list[0].album_id !== 0 &&
                        list.map((data,index)=>{
                            return(
                                <MusicAlbumItem key={`new-album-${index}`} data={data} clickEvent={clickEvent}></MusicAlbumItem>
                            )
                        })
                    }
                </SliderPart>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    margin-top: 25px;
    & .slick-dots{bottom: -40px;}
`