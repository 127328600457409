import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const AUDIO_MUSIC_PLAY = 'SUBOX_AUDIO/AUDIO_MUSIC_PLAY';

export const setMusicPlayCreator = createAction(AUDIO_MUSIC_PLAY);

export function setMusicPlay(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setMusicPlayCreator>> {
    
    return async function (dispatch) {
        try {
            dispatch(setMusicPlayCreator({
                data: data
            }));
        } catch (error) {
        
        }
    };
}

export interface audioType {
    url:string;
    type: string;
}

const initialState:audioType = {
    url: '',
    type: '',
};


export const globalMusicPlay = handleActions<audioType | any>({
    [AUDIO_MUSIC_PLAY]: (state, action: ReturnType<typeof setMusicPlayCreator>) => ({
        url: action.payload.data.url ?? state.url,
        type: action.payload.data.type ?? '',
    }),
  }, initialState);
