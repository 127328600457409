import React,{useState,useCallback} from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

export default function EveryDaySliderPart({list,sliderRef}:any) {
    const [dragging, setDragging] = useState(false);
    
    const handleBeforeChange = useCallback(() => {
        setDragging(true);
    }, [setDragging]);
    
    const handleAfterChange = useCallback(() => {
        setDragging(false);
    }, [setDragging]);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 5,
        infinity: true,
        speed:1500,
        autoplaySpeed:5000,
        autoplay:true,
        // pauseOnHover : true,
        arrows:false,
        dot:false,
        responsive: [{  
            breakpoint: 960,
            settings: {
                slidesToShow:1,
            } 
        }],
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
    };

    const clickEvent = () =>{
        if(dragging) return;
    }

    return (
        <div>
            <Slider ref={sliderRef} {...settings}>
                {list.map((data:any,index:any)=>(
                    <Item key={`every-item-${index}`} onClick={clickEvent}>
                        <ImgWrap>
                            <img alt='album-img' src={data.playList_img}></img>
                        </ImgWrap>
                        <TextItem textAlign='center' marginTop={10}>{data.playList_title}</TextItem>
                        <TextItem textAlign='center' marginTop={5} fontSize={14} color='#c6c6c6'>{data.playList_genre}</TextItem>
                    </Item>
                ))}
            </Slider>
        </div>
    )
}

const Item = styled.div`
    padding: 20px;
    @media ${addTheme.media.m}{
        padding: 30px;
    }
`;
const ImgWrap = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    &::after{
        content:'';
        padding-bottom:100%;
        display: block;
    }
    & img{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
    }
`;