import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import styled from 'styled-components';

export default function MusicGenreItem({title,list}:any) {
    const [newList,setNewList] = useState(['']);
    const navigate = useNavigate();
    const infoClick = (id:any) =>{
        // navigate(`/?ca=${id}`)
    }

    useEffect(()=>{
        if(list === '') return;
        const arr = list.split(',');
        setNewList(arr);
    },[list])

    return(
        <div>
            <TextItem>{title}</TextItem>
            <Row style={{flexWrap:'wrap'}}>
                {newList.length !== 0 && newList.map((data:any,index:any)=>{
                    return <MusicTextItem key={`info-${index}`}>{data}</MusicTextItem>
                })}
            </Row>
        </div>
    )
}

const MusicTextItem = styled.div`
    background-color: #000;
    color: #fff;
    padding: 5px;
    margin: 2px;
    font-size: 14px;
    &:nth-of-type(1){
        margin: 8px 8px 8px 0;
    }
`;