import React,{useState} from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import styled from 'styled-components'
import { MaxWidthWrap, Row, TextItem, } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import UploadGuidePart from '../part/UploadGuidePart';
import UploadPart from '../part/UploadPart';
import {Box,Tabs,Tab} from '@mui/material';
import UploadAlbumPart from '../part/UploadAlbumPart';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
export default function UploadPage() {
    const [value,setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    return (
        <LayoutPage>
            <MaxWidthWrap>
                <Wrap>
                    <Left>
                        <UploadGuidePart></UploadGuidePart>
                    </Left>
                    <Right>
                        <Tabs value={value} onChange={handleChange} aria-label="basic">
                            <Tab sx={{color:'#fff'}} label="음원 업로드" {...a11yProps(0)} />
                            <Tab sx={{color:'#fff'}} label="앨범 생성" {...a11yProps(1)} />
                        </Tabs>
                        <CustomTabPanel value={value} index={0}>
                            <UploadPart></UploadPart>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <UploadAlbumPart></UploadAlbumPart>
                        </CustomTabPanel>
                    </Right>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled(Row)`
    margin-top: 35px;
    flex-wrap: wrap;
    align-items: start;
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
            margin-right:0;
        }
    }
`;
const Left = styled.div`
    width: 40%;
    margin-right: 10%;
`;
const Right = styled.div`
    width: 50%;
    & .Mui-selected{
        color: #00ff9c !important;
    }
    & .MuiTabs-indicator{
        background-color: #00ff9c !important;
    }
    @media ${addTheme.media.m}{
        margin-top: 50px;
    }
`;