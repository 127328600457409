import React, { useEffect, useState } from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import { MaxWidthWrap } from '../../../style/basic/commonStyle'
import { Tab, Tabs } from '@mui/material';
import { TabPanelPart, a11yProps } from '../../common/part/TabPanelPart';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { TERMS_ITEM, apiGetTermsList } from '../../../api/api_terms';
import Head from '../../../routes/Head';

const TERMS = {
    service:[
        `서비스 이용약관`
    ],
    info:[
        `개인정보처리방침`
    ]
}

export default function TermsPage() {
    const user = useUserInfo();
    const {id} = useParams();
    const [value,setValue] = useState(0);
    const [terms,setTerms] = useState([TERMS_ITEM]);
    const navigate = useNavigate();

    useEffect(()=>{
        if(id === null || id === undefined) return;
        setValue(Number(id));
    },[id])

    const handleChange = (e:any, newValue: number) =>{
        navigate(`/terms/${newValue}`);
    }

    useEffect(()=>{
        getTerms();
    },[]);

    const getTerms = async() =>{
        const res = await apiGetTermsList(user.jwt_token,user.user_id);
        if(res.result){
            setTerms(res.data);
            return;
        }
        setTerms([TERMS_ITEM]);
    }

    const getContent = (type:string) =>{
        const item = terms.filter(data=>data.terms_type === type);
        if(item.length === 0){
            return '';
        }else{
            return item[0].terms_content;
        }
    }

    return (
        <LayoutPage>
            <Head title='terms' />
            <MaxWidthWrap>
                <Wrap>
                    <Tabs value={value} onChange={handleChange} aria-label="basic">
                        <Tab sx={{color:'#fff'}} label="서비스 이용약관" {...a11yProps(0)} />
                        <Tab sx={{color:'#fff'}} label="개인정보처리방침" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanelPart value={value} index={0}>
                        <Item>{getContent('2')}</Item>
                    </TabPanelPart>
                    <TabPanelPart value={value} index={1}>
                        <Item>{getContent('1')}</Item>
                    </TabPanelPart>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    & .Mui-selected{
        color: ${addTheme.color} !important;
    }
    & .MuiTabs-indicator{
        background-color: ${addTheme.color} !important;
    }
`;

const Item = styled.pre`
    margin-top: 40px;
    white-space: pre-wrap;
    line-height: 1.3;
`;