import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';
import { VISIBLE_ITEM } from '../../api/api_user';

const SET_VISIBLE = 'suworld/common/SET_VISIBLE';

export const globalVisibleCreator = createAction(SET_VISIBLE);

export function setGlobalVisible(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalVisibleCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalVisibleCreator({
        data: data.list
      }));
    } catch (error) {
      
    }
  };
}

export interface like {
  list:Array<typeof VISIBLE_ITEM>;
}

const initialState: like = {
  list:[]
};

export const globalVisible = handleActions<like, any>({
  [SET_VISIBLE]: (state, action: ReturnType<typeof globalVisibleCreator>) => ({
    list: action.payload.data ?? [],
  }),
}, initialState);
