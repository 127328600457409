import { axiosConfigInstance } from "./config";

/*
[sns_type]
1: only text feed / 2: image feed / 3: video feeed / 4: audio feed / 5: youtube feed

[sns_category]
1: basic_feed(all) / 2: outer_feed(k-pop,pop) (ex. youtube)

[sns_small_category]
1: basic feed / 2: kpop / 3: pop

[sns_visible]
0: non_visible / 1: all_visible / 2: follow_visible / 3: 심사중
*/

export const STORY_ITEM = {
    "sns_id": 0,
    "user_id": 12577,
    "sns_type": "1",
    "sns_timestamp": "1691371611249",
    "sns_youtube_publish_timestamp": "1691371611249",
    "sns_title": "",
    "sns_description": "ㅅㄷㄴㅈㅋㄷ",
    "sns_content_url": "",
    "sns_youtube_video_id": "",
    "tag": [],
    "user_nickname": "준쨩",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png",
    "like_cnt": 0,
    "reply_cnt": 0,
    "donate_amount": "0",
    "images": []
}

export const STORY_POP_ITEM = {
    "sns_id": 0,
    "user_id": 0,
    "sns_type": "5",
    "sns_timestamp": "1689664074173",
    "sns_youtube_publish_timestamp": "1688786679000",
    "sns_title": "NMIXX 'Roller Coaster' M/V",
    "sns_description": "NMIXX 'Roller Coaster' M/V\n\nNMIXX 'A Midsummer NMIXX's Dream' Pre-order & Pre-save ▶ https://nmixx.lnk.to/AMidsummerNMIXXDream\n\nNMIXX Official YouTube ▶ https://www.youtube.com/c/NMIXXOfficial\nNMIXX Official Twitter ▶ https://twitter.com/NMIXX_official\nNMIXX Official Instagram ▶ https://www.instagram.com/nmixx_official/\nNMIXX Official Facebook ▶ https://www.facebook.com/NMIXXOfficial/\nNMIXX Official Weibo ▶ https://weibo.com/NMIXXOfficial\nNMIXX Official Website ▶ https://nmixx.jype.com/\nNMIXX Official Fan's ▶ https://fans.jype.com/nmixx\nNMIXX Official TikTok ▶ www.tiktok.com/@nmixx_official\n\nⓒ 2023 JYP Entertainment. All Rights Reserved\n\n#NMIXX #엔믹스\n#A_Midsummer_NMIXXs_Dream \n#Roller_Coaster",
    "sns_content_url": "",
    "sns_youtube_video_id": "fqBAzCH4-9g",
    "tag": [],
    "user_nickname": "준쨩",
    "user_profile": "http://192.168.1.21:10125/user_profile/basic_profile.png",
    "like_cnt": 2,
    "reply_cnt": 1,
    "donate_amount": "0",
    "images": [],
    "rank": 1
}

export const MY_LIKE_ITEM = {
    sns_id:0,
}

export const DETAIL_ITEM = {
    "sns_id": 0,
    "user_id": 30,
    "sns_type": "5",
    "sns_timestamp": "1689664074173",
    "sns_youtube_publish_timestamp": "1688786679000",
    "sns_title": "NMIXX 'Roller Coaster' M/V",
    "sns_description": "NMIXX 'Roller Coaster' M/V\n\nNMIXX 'A Midsummer NMIXX's Dream' Pre-order & Pre-save ▶ https://nmixx.lnk.to/AMidsummerNMIXXDream\n\nNMIXX Official YouTube ▶ https://www.youtube.com/c/NMIXXOfficial\nNMIXX Official Twitter ▶ https://twitter.com/NMIXX_official\nNMIXX Official Instagram ▶ https://www.instagram.com/nmixx_official/\nNMIXX Official Facebook ▶ https://www.facebook.com/NMIXXOfficial/\nNMIXX Official Weibo ▶ https://weibo.com/NMIXXOfficial\nNMIXX Official Website ▶ https://nmixx.jype.com/\nNMIXX Official Fan's ▶ https://fans.jype.com/nmixx\nNMIXX Official TikTok ▶ www.tiktok.com/@nmixx_official\n\nⓒ 2023 JYP Entertainment. All Rights Reserved\n\n#NMIXX #엔믹스\n#A_Midsummer_NMIXXs_Dream \n#Roller_Coaster",
    "sns_thumbnail_1": "",
    "sns_thumbnail_2": "",
    "sns_thumbnail_3": "",
    "sns_thumbnail_4": "",
    "sns_thumbnail_5": "",
    "sns_content_url": "",
    "sns_youtube_video_id": "fqBAzCH4-9g",
    "tag": [],
    "user_nickname": "준쨩",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png",
    "like_cnt": 2,
    "reply_cnt": 1,
    "donate_amount": "100"
}

export const RANK_ITEM = {
    "user_id": 0,
    "donate_amount": "100",
    "sns_donate_timestamp": "1689930674449",
    "user_nickname": "ivefan",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png"
}

export const LIKE_ITEM = {
    "sns_id": 0,
    "user_id": 0,
    "sns_like_timestamp": "1691569736486",
    "user_nickname": "",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png",
    "user_country": "KR"
}

export const REPLY_ITEM = {
    "user_id": 0,
    "sns_reply_group_id": 1,
    "sns_reply_order": 1,
    "sns_reply_depth": 1,
    "sns_reply_content": "엔믹스",
    "sns_reply_timestamp": "1689836197346",
    "user_nickname": "SUBOX dev",
    "user_profile": "https://music.dapi.subox.co.kr/user_profile/basic_profile.png"
}

// story 리스트 등록 순
export const apiGetStoryList = async(token:string,user_id:any,page:any,type:any) => {
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'getSnsFeedBasicList',
            params:{
                user_id,
                page,
                count:10,
                feed_type:type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 내가 좋아요한 리스트
export const apiGetMyLikeList = async(token:string,user_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'getLikeFeedList',
            params:{
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 좋아요 하기
export const apiSetStoryLike = async(token:string,user_id:any,sns_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'setFeedLike',
            params:{
                user_id,
                sns_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 좋아요 취소
export const apiSetStoryUnLike = async(token:string,user_id:any,sns_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'setFeedUnLike',
            params:{
                user_id,
                sns_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// story type별 리스트
export const apiGetStoryListType = async(token:string,user_id:any,page:any,type:number,category:number) => {
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'getSnsFeedListWithType',
            params:{
                user_id,
                page,
                count:1000,
                feed_type:type,
                feed_small_category:category
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// story detail
export const apiGetStoryDetail = async(token:string,user_id:any,sns_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'getSnsFeedDetail',
            params:{
                user_id,
                sns_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 후원 랭킹
export const apiGetDonateRank = async(token:string,user_id:any,sns_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sns/donate',token).post('',{
            method:'setDonateTopRanking',
            params:{
                user_id,
                sns_id,
                page:1,
                count:10,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 해당 story 좋아요 리스트
export const apiGetStoryLikeList = async(token:string,user_id:any,sns_id:any) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'getLikeTargetFeedUserList',
            params:{
                user_id,
                sns_id,
                page:1,
                count:10,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 댓글 리스트
export const apiGetStoryReplyList = async(token:string,user_id:any,sns_id:any,page:number) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'getFeedReplyList',
            params:{
                user_id,
                sns_id,
                page,
                count:15,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 댓글 작성 
export const apiSetStoryReply = async(token:string,user_id:any,sns_id:any,reply_group:any,content:string) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'setWriteFeedReply',
            params:{
                user_id,
                sns_id,
                reply_group,
                content
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result:false,
        }
    }
}

// 후원하기
export const apiSetStoryDonate = async(token:string,user_id:any,sns_id:any,donate_amount:any) =>{
    try {
        const res = await axiosConfigInstance('/sns/donate',token).post('',{
            method:'setDonateFeed',
            params:{
                user_id,
                sns_id,
                donate_amount
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 업로드 텍스트
export const apiSetStoryText = async(token:string,user_id:any,sns_title:string,sns_description:string,sns_tag:any) =>{
    try {
        const res = await axiosConfigInstance('/sns/upload',token).post('',{
            method:'uploadOnlyTextFeed',
            params:{
                user_id,
                sns_title,
                sns_description,
                sns_tag,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 업로드 이미지
export const apiSetStoryImg = async(token:string,user_id:any,sns_title:string,sns_description:string,sns_tag:any,imgList:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','uploadImageFeed');
        formData.append('params', JSON.stringify({
            user_id,
            sns_title,
            sns_description,
            sns_tag,
        }));
        for (let i = 0; i < imgList.length; i++) {
            formData.append(`feed_images${i+1}`,imgList[i]);
        }
        const res = await axiosConfigInstance(`/sns/upload`,token,type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// 리스팅 신청
export const apiSetListing = async(token:string,user_id:any,video_id:string) =>{
    try {
        const res = await axiosConfigInstance('/sns/upload',token).post('',{
            method:'requestVideoListing',
            params:{
                user_id,
                video_id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}

// story 삭제
export const apiSetStoryDel = async(token:string,user_id:number,sns_id:number) =>{
    try {
        const res = await axiosConfigInstance('/sns',token).post('',{
            method:'deleteFeed',
            params:{
                user_id,
                sns_id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}