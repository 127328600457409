import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { TextItem } from '../../../style/basic/commonStyle';
import { useGlobalAlert } from '../../../utils/custom-hooks/common/useGlobalAlert';
import { setGlobalAlert } from '../../../store/common/globalAlert';
import { useDispatch } from 'react-redux';

export default function AlertPart() {
    const alert = useGlobalAlert();
    const dispatch = useDispatch();

    const close = () =>{
        dispatch(setGlobalAlert({
            open:false,
            title:'',
            content:'',
        }));
    }

    return (
        <div>
            <Dialog open={alert.open} sx={{'.MuiPaper-root':{width:'100%',maxWidth:'320px',backgroundColor:'#12101e'}}}>
                <DialogTitle sx={{color:'#fff'}}>{alert.title}</DialogTitle>
                <DialogContent>
                   <TextItem dangerouslySetInnerHTML={{__html:alert.content}}></TextItem>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>확인</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
