import React from 'react'
import { useGlobalAudio } from './common/useGlobalAudio'
import { useDispatch } from 'react-redux';
import { setAudioData } from '../../store/common/globalAudio';

export default function useAudioAction() {
    const audio = useGlobalAudio();
    const dispatch = useDispatch();

    const add = (data:any) =>{
        const chk = audio.filter((item:any)=>item.musicSrc === data.url);
        if(chk.length === 0){
            dispatch(setAudioData(
                [...audio,{name:data.title,singer:data.artist,cover:data.artwork,musicSrc:data.url,sound_id:data.sound_id,album_id:data.album_id}]
            ))
        }
    }

    const addAll = (arr:any)=>{
        let newList:any = [];
        arr.map((data:any)=>{
            const chk = audio.filter((item:any)=>item.musicSrc === data.url);
            if(chk.length === 0){
                newList.push({name:data.title,singer:data.artist,cover:data.artwork,musicSrc:data.url,sound_id:data.sound_id,album_id:data.album_id})
            }
        })
        
        dispatch(setAudioData(
            [...audio, ...newList]
        ))
    }

    const del = (data:any) =>{
        if(data === 'all'){
            dispatch(setAudioData([]));
            return;
        }
        // const t = audio.filter((item:any) => item.musicSrc === data)
        
        dispatch(setAudioData(
            audio.filter((item:any) => item.musicSrc === data)
        ))
    }

    return {add,addAll,del}
}
