import React,{useEffect,useState} from 'react'
import styled from 'styled-components';
import { MaxWidthWrap, Row, TextItem } from '../../../style/basic/commonStyle';
import FooterLeftPart from '../part/FooterLeftPart';
import FooterRightPart from '../part/FooterRightPart';
import { addTheme } from '../../../style/addTheme';
import { COMPANY, SNS_ITEM, } from '../../../api/api_home';

export interface footerPartType {
    company : typeof COMPANY;
    sns?: Array<typeof SNS_ITEM>;
}

const COMPANY_DUMMY = {
    "info_id": 1,
    "info_name": "(주) 슈박스",
    "info_addr": "서울특별시 강남구 밤고개로 1길 10, 6층",
    "info_num": "172-86-01671",
    "info_cap": "김성호",
    "info_phone": "support@subox.co.kr",
    "info_bNum": "제 2021-서울강남-03905호",
    "info_customer": "070-7733-7285"
}

export default function FooterPage() {
    const [company,setCompany] = useState(COMPANY_DUMMY);
    const [sns,setSns] = useState([SNS_ITEM]);

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <LeftWrap>
                        <FooterLeftPart company={company}></FooterLeftPart>
                    </LeftWrap>
                    <RightWrap>
                        <FooterRightPart company={company} sns={sns}></FooterRightPart>
                    </RightWrap>
                </InnerWrap>
                <TextItem marginTop={50} fontSize={13}>Copyright ⓒ 2023 SUBOX. All rights Reserved</TextItem>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #242424;
    padding: 80px 0 100px;
`;

const InnerWrap = styled(Row)`
    align-items: start;
    flex-wrap: wrap;
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
`;

const LeftWrap = styled.div`
    width: 60%;
`;

const RightWrap = styled.div`
    width: 40%;
`;
