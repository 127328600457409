import React from 'react'
import styled from 'styled-components'
import { MUSIC_ITEM, NEW_ALBUM_ITEM, PLAYLIST_ITEM } from '../../../api/api_music'
import MusicAlbumItem from '../../music/item/MusicAlbumItem';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import PaginationItem from '../../common/item/PaginationItem';
import { addTheme } from '../../../style/addTheme';
import { useDispatch } from 'react-redux';
import { setAlbum } from '../../../store/common/clickAlbum';
import { useNavigate } from 'react-router';
import MusicPlayListItem from '../../music/item/MusicPlayListItem';
import NoneItem from '../item/NoneItem';

interface searchResPartType {
    albumList: Array<typeof NEW_ALBUM_ITEM>;
    playList: Array<typeof PLAYLIST_ITEM>;
    page?:number;
    total?:number;
    setPage?:any;
}

export default function SearchResAlbumPart({albumList,playList,page,total,setPage}:searchResPartType) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const albumClick = (data:any) =>{
        dispatch(setAlbum(data));
        navigate(`/searchAlbumDetail/${data.album_id}`);
    }

    const playlistClick = (data:any) =>{
        dispatch(setAlbum(data));
        navigate(`/searchPlayListDetail/${data.playlist_id}`);
    }

    return (
        <Wrap>
            {albumList.length === 0 ? <NoneItem title='앨범'></NoneItem>
                : albumList[0].album_id !== 0 &&
                    <InnerWrap>
                        <TextItem>앨범</TextItem>
                        <ListWrap>
                            {albumList.map((data,index)=>{
                                return(
                                    <MusicAlbumItem key={`album-item-${index}`} data={data} clickEvent={albumClick}></MusicAlbumItem>
                                )
                            })}
                        </ListWrap>
                    </InnerWrap>
            }
            {playList.length === 0 ? <NoneItem title='플레이리스트'></NoneItem>
                : playList[0].playlist_id !== 0 &&
                    <InnerWrap>
                        <TextItem>플레이리스트</TextItem>
                        <ListWrap>
                            {playList.map((data,index)=>{
                                return(
                                    <MusicPlayListItem key={`album-item-${index}`} data={data} clickEvent={playlistClick}></MusicPlayListItem>
                                )
                            })}
                        </ListWrap>
                    </InnerWrap>
            }
            {page !== undefined && total !== 0 &&
                <PageWrap>
                    <PaginationItem page={page} total={total} setPage={setPage}></PaginationItem>
                </PageWrap>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;

const InnerWrap = styled.div`
    margin-top: 80px;
    &:first-of-type{
        margin-top: 35px;
    }
`;

const ListWrap = styled(Row)`
    flex-wrap: wrap;
    &>div{
        width: 25%;
        padding: 10px;
    }
    @media ${addTheme.media.m}{
        &>div{
            width: 50%;
        }
    }
`;

const PageWrap = styled.div`
    margin-top: 25px;
`;