import React from 'react'
import { Helmet } from 'react-helmet-async'

interface HeadType {
    title: string;
}

export default function Head({title}:HeadType) {
    return (
        <Helmet>
            <title>SUBOX MUSIC - {title}</title>
            <meta property="og:title" content={`SUBOX MUSIC - ${title}`} />
        </Helmet>
    )
}
