import React,{useState} from 'react'
import styled from 'styled-components'
import { TextItem } from '../../../style/basic/commonStyle';
import SelectItem from '../../common/item/SelectItem';
import InputItem from '../../common/item/InputItem';
import { addTheme } from '../../../style/addTheme';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const ALBUM = [
    {id:1,name:'앨범1'},
    {id:2,name:'앨범2'},
]

const GENRE = [
    {id:1,name:'힙합'},
    {id:2,name:'판소리'},
]

export default function UploadPart() {
    const [album,setAlbum] = useState('');
    const [file,setFile] = useState<any>(null);
    const [title,setTitle] = useState('');
    const [bpm,setBpm] = useState(0);
    const [genre,setGenre] = useState('');
    const [content,setContent] = useState('');

    const upload = (e:any) =>{
        e.preventDefault();
    }

    const getFile = (e:any) =>{
        const audio = e.target.files[0];
        if(audio === undefined){
            setFile(null);
            return;
        }
        setFile(audio);
    }

    return (
        <Wrap>
            <form onSubmit={upload}>
                <TextItem fontSize={18} marginTop={25}>앨범</TextItem>
                <SelectItem margin='15px 0 0 0' value={album} setValue={setAlbum}>
                    {ALBUM.map((data,index)=>{
                        return(
                            <MenuItem key={`album-${index}`} value={data.name}>{data.name}</MenuItem>
                        )
                    })}
                </SelectItem>
                <TextItem fontSize={18} marginTop={25}>음악 파일</TextItem>
                <InputItem type={'file'} margin='15px 0 0 0' id='audio-file' inputLabel='none' label={file === null ? '음악 파일 첨부하기':file.name} inputProps={{'accept':'.mp3,.wav'}} valueChange={getFile}></InputItem>
                <TextItem fontSize={18} marginTop={25}>노래 제목</TextItem>
                <InputItem type={'text'} margin='15px 0 0 0' value={title} valueChange={setTitle}></InputItem>
                <TextItem fontSize={18} marginTop={25}>BPM</TextItem>
                <InputItem type={'number'} margin='15px 0 0 0' value={bpm} valueChange={setBpm}></InputItem>
                <TextItem fontSize={18} marginTop={25}>장르</TextItem>
                <SelectItem margin='15px 0 0 0' value={genre} setValue={setGenre}>
                    {GENRE.map((data,index)=>{
                        return(
                            <MenuItem key={`album-${index}`} value={data.name}>{data.name}</MenuItem>
                        )
                    })}
                </SelectItem>
                <TextItem fontSize={18} marginTop={25}>곡 설명</TextItem>
                <textarea rows={5} value={content} onChange={(e:any)=>setContent(e.target.value)}></textarea>
                <Button type='submit' fullWidth variant='contained' sx={{backgroundColor:'#009764',marginTop:'20px','&:hover':{backgroundColor:'#018b5d'}}}>업로드</Button>
            </form>
        </Wrap>
    )
}

const Wrap = styled.div`
    & textarea{
        width: 100%;
        border: 1px solid #fff;
        border-radius: 4px;
        color: #fff;
        margin-top: 15px;
        padding: 8.5px 14px;
        font-size:16px;
    }
`;