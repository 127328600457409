import {RootState} from '../../../store/store';
import {useSelector} from 'react-redux';

function selector(state: RootState) {
    return state.globalReport;
}

export function useGlobalReport() {
  return useSelector<RootState, ReturnType<typeof selector>>(selector);
}
