import React from 'react'
import { Row, RowBetween, TextItem } from '../../../style/basic/commonStyle'
import styled from 'styled-components'
import { MUSIC_ITEM } from '../../../api/api_music'
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';

interface musicOtherItemType{
    data: typeof MUSIC_ITEM;
}

export default function MusicOtherItem({data}:musicOtherItemType) {
    const navigate = useNavigate();

    const clickEvent = () =>{
        if(data.sound_id === 0) return;
        navigate(`/searchDetail/${data.sound_id}`);
    }
    
    return(
        <div>
            <ItemWrap>
                <LeftWrap onClick={clickEvent}>
                    <ImgItem>
                        <img alt='album-img' src={data.artwork}></img>
                    </ImgItem>
                    <Title>{data.title}</Title>
                </LeftWrap>
                <RightWrap>
                    <Title>{data.artist}</Title>
                </RightWrap>
            </ItemWrap>
        </div>
    )
}

const ItemWrap = styled(RowBetween)`
    margin-top: 20px;
    flex-wrap: wrap;
    &>div:first-of-type{
        width: 60%;
        margin-right: 10%;
    }
    &>div:last-of-type{
        width: 30%;
    }
`;

const LeftWrap = styled(Row)`
    cursor: pointer;
`;

const RightWrap = styled.div`
    flex-shrink: 0;
`;

const ImgItem = styled.div`
    width: 42px;
    height: 42px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 110%;
    }
`;

const Title = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;