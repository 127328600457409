import React from 'react'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import { Center, TextItem } from '../../../style/basic/commonStyle';

export default function BottomBannerItem({title,link,img,clickEvent}:any) {
    
    return (
        <ItemWrap onClick={()=>{clickEvent(link)}} linkState={link === ''}>
            <TextWrap>
                <PreWrap>
                    {title}
                </PreWrap>
            </TextWrap>
            <ImgWrap>
                <img src={img}></img>
            </ImgWrap>
        </ItemWrap>
    ) 
}


const ItemWrap = styled.div<{linkState:boolean}>`
    display: flex !important;
    align-items: stretch;
    min-height: 260px;
    overflow: hidden;
    border-radius: 20px;
    flex-wrap: wrap;
    cursor: ${props => props.linkState ? 'default' : 'pointer'};
    &>div{
        width: 50%;
    }
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
`;

const TextWrap = styled(Center)`
    background-color: #2c3084;
    justify-content: start;
    padding:30px 0 30px 60px;
    min-height: 180px;
    @media ${addTheme.media.m}{
        padding: 30px 20px;
    }
`;

const ImgWrap = styled.div`
    background-color: #eee;
    overflow: hidden;
    position: relative;
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 110%;
    }
    @media ${addTheme.media.m}{
        height: 172px;
    }
`;

const PreWrap = styled.pre`
    font-weight: 700;
    font-size: 22px;
    @media ${addTheme.media.m}{
        font-size: 18px;
    }
`;