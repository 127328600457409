import React,{useEffect, useState} from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import styled from 'styled-components'
import MusicBannerPart from '../part/MusicBannerPart'
import MusicNewMusicPart from '../part/MusicNewMusicPart'
import { MaxWidthWrap } from '../../../style/basic/commonStyle'
import MusicNewAlbumPart from '../part/MusicNewAlbumPart'
import MusicPlayListPart from '../part/MusicPlayListPart'
import { BANNER_ITEM, NEW_ALBUM, NEW_MUSIC, PLAYLIST, apiGetMusicBanner, apiGetNewAlbum, apiGetNewMusic, apiGetPlayList } from '../../../api/api_music'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang'
import Head from '../../../routes/Head'

export default function MusicPage() {
    const user = useUserInfo();
    const {number} = useGlobalLang();
    const [bannerList,setBannerList] = useState([BANNER_ITEM]);
    const [newMusic,setNewMusic] = useState(NEW_MUSIC);
    const [newAlbum,setNewAlbum] = useState(NEW_ALBUM);
    const [playList1,setPlayList1] = useState(PLAYLIST);
    const [playList2,setPlayList2] = useState(PLAYLIST);
    const [playList3,setPlayList3] = useState(PLAYLIST);

    useEffect(()=>{
        getBanner();
        getNewMusic();
        getNewAlbum();
        getPlayList1();
        getPlayList2();
        getPlayList3();
    },[])

    // 배너
    const getBanner = async() => {
        const res = await apiGetMusicBanner(user.jwt_token,user.user_id,1,10);
        if(res.result){
            setBannerList(res.data);
        }else{
            setBannerList([]);
        }
    }

    // 신곡
    const getNewMusic = async() =>{
        const res = await apiGetNewMusic(user.jwt_token,user.user_id,1);
        
        if(res.result){
            setNewMusic(res);
            return;
        }
        setNewMusic(NEW_MUSIC);
    }

    // 신규
    const getNewAlbum = async() =>{
        const res = await apiGetNewAlbum(user.jwt_token,user.user_id,1);
        if(res.result){
            setNewAlbum(res.data);
            return;
        }
        setNewAlbum(NEW_ALBUM);
    }

    // 플레이 리스트
    const getPlayList1 = async() =>{
        const res = await apiGetPlayList(user.jwt_token,user.user_id,1,'MAIN_HORIZONTAL_001');
        
        if(res.result){
            setPlayList1(res.data);
            return;
        }
        setPlayList1(PLAYLIST);
    }
    // 플레이 리스트
    const getPlayList2 = async() =>{
        const res = await apiGetPlayList(user.jwt_token,user.user_id,1,'MAIN_HORIZONTAL_002');
        
        if(res.result){
            setPlayList2(res.data);
            return;
        }
        setPlayList2(PLAYLIST);
    }
    // 플레이 리스트
    const getPlayList3 = async() =>{
        const res = await apiGetPlayList(user.jwt_token,user.user_id,1,'MAIN_HORIZONTAL_003');
        
        if(res.result){
            setPlayList3(res.data);
            return;
        }
        setPlayList3(PLAYLIST);
    }

    return (
        <LayoutPage>
            <Head title='music' />
            <Wrap>
                {/* <SearchMenuPart type='music'></SearchMenuPart> */}
                <InnerWrap>
                    <MusicBannerPart list={bannerList}></MusicBannerPart>
                </InnerWrap>
                <MaxWidthWrap>
                    <InnerWrap>
                        <MusicNewMusicPart title={number === 0 ? newMusic.main_title_ko : newMusic.main_title_en} list={newMusic.data}></MusicNewMusicPart>
                    </InnerWrap>
                    <InnerWrap>
                        <MusicNewAlbumPart title={number === 0 ? newAlbum.main_title_ko : newAlbum.main_title_en} list={newAlbum.data}></MusicNewAlbumPart>
                    </InnerWrap>
                    <InnerWrap>
                        <MusicPlayListPart title={number === 0 ? playList1.main_title_ko : playList1.main_title_en} list={playList1.data}></MusicPlayListPart>
                    </InnerWrap>
                    <InnerWrap>
                        <MusicPlayListPart title={number === 0 ? playList2.main_title_ko : playList2.main_title_en} list={playList2.data}></MusicPlayListPart>
                    </InnerWrap>
                    <InnerWrap>
                        <MusicPlayListPart title={number === 0 ? playList3.main_title_ko : playList3.main_title_en} list={playList3.data}></MusicPlayListPart>
                    </InnerWrap>
                </MaxWidthWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    padding-bottom: 85px;
`;

const InnerWrap = styled.div`
    padding-bottom: 85px;
`;