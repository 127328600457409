import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TabPanelPart, a11yProps } from '../../common/part/TabPanelPart';
import { ALBUM_ITEM, SOUND_ITEM, apiGetUserAlbumList, apiGetUserBeatList, apiGetUserSoundList } from '../../../api/api_user';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import UserContentTabItem from '../item/UserContentTabItem';

export default function UserContentPart({targetId}:any) {
    const user = useUserInfo();
    const [value,setValue] = useState(0);
    const [page,setPage] = useState(1);
    const [list,setList] = useState<Array<typeof SOUND_ITEM | typeof ALBUM_ITEM>>([])
    const [total,setTotal] = useState(0);
    const [cnt,setCnt] = useState(0);
    const handleChange = (e:any,val:any) =>{
        setValue(val);
        setPage(1);
    }

    useEffect(()=>{
        if(value === 0){
            getSound();
        }
        if(value === 1){
            getBeat();
        }
        if(value === 2){
            getAlbum();
        }
    },[value,page])

    const getSound = async() =>{
        const res = await apiGetUserSoundList(user.jwt_token,user.user_id,targetId,page);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            setCnt(res.sound_cnt);
            return;
        }
        setList([SOUND_ITEM]);
        setTotal(0);
        setCnt(0);
    }

    const getBeat = async() =>{
        const res = await apiGetUserBeatList(user.jwt_token,user.user_id,targetId,page);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            setCnt(res.sound_cnt);
            return;
        }
        setList([SOUND_ITEM]);
        setTotal(0);
        setCnt(0);
    }

    const getAlbum = async() =>{
        const res = await apiGetUserAlbumList(user.jwt_token,user.user_id,targetId,page);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            setCnt(res.album_cnt);
            return;
        }
        setList([ALBUM_ITEM]);
        setTotal(0);
        setCnt(0);
    }

    return (
        <Wrap>
             <Tabs value={value} onChange={handleChange} aria-label="basic">
                <Tab sx={{color:'#fff'}} label="음악" {...a11yProps(0)} />
                <Tab sx={{color:'#fff'}} label="비트" {...a11yProps(1)} />
                <Tab sx={{color:'#fff'}} label="앨범" {...a11yProps(1)} />
            </Tabs>
            <TabPanelPart value={value} index={0}>
                <UserContentTabItem cnt={cnt} list={list} total={total} type='sound' page={page} setPage={setPage}></UserContentTabItem>
            </TabPanelPart>
            <TabPanelPart value={value} index={1}>
                <UserContentTabItem cnt={cnt} list={list} total={total} type='sound' page={page} setPage={setPage}></UserContentTabItem>
            </TabPanelPart>
            <TabPanelPart value={value} index={2}>
                <UserContentTabItem cnt={cnt} list={list} total={total} type='album' page={page} setPage={setPage}></UserContentTabItem>
            </TabPanelPart>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 25px;
    & .Mui-selected{
        color: #fff !important;
    }
    & .MuiTabs-indicator{
        background-color: #fff !important;
    }
`;