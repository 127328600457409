import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_AlERT= 'SUBOX_AUDIO/SET_ALERT';

export const globalAlertCreator = createAction(SET_AlERT);

export function setGlobalAlert(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalAlertCreator>> {
    return async function (dispatch) {
    try {
            dispatch(globalAlertCreator({
              data: data
            }));
    } catch (error) {
      
    }
  };
}

export interface AlertState {
  open:boolean;
  title:string;
  content:string;
}

const initialState: AlertState = {
  open:false,
  title:'',
  content:'',
};

export const globalAlert = handleActions<AlertState, any>({
  [SET_AlERT]: (state, action: ReturnType<typeof globalAlertCreator>) => ({
    open: action.payload.data.open ?? false,
    title: action.payload.data.title ?? '',
    content: action.payload.data.content ?? '',
  }),
}, initialState);
