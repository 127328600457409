import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_CONFIRM= 'SUBOX_AUDIO/SET_CONFIRM';

export const globalConfirmCreator = createAction(SET_CONFIRM);

export function setGlobalConfirm(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalConfirmCreator>> {
    return async function (dispatch) {
    try {
            dispatch(globalConfirmCreator({
              data: data
            }));
    } catch (error) {
      
    }
  };
}

export interface ConfirmState {
  open:boolean;
  title:string;
  content:string;
  result:boolean;
}

const initialState: ConfirmState = {
  open:false,
  title:'',
  content:'',
  result:false,
};

export const globalConfirm = handleActions<ConfirmState, any>({
  [SET_CONFIRM]: (state, action: ReturnType<typeof globalConfirmCreator>) => ({
    open: action.payload.data.open ?? false,
    title: action.payload.data.title ?? '',
    content: action.payload.data.content ?? '',
    result: action.payload.data.result ?? false,
  }),
}, initialState);
