import React from 'react'
import SliderPart from '../../common/part/SliderPart'
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';

export default function NewWebSliderItem() {
    return (
        <Wrap>
            <SliderPart>
                <Item>
                    <img alt='phone-img' src='/img/new_web/phone-1.png'></img>
                </Item>
                {/* <Item>
                    <img alt='phone-img' src='/img/new_web/phone-1.png'></img>
                </Item>
                <Item>
                    <img alt='phone-img' src='/img/new_web/phone-1.png'></img>
                </Item>
                <Item>
                    <img alt='phone-img' src='/img/new_web/phone-1.png'></img>
                </Item> */}
            </SliderPart>
        </Wrap>
    )
}
const Wrap = styled.div`
    height: 100%;
    &>div{
        height: 100%;
    }
    & .slick-slider{
        height: 100%;
    }
    & .slick-list{
        height: 100%;
    }
    & .slick-track{
        height: 100%;
    }
    & .slick-slide>div{
        height: 100%;
    }
    & .slick-dots li{
        width: 8px;
        height: 8px;
        opacity: 0.25;
        border-radius: 50%;
        background-color: #fff;
        transition: 0.3s ease-in-out;
    }
    & .slick-dots .slick-active{
        width: 16px;
        opacity: 0.8;
        border-radius: 16px;
    }

    & .slick-dots li button::before{content:none;}
`;

const Item = styled(Center)`
    display: flex !important;
    height: 100%;
    & img{
        width: 62%;
        display: inline-block;
    }
`;