import React from 'react'
import styled from 'styled-components'
import { Center, Row, TextItem } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import useLang from '../../../utils/custom-hooks/common/useLang';
import TitleItem from '../../common/item/TitleItem';
import { SEASON_ITEM } from '../../../api/api_home';

const LIST_DUMMY = [
    {
        "season_id": 1,
        "season_title": "ONE",
        "season_time": 1690434652157,
        "season_update_time": 1690434738820,
        "season_item": [
            {
                "item_id": 1,
                "item_name": "ATO System",
                "item_active": true
            },
            {
                "item_id": 2,
                "item_name": "ATOREUM",
                "item_active": true
            },
            {
                "item_id": 3,
                "item_name": "Ecosystem",
                "item_active": false
            },
            {
                "item_id": 4,
                "item_name": "Web3.0",
                "item_active": false
            }
        ]
    },
    {
        "season_id": 2,
        "season_title": "TWO",
        "season_time": 1690434659524,
        "season_update_time": 1690434766911,
        "season_item": [
            {
                "item_id": 1,
                "item_name": "STO",
                "item_active": false
            },
            {
                "item_id": 2,
                "item_name": "Expansion",
                "item_active": false
            },
            {
                "item_id": 3,
                "item_name": "NFT Membership",
                "item_active": false
            },
            {
                "item_id": 4,
                "item_name": "Metaverse",
                "item_active": false
            }
        ]
    },
    {
        "season_id": 3,
        "season_title": "FINAL",
        "season_time": 1690434674620,
        "season_update_time": 1690434778570,
        "season_item": [
            {
                "item_id": 1,
                "item_name": "SUWORLD 2030 METAVERSE",
                "item_active": true
            }
        ]
    }
]

interface itemType {
    data : typeof SEASON_ITEM;
    last : boolean;
}

const Item = ({data,last}:itemType) =>{
    return(
        <VerticalTimelineElement
            className="line-wrap-content"
            contentArrowStyle={{ display:'none' }}
            iconClassName='line-wrap-icon'
            position='right'
        >
            <ItemWrap>
                <TextItem fontSize={22} fw={700}>SUWORD SEASON <span>{data.season_title}</span></TextItem>
                <KeywordWrap>
                    
                    {
                        typeof data.season_item !== 'string' && data.season_item.map((item,index)=>{
                            return(
                                last ? 
                                <KeywordLastItem key={`keyword-item-${index}`} activeState={item.item_active}>{item.item_name}</KeywordLastItem> :
                                <KeyWordBg key={`keyword-item-${index}`} activeState={item.item_active}>
                                    <KeywordItem>{item.item_name}</KeywordItem>
                                </KeyWordBg>
                            )
                        })
                    }
                </KeywordWrap>
            </ItemWrap>
        </VerticalTimelineElement>
    )
}

export default function NewDimensionPart() {
    const lang = useLang('intro_dimension');
    // const [season,setSeason] = useState([SEASON_ITEM]);

    // useEffect(() =>{
    //     getSeason();
    // },[])

    // const getSeason = async() =>{
    //     const res = await apiGetSeason();
    //     if(res.result){
    //         setSeason(res.data);
    //         return;
    //     }
    //     setSeason([SEASON_ITEM]);
    // }

    return (
        <Wrap>
            <TitleWrap>
                <TitleItem text={lang[0]}></TitleItem>
            </TitleWrap>
            {/* <ContentWrap 
                heightCount = {season.length === 0 || season[0].season_id === 0 ? LIST_DUMMY.length :  season.length}
            > */}
            <ContentWrap 
                heightCount = {LIST_DUMMY.length}
            >
                <VerticalTimeline className='line-wrap'>
                    {LIST_DUMMY.map((data,index)=>{
                            return(
                                <Item key={`keyword-${index}`} data={data} last={LIST_DUMMY.length === index+1}></Item>
                            )
                        })}
                    {/* {season.length === 0 || season[0].season_id === 0 ? 
                        LIST_DUMMY.map((data,index)=>{
                            return(
                                <Item key={`keyword-${index}`} data={data} last={LIST_DUMMY.length === index+1}></Item>
                            )
                        })
                        :
                        season.map((data,index)=>{
                            return(
                                <Item key={`keyword-${index}`} data={data} last={season.length === index+1}></Item>
                            )
                        })
                    } */}
                </VerticalTimeline>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-wrap: wrap;
    align-items: start;
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
`;

const TitleWrap = styled.div`
    position: relative;
    width: 40%;
    & span{
        color: ${addTheme.color};
    }
    &::after{
        content: '';
        width: 55px;
        height: 55px;
        position: absolute;
        top:-30px;
        left:-20px;
        background-color: #ff5200;
        z-index: -1;
        border-radius: 50%;
        @media ${addTheme.media.m}{
            top:-35px;
            left:-10px;
        }
    }
`;

const ContentWrap = styled.div<{heightCount:number}>`
    width: 60%;
    @media ${addTheme.media.m}{
        margin-top: 80px;
    }
    .line-wrap{
        padding: 0;
    }
    .line-wrap::before{
        width: 2px;
        height: ${props => `calc(${(100 / props.heightCount) * (props.heightCount - 1)}% + 130px);` }
    }
    .line-wrap-content {
        margin: 60px 0;
    }
    .line-wrap-content:first-of-type{
        margin: 0;
    }
    .line-wrap-content .vertical-timeline-element-content{
        padding: 0;
        background-color: #111;
        box-shadow: none;
        
    }
    .line-wrap-icon{
        width: 22px;
        height: 22px;
        background-color: #fff;
        box-shadow: none;
        margin-left: -11px;
    }
    @media ${addTheme.media.m} {
        .line-wrap-icon{
            margin-left: 8px;
        }
        .line-wrap-content .vertical-timeline-element-content{
            margin-left: 45px;
        }
    }
`;

const ItemWrap = styled.div`
    &>div>span{
        color: ${addTheme.color};
    }
`;

const KeywordWrap = styled.div`
    margin-top: 35px;
`;

const KeyWordBg = styled.div<{activeState:boolean}>`
    width: 185px;
    height: 50px;
    margin-top: 15px;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: ${props => props.activeState ? 
        `linear-gradient(90deg, rgba(0,233,132,1) 0%, rgba(0,125,254,1) 50%, rgba(251,0,135,1) 100%)`
        : '#afafaf'
    };
    border-radius: 45px;
    &:first-of-type{
        margin-top: 25px;
    }
    color: ${props => props.activeState ? '#fff' : '#afafaf'};
`;
const KeywordItem = styled(Center)`
    background-color: #111;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-49.8%,-50%);
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    border-radius: 55px;
`;

const KeywordLastItem = styled(Center)<{activeState:boolean}>`
    width: 300px;
    height: 54px;
    margin-top: 30px;
    font-weight: 700;
    background-image: linear-gradient(90deg, rgba(0,233,132,1) 0%, rgba(0,125,254,1) 50%, rgba(251,0,135,1) 100%);
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    border: 1px solid #afafaf;
    border-radius: 54px;
    @media ${addTheme.media.m}{
        font-size: 18px;
        width: 275px;
    }
`;