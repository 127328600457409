import { axiosHome, axiosConfigInstance } from "./config";

export const SEASON_ITEM_ITEM = {
    "item_id": 0,
    "item_name": "",
    "item_active": false
}

export const SEASON_ITEM = {
    "season_id": 0,
    "season_title": "",
    "season_time": 0,
    "season_update_time": 0,
    "season_item": [SEASON_ITEM_ITEM]
}

export const NOTICE_ITEM = {
    "notice_id": 0,
    "notice_type": 0,
    "notice_title": "",
    "notice_content": "",
    "notice_img": [
        ""
    ],
    "notice_time": 0,
    "notice_update_time": 0,
    "notice_delete": 0
}

export const COMPANY = {
    "info_id": 0,
    "info_name": "",
    "info_addr": "",
    "info_num": "",
    "info_cap": "",
    "info_phone": "",
    "info_bNum": "",
    "info_customer": ""
}

export const SNS_ITEM = {
    "sns_id": 0,
    "sns_img": "",
    "sns_link": ""
}

export const COUNT_ITEM = {
    "count_id": 0,
    "count_title_ko": "",
    "count_title_en": "",
    "count_icon": "",
    "count_content": 0,
    "count_type": 0
};

export const BOTTOM_BANNER_ITEM = {
    "bb_id": 0,
    "bb_title": "",
    "bb_link": "",
    "bb_img": "",
    "bb_time": 0,
    "bb_update_time": 0,
    "bb_del": 0
}

export const YOUTUBE_ITEM = {
    "y_id": 0,
    "y_title": "",
    "y_img": "",
    "y_code": "",
    "y_time": 0
}

// 공지사항 리스트
export const apiGetNotice = async(page:any,count:any,id?:any) =>{
    try {
        const res = await axiosConfigInstance('/outer/data').post('',{
            method:'getOuterBbsList',
            params:{
                "from_platform": "subox_notice"
            }
        })
        return res.data;
    } catch (error) {
        return{
            error,
            result:false,
        }
    }
}