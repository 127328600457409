import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React,{useEffect,useState} from 'react'
import { useGlobalReport } from '../../../utils/custom-hooks/common/useGlobalReport';
import { useDispatch } from 'react-redux';
import { setGlobalReport } from '../../../store/common/globalReport';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { REPORT_CATEGORY_ITEM, apiGetReportCategory, apiSetReport } from '../../../api/api_report';
import SelectItem from './SelectItem';
import MenuItem from '@mui/material/MenuItem';
import { setGlobalAlert } from '../../../store/common/globalAlert';

export default function ReportMusicItem() {
    const user = useUserInfo();
    const dispatch = useDispatch();
    const state = useGlobalReport();
    const [category,setCategory] = useState([REPORT_CATEGORY_ITEM]);
    const [selectCategory,setSelectCategory] = useState('');
    const close = () =>{
        dispatch(setGlobalReport({
            open:false,
            id:0
        }))
        setSelectCategory('');
    }
    
    useEffect(() =>{
        if(state.open){
            getCategory();
        }
    },[state.open])

    const getCategory = async() =>{
        const res = await apiGetReportCategory(user.jwt_token,user.user_id,state.type);
        
        if(res.result){
            setCategory(res.data);
            return;
        }
        setCategory([REPORT_CATEGORY_ITEM]);
    }

    const modalAction = (txt:string) =>{
        dispatch(setGlobalAlert({
            open:true,
            title:txt,
            content:'',
        }));
    }

    const reportAction = async() =>{
        if(selectCategory === ''){
            modalAction('카테고리를 선택해주세요.');
            return;
        }
        const res = await apiSetReport(user.jwt_token,user.user_id,state.id,selectCategory,state.type);
        close();
        if(res.result){
            modalAction('신고가 되었습니다.')
        }
    }

    return (
        <div>
            <Dialog open={state.open} onClose={close} sx={{'.MuiPaper-root':{backgroundColor:'#12101e',}}}>
                <DialogTitle sx={{color:'#fff'}}>신고하기</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color:'#fff'}}>
                        {state.title}
                    </DialogContentText>
                    <SelectItem margin='20px 0 0' value={selectCategory} setValue={setSelectCategory}>
                        {
                            category.map((data,index)=>{
                                return(
                                    <MenuItem key={`category-${index}`} value={data.report_category_id}>{data.report_category_reason}</MenuItem>
                                )
                            })
                        }
                    </SelectItem>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>취소</Button>
                    <Button onClick={reportAction}>신고</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
