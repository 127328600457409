import React,{useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components';
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, Row, TextItem } from '../../../style/basic/commonStyle';
import {PiMusicNotesBold} from 'react-icons/pi';
import { useMediaQuery } from '@mui/material';
import { addTheme } from '../../../style/addTheme';
import MusicItem from '../item/MusicItem';
import { MdPlaylistAdd } from 'react-icons/md';
import useAudioAction from '../../../utils/custom-hooks/useAudioAction';
import { apiGetAlbumSoundList } from '../../../api/api_album';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useAlbum } from '../../../utils/custom-hooks/common/useAlbum';
import { MUSIC_ITEM } from '../../../api/api_music';
import {IoAlertCircleOutline} from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { setGlobalReport } from '../../../store/common/globalReport';
import Head from '../../../routes/Head';

export default function SearchPlayListDetailPage() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const user = useUserInfo();
    const albumInfo = useAlbum();
    const navigate = useNavigate();
    const queryChk = useMediaQuery(addTheme.media.m);
    const [list,setList] = useState([MUSIC_ITEM]);
    const audioAction = useAudioAction();

    const addAll = () =>{
        audioAction.addAll(list);
    }

    const getInfo = async() =>{
        const res = await apiGetAlbumSoundList(user.jwt_token,user.user_id,id);
        
        if(res.result){
            setList(res.data);
            return;
        }
        setList([MUSIC_ITEM]);
    }

    useEffect(()=>{
        if(id != albumInfo.id){
            navigate('/');
            return;
        }
        getInfo();
    },[albumInfo]);
    
    const report = () =>{
        dispatch(setGlobalReport({
            open:true,
            id:id,
            title:'해당 플레이리스트를 신고하시겠습니까?',
            type:3
        }))
    }

    return (
        <LayoutPage padding={'0'}>
            <Head title='playlist detail' />
            <Wrap>
                <MaxWidthWrap>
                    <Title>
                        <TextItem textAlign='center' fontSize={26} mFontSize={22}>{albumInfo.title}</TextItem>
                        <CountWrap>
                            <PiMusicNotesBold size={queryChk ? 18 : 24}></PiMusicNotesBold>
                            <TextItem fontSize={24} mFontSize={18}>{list.length} Tracks</TextItem>
                        </CountWrap>
                        <User>
                            <Profile>
                                <Item>
                                    <Img alt='playlist-img' src={albumInfo.profile}></Img>
                                </Item>
                            </Profile>
                            <TextItem w='auto'>{albumInfo.artist}</TextItem>
                        </User>
                    </Title>
                    <ItemWrap>
                        <Item>
                            <Img alt='playlist-img' src={albumInfo.img}></Img>
                        </Item>
                    </ItemWrap>
                    <ListWrap>
                        <BtnWrap>
                            <AddBtn onClick={addAll}>
                                <MdPlaylistAdd size={32}></MdPlaylistAdd>
                                전체 추가
                            </AddBtn>
                            {albumInfo.type === 'album' &&
                                <AddBtn onClick={report}>
                                    <IoAlertCircleOutline size={26}></IoAlertCircleOutline>
                                    신고
                                </AddBtn>
                            }
                        </BtnWrap>
                        {list.length !== 0 && list[0].album_id !== 0 && 
                        list.map((data,index)=>{
                            return(
                                <MusicItem key={`music-item${index}`} data={data}></MusicItem>
                            )
                        })}
                    </ListWrap>
                </MaxWidthWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    padding:60px 0 80px;
    background: url('/img/view_bg.jpg');
    background-repeat: no-repeat;
`;
const Title = styled.div`
    &>div{
        text-align: center;
    }
`;
const User = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
`;

const Profile = styled.div`
    position: relative;
    width: 100%;
    max-width: 46px;
    margin-right: 8px;
    &::after{
        content:'';
        display: block;
        padding-bottom: 100%;
    }
`;

const CountWrap = styled(Center)`
    margin-top: 30px;
    &>div{
        width: auto;
    }
    &>svg{
        margin-right: 10px;
    }
`;

const ItemWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 320px;
    margin: 70px auto 0;
    &::after{
        content:'';
        display: block;
        padding-bottom: 100%;
    }
`;

const Item = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding: 15px;
`;

const Img = styled.img`
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 110%;
`;

const BtnWrap = styled.div`
    display: flex;
    justify-content: end;
`;

const AddBtn = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    &:nth-last-of-type(1){
        margin-right: 0;
    }
    &:hover{
        &{
            color:#00ff9c; 
        }
        &>svg{
            color: #00ff9c;
        }
    }
    &>svg{
        margin-right: 4px;
    }
`;

const ListWrap = styled.div`
    margin-top: 60px;
    background-color: #000;
    padding: 16px;
    border-radius: 10px;
`;