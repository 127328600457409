import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import WelcomeSlickPart from '../part/WelcomeSlickPart';

const LIST = [
    {title:'나의 창작음원',content:'자유롭게 업로드',img:'/img/welcome/icon-01.png'},
    {title:'다양한 장르',content:'자유롭게 듣기',img:'/img/welcome/icon-02.png'},
    {title:'창작 아티스트만의',content:'특별한 프로필',img:'/img/welcome/icon-03.png'},
    {title:'슈박스',content:'재미있게 모으기',img:'/img/welcome/icon-04.png'},
    {title:'인공지능 ATO',content:'재미있게 이용하기',img:'/img/welcome/icon-05.png'},
    {title:'슈박스로',content:'내 아티스트 응원하기',img:'/img/welcome/icon-06.png'},
    {title:'모은 포인트로',content:'마음껏 쇼핑하기',img:'/img/welcome/icon-07.png'},
    {title:'음악과 소통으로',content:'새로운 친구와 만나기',img:'/img/welcome/icon-08.png'},
    {title:'미니 게임으로',content:'재미있게 함께하기',img:'/img/welcome/icon-09.png'},
    {title:'음악 차트',content:'한눈에 알아보기',img:'/img/welcome/icon-10.png'},
]

const LEFT_LIST = LIST.filter((_,index)=>index % 2 === 1);
const RIGHT_LIST = LIST.filter((_,index)=>index % 2 === 0);

export default function WelcomePage() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <MainImgWrap>
                    <img alt='welcome' src='/img/welcome/icon-top.png' />
                </MainImgWrap>
                <ContentWrap>
                    <ContentItem>
                        <WelcomeSlickPart autoplaySpeed={6000} center list={LEFT_LIST}/>
                    </ContentItem>
                    <ContentItem>
                        <WelcomeSlickPart autoplaySpeed={6000} list={RIGHT_LIST} />
                    </ContentItem>
                </ContentWrap>
            </MaxWidthWrap>
            <BottomWrap></BottomWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const MainImgWrap = styled(Center)`
    width: 100%;
    padding-top: 50px;
    & img{
        width: 85%;
        max-width: 456px;
    }
`;

const ContentWrap = styled(RowBetween)`
    max-width: 720px;
    margin: 60px auto 0;
`;
const ContentItem = styled.div`
    width: 49%;
`;

const BottomWrap = styled.div`
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    width: 100%;
    height: 150px;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%);
`;