import React, { useEffect, useState } from 'react'
import LayoutPage from '../../common/page/LayoutPage'
import styled from 'styled-components'
import MusicInfoPart from '../part/SearchInfoPart';
import { useParams, useSearchParams } from 'react-router-dom';
import { MUSIC_INFO, apiGetMusicInfo, apiGetSimilarList } from '../../../api/api_music';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import Head from '../../../routes/Head';

export default function SearchDetailPage() {
    const [searchParams,setSearchParams] = useSearchParams();
    const [info,setInfo] = useState(MUSIC_INFO);
    const [similar,setSimilar] = useState([MUSIC_INFO]);
    const {id} = useParams();
    const user = useUserInfo();
    
    // 정렬
    const s = searchParams.get('s') === null ? 0 : searchParams.get('s');
    // 현재 페이지
    const nowPage = searchParams.get('p') === null ? 1 : searchParams.get('p');

    const [total,setTotal] = useState(1);

    useEffect(()=>{
        if(id === undefined) return;
        getInfo();
        getSimilar();
    },[s,nowPage,id])


    const getInfo = async() =>{
        const res = await apiGetMusicInfo(user.jwt_token,user.user_id,id);
        
        if(res.result){
            setInfo(res.data);
            return;
        }
        setInfo(MUSIC_INFO);
    }

    const getSimilar = async() =>{
        const res = await apiGetSimilarList(user.jwt_token,user.user_id,id);
        
        if(res.result){
            setSimilar(res.data);
            return;
        }
        setSimilar([MUSIC_INFO]);
    }

    return (
        <LayoutPage padding={0}>
            <Head title='music detail' />
            <Wrap>
                <MusicInfoPart info={info} similar={similar}></MusicInfoPart>
                {/* <MusicDetailCommentPart s={s} nowPage={nowPage} total={total}></MusicDetailCommentPart> */}
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;

