import React,{useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme';
import { AiOutlineSwapLeft,AiOutlineSwapRight } from 'react-icons/ai';
import EveryDaySliderPart from './EveryDaySliderPart';
import useLang from '../../../utils/custom-hooks/common/useLang';
import TitleItem from '../../common/item/TitleItem';

const LIST = [
    {playList_id:1,playList_img:'/img/album/album_image_1.jpg',playList_title:'Amazing Game', playList_genre:'Pop'},
    {playList_id:2,playList_img:'/img/album/album_image_2.jpg',playList_title:'Chill Vibes', playList_genre:'Acoustic'},
    {playList_id:3,playList_img:'/img/album/album_image_3.jpg',playList_title:'Throwback Thursday', playList_genre:'Pop'},
    {playList_id:4,playList_img:'/img/album/album_image_4.jpg',playList_title:'In the Mood for Jazz', playList_genre:'Jazz'},
    {playList_id:5,playList_img:'/img/album/album_image_5.jpg',playList_title:'Workout Mix', playList_genre:'HipPop'},
    {playList_id:6,playList_img:'/img/album/album_image_6.jpg',playList_title:'Country Roads', playList_genre:'Country'},
    {playList_id:7,playList_img:'/img/album/album_image_7.jpg',playList_title:'Sunday Brunch', playList_genre:'NewAge'},
    {playList_id:8,playList_img:'/img/album/album_image_8.jpg',playList_title:'Classical Relaxation', playList_genre:'Classical'},
    {playList_id:9,playList_img:'/img/album/album_image_9.jpg',playList_title:'Party Anthems', playList_genre:'EDM'},
    {playList_id:10,playList_img:'/img/album/album_image_10.jpg',playList_title:'Latin Fever', playList_genre:'Latin'},
    {playList_id:11,playList_img:'/img/album/album_image_11.jpg',playList_title:'Motivation Mix', playList_genre:'HipPop'},
    {playList_id:12,playList_img:'/img/album/album_image_12.jpg',playList_title:'90s Nostalgia', playList_genre:'Pop'},
]

export default function EveryDayPart() {
    const lang = useLang('intro_every');
    const [list,setList] = useState(LIST);
    const sliderRef = useRef<any>(null);

    const previousSlide = () => {
        sliderRef.current.slickPrev();
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    useEffect(()=>{
        getList();
    },[])

    const getList = async() => {
    }

    return (
        <div>
            <MaxWidthWrap>
                <TitleWrap>
                    <LeftWrap>
                        <AiOutlineSwapLeft size={60} onClick={previousSlide}></AiOutlineSwapLeft>
                    </LeftWrap>
                    <CenterWrap>
                        <div>
                            <TextItem textAlign='center' fontSize={16}>{lang[0]}</TextItem>
                            <TitleItem align='center' text={lang[1]}></TitleItem>
                        </div>
                    </CenterWrap>
                    <RightWrap>
                        <AiOutlineSwapRight size={60} onClick={nextSlide}></AiOutlineSwapRight>
                    </RightWrap>
                </TitleWrap>
            </MaxWidthWrap>
            <SliderWrap>
                <EveryDaySliderPart list={list} sliderRef={sliderRef}></EveryDaySliderPart>
            </SliderWrap>
        </div>
    )
}

const TitleWrap = styled(Row)`
    flex-wrap: wrap;
    align-items: end;
    justify-content: space-between;
    &>div>svg{
        cursor: pointer;
    }
`;
const LeftWrap = styled.div`
    @media ${addTheme.media.m}{
        width: 50%;
        text-align: left;
        order:2;
        margin-top: 30px;
    }
`;
const CenterWrap = styled(Center)`
    width: 80%;
    @media ${addTheme.media.m}{
        width: 100%;
        order:1
    }
`;
const RightWrap = styled.div`
    @media ${addTheme.media.m}{
        width: 50%;
        text-align: right;
        order:3;
        margin-top: 30px;
    }
`;

const SliderWrap = styled.div`
    margin-top: 80px;
    @media ${addTheme.media.m}{
        margin-top: 15px;
    }
`;