import React from 'react'
import { TextItem } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import styled from 'styled-components'

export default function NewEnjoyItem({title,content,img}:any) {
    return (
        <Wrap>
            <TextItem fw={700} textAlign='center' fontSize={21} marginTop={70} dangerouslySetInnerHTML={{__html:title}}></TextItem>
            <TextItem fontSize={12} color='#d1d1d1' textAlign='center' marginTop={20} dangerouslySetInnerHTML={{__html:content}}></TextItem>
            <ImgWrap>
                <img alt='enjoy-part-img' src={img}></img>
            </ImgWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    & span{
        color: ${addTheme.color};
    }
`

const ImgWrap = styled.div`
    text-align: center;
    margin-top: 30px;
    width: 100%;
    & img{
        width: 100%;
    }
`;