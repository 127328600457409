import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IoMdClose,IoMdMenu } from 'react-icons/io';
import { MENU } from '../page/HeaderPage';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

const SIZE = 24;

export default function HeaderMoMenuPart({activeMenu,linkAction}:any) {
    const {number} = useGlobalLang();
    const widthChk = useMediaQuery(addTheme.media.m);
    const [menuState,setMenuState] = useState(false);

    const menuIconClick = () =>{
        setMenuState(!menuState);
    }

    useEffect(()=>{
        if(widthChk) return;
        setMenuState(false);
    },[widthChk])

    return (
        <Wrap>
            <IconWrap onClick={menuIconClick}>
                {menuState ?
                    <IoMdClose color='#fff' size={SIZE}></IoMdClose>
                    :
                    <IoMdMenu color='#fff' size={SIZE}></IoMdMenu>
                }
            </IconWrap>
            <MenuWrap menuState={menuState}>
                <MenuWrapBg onClick={menuIconClick}></MenuWrapBg>
                <MenuContent>
                    <MenuList>
                        {MENU.map((data,index)=>{
                            return(
                                <MenuItem key={`menu-${index}`} activeMenu={activeMenu === data.type} onClick={()=>linkAction(data.link)}>
                                    {data.title[number]}
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                </MenuContent>
            </MenuWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const IconWrap = styled.div`
    cursor: pointer;
    position: relative;
    z-index: 999;
`;

const MenuWrap = styled.div<{menuState:boolean}>`
    position: fixed;
    top:0;
    right: ${props=>props.menuState ? 0 : '-100%'};
    width: 100%;
    height: 100%;
    z-index: 998;
    transition: 0.3s ease-in-out;
`;

const MenuWrapBg = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
`;
const MenuContent = styled.div`
    position: relative;
    width: 75%;
    height: 100%;
    background-color: #000;
    margin-left: auto;
`;

const MenuList = styled.div`
    padding-top: ${addTheme.headerHeight.pc};
`;

const MenuItem = styled.div<{activeMenu:boolean}>`
    font-size: 20px;
    margin: 15px 0;
    &:last-of-type{
        margin-right: 0;
    }
    text-align: center;
    color: ${props => props.activeMenu ? addTheme.color : '#fff'};
    cursor: pointer;
`;